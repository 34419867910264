import React from 'react';
import {  Typography, Grid, Box, Paper, SelectChangeEvent } from '@mui/material';
import CommonTextField from '../../../common/CommonTextField';
import CommonSelect from '../../../common/CommomSelect';
import { Cities, Country,State } from '../../../../constants/dummyConst';

const Address = ({formValues,setFormValues}:any) => {
  
  // const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };


  return (
    
    <Box sx={{ pt: '4px'}}>
        <Paper elevation={0} sx={{overflowY:'auto',pt:'10px',position:'relative'}}>
      <Typography sx={{fontSize:'24px',fontWeight:'500', mb:'27px'}}>Address</Typography>
      <Box sx={{width:'50vw'}}>
      <Grid container spacing={2}>

      <Grid item xs={12} md={4}>
        <Typography sx={{fontSize:'14px',color:'#E5E3E3' , mb:'17px'}}>Country</Typography>
      <CommonSelect
                  labelId=""
                  label=""
                  name="country"
                  value={formValues.country||''}
                  onChange={handleInputChange}
                  // error={!!errors.country}
                  // helperText={errors.country}
                  options={Country}
                />
          </Grid>
          <Grid item xs={12} md={4}>
             <Typography sx={{fontSize:'14px',color:'#E5E3E3' , mb:'17px'}}>Address Line 1</Typography>
             <CommonTextField
                  label="addressLine1"
                  name="addressLine1"
                  value={formValues.addressLine1||''}
                  onChange={handleInputChange}
                  // error={!!errors.addressLine1}
                  // helperText={errors.addressLine1}
                />
             </Grid>
             <Grid item xs={12} md={4}>
             <Typography sx={{fontSize:'14px',color:'#E5E3E3' , mb:'17px'}}>Address Line 2</Typography>
             <CommonTextField
                  label="addressLine2"
                  name="addressLine2"
                  value={formValues.addressLine2||''}
                  onChange={handleInputChange}
                  // error={!!errors.addressLine2}
                  // helperText={errors.addressLine2}
                />
             </Grid>
             <Grid item xs={12} md={4}>
        <Typography sx={{fontSize:'14px',color:'#E5E3E3' , mb:'17px'}}>Postal Code</Typography>
        <CommonTextField
                  label=""
                  name="postalCode"
                  value={formValues.postalCode||''}
                  onChange={handleInputChange}
                  // error={!!errors.postalCode}
                  // helperText={errors.postalCode}
                />
              </Grid>
          <Grid item xs={12} md={4}>
             <Typography sx={{fontSize:'14px',color:'#E5E3E3' , mb:'17px'}}>State</Typography>
             <CommonSelect
                  labelId="state"
                  label=""
                  name="state"
                  value={formValues.state||''}
                  onChange={handleInputChange}
                  // error={!!errors.state}
                  // helperText={errors.state}
                  options={State}
                />
             </Grid>
             <Grid item xs={12} md={4}>
             <Typography sx={{fontSize:'14px',color:'#E5E3E3' , mb:'17px'}}>City</Typography>
             <CommonSelect
                  labelId="city"
                  label=""
                  name="city"
                  value={formValues.city||''}
                  onChange={handleInputChange}
                  // error={!!errors.city}
                  // helperText={errors.city}
                  options={Cities}
                />
             </Grid>
        </Grid>
      
      </Box>
      {/* <Box sx={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',gap:'20px',mt:'50px',mr:'10px', position:"absolute", bottom:'10px', right:'10px'}}>
        <Button  sx={{textTransform:'none',border:'InactiveBorder',color:'#848484'}} onClick={handlePreviousPage}>Previous</Button>
      <Button variant='contained' sx={{textTransform:'none'}} onClick={handleContinue}>Continue</Button>
      </Box> */}
      </Paper>
    </Box>
  );
};

export default Address;
