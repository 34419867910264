import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Link,
  createTheme,
  ThemeProvider,
  useMediaQuery,
  IconButton,
  InputAdornment,
} from "@mui/material";

import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import theronimg from "../assets/images/theronimg.svg";
import SignupImageBox from "./signupimgbox";
import bgimg from "../assets/images/login-pgimg.png";
import { LOGIN_API_URL } from "../constants/Crudurl";
import axios from "axios";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";

// import { ToastContainer } from 'react-toastify';
// import {  notify } from "../components/dashboard/commoncomponent/CustomToast";

 export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

interface LoginFormInputs {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger,
  } = useForm<LoginFormInputs>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isXLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [emailFocused, setEmailFocused] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);


  const onSubmit = async (data: any) => {
    const isValid = await trigger();
    if (isValid) {
      console.log(data);
  
      try {
        const response = await axios.post(LOGIN_API_URL, {
          identifier: data.email,
          password: data.password,
        });
  
        localStorage.setItem("token", response.data.accessToken);
        const tokenResponse: any = jwtDecode(response.data.accessToken);
        toast.success("Login successful");
        if (tokenResponse?.role.includes("ADMIN")) {
          navigate("/admin");
        } else {
          navigate("/dashboard");
        }
      } catch (error: any) {
        console.error("Login failed:", error);
        
        
        if (error.response && error.response.status === 403) {
          toast.error("Incorrect email or password");
        } else {
          toast.error("Something went wrong. Please try again.");
          // notify("error","Something went wrong. Please try again.")
        }
      }
    }
  };
  

  return (

    <ThemeProvider theme={theme}>
      
      <Container
        component="main"
        maxWidth="xl"
        sx={{ backgroundColor: "#f0f4ff" }}
      >
        <Box
          display="flex"
          flexDirection={isSmallScreen || isMediumScreen ? "column" : "row"}
          height="100vh"
        >
          {!isSmallScreen && !isMediumScreen && <SignupImageBox />}
          <Box
            flex={isSmallScreen || isMediumScreen ? "none" : 0.7}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundImage: `url(${bgimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              padding: isSmallScreen || isMediumScreen ? "1rem" : "3rem",
              height: isSmallScreen || isMediumScreen ? "90%" : "84vh",
            }}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: isXLargeScreen ? 400 : 340,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                minHeight: "85vh",
              }}
            >
              <img
                src={theronimg}
                alt="Theron"
                style={{
                  height: isXLargeScreen ? 120 : 90,
                  marginBottom: "1rem",
                }}
              />

              <Typography
                // component="h6"
                variant={isLargeScreen || isXLargeScreen ? "h5" : "h6"}
                alignItems="start"
                gutterBottom
                sx={{ color: "#616161", fontSize: "18px" }}
              >
                Login
              </Typography>
              <Box
                component="form"
                // sx={{ mt: 1 }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                      message: "Enter a valid email",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="email"
                      label="Email"
                      autoComplete="email"
                      error={Boolean(errors.email)}
                      helperText={errors.email?.message}
                      onFocus={() => setEmailFocused(true)}
                       
                      onBlur={() => {
                        setEmailFocused(false);
                        field.onBlur();
                      }}
                      InputLabelProps={{
                        shrink:
                           emailFocused ||
                          !!field.value ||
                          Boolean(errors.email),
                      }}
                      InputProps={{
                        sx: { borderRadius: 2, backgroundColor: "white" },
                      }}
                      size="small"
                    />
                  )}
                />
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters long",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="password"
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      autoComplete="current-password"
                      error={Boolean(errors.password)}
                      helperText={errors.password?.message}
                      onFocus={() => setPasswordFocused(true)}
                      onBlur={() => {
                        setPasswordFocused(false);
                        field.onBlur(); 
                      }}
                      InputLabelProps={{
                        shrink:
                          passwordFocused ||
                          !!field.value ||
                          Boolean(errors.password), 
                      }}
                      InputProps={{
                        sx: { borderRadius: 2, backgroundColor: "white" },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      size="small"
                    />
                  )}
                />
                <Box display="flex" justifyContent="flex-end">
                  <Link
                    href="#"
                    variant="body2"
                    onClick={() => navigate("/forgotpassword")}
                    sx={{
                      textDecoration: "none",
                      color: "#616161",
                      marginTop: "10px",
                    }}
                  >
                    Forgot Password
                  </Link>
                </Box>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 2,
                    mb: 2,
                    borderRadius: 2,
                    background: "linear-gradient(90deg, #36CFC9, #2F54EB)",
                    textTransform: "none",
                  }}
                >
                  Login
                </Button>
                {/* <Divider sx={{ my: 3 }}>or</Divider>
                <Button
                  fullWidth
                  sx={{
                    mb: 2,
                    borderRadius: 2,
                    background: "#fff",
                    color: "#000",
                    boxShadow: 1,
                  }}
                  onClick={() => {}}
                >
                  <img
                    src={microsof}
                    alt="Microsoft"
                    style={{ height: 24, marginRight: 8 }}
                  />
                  Login with Microsoft
                </Button>
                <Box
                  textAlign="center"
                  sx={{
                    background: "#fff",
                    borderRadius: 2,
                    padding: "8px",
                    boxShadow: 1,
                  }}
                >
                  <Typography variant="body2">
                    Don't you have an account?{" "}
                    <Link
                      href="#"
                      onClick={() => navigate("/signup")}
                      sx={{ textDecoration: "none" }}
                    >
                      Sign Up
                    </Link>
                  </Typography>
                </Box> */}
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Login;
