import React, { useState, useEffect } from 'react';
import { Box, Grid,Paper, Tab, Tabs, Typography } from '@mui/material';
import SearchBar from './SearchBar';
import ViewCard from '../ViewCard';
import FilterSidebar from './FilterSidebar';
import { useNavigate } from 'react-router-dom';
import WFA from '../Rth/rthtable/WFA';
import RTHInProgressTable from '../Rth/rthtable/RthInProgressTable';
import RTHClosedTable from '../Rth/rthtable/RthClosedTable';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import { fetchJobs } from '../../../redux/features/jobcardSlice';
import { jwtDecode } from "jwt-decode";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} pt={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

interface RoleTabItem {
  label: string;
  component: JSX.Element;
  data?: any;
}

interface RoleTabs {
  TEAMHEAD: RoleTabItem[];
  APPROVERHEAD: RoleTabItem[];
  [key: string]: RoleTabItem[]; // To support other roles if needed
}

type tabType = {
  tabNamee: string
}

export const TabsPanelNew: React.FC<tabType> = ({ tabNamee }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [tabName, setTabName] = useState<string>("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [viewType, setViewType] = useState<'grid' | 'list'>('grid');
  const [userRole, setUserRole] = useState('');
  const token: any = localStorage.getItem("token");
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();


  const [teamHeadFilterDto, setTeamHeadFilterDto] = useState({
    l1StatusPending: false,
    l1StatusDeclined: false,
    l2StatusApproved: false,
    internalOnRole: false,
    internalContract: false,
    externalOneTime: false,
    externalContract: false,
    priorityLow: false,
    priorityMedium: false,
    priorityHigh: false,
    rthStages: null,
    createdBy: null,
    recruiterLead: null,
    PositionTitle: null,
    fromYear: 0,
    fromMonth: 0,
    fromDay: 0,
    toYear: 0,
    toMonth: 0,
    toDay: 0,
    page: 0,
    size: 10
  });

  const { overAllRth, tobeAssigned, asignedRTh, myRequest, inProgress, closed, myRquestCounts,JobPost,WaitingToMoveToJob,Resume,ReadyForJDCreation } = useSelector(
    (state: RootState) => state.jobs
  );


  const decoded: any = jwtDecode(token);
  const userId = decoded.userId;

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded: any = jwtDecode(token);
      const role = decoded.role;
      const rrole = role.replace(/\s+/g, '')
      setUserRole(rrole);
    }
  }, []);


  const handleViewChange = (view: 'grid' | 'list') => {
    setViewType(view);
  };



  const handleButtonClick = () => {
    navigate('/newrth');
  };

  useEffect(() => {
    setTabName(tabNamee);
  }, [tabNamee])

  useEffect(() => {
    dispatch(fetchJobs({ userId, teamHeadFilterDto }));
  }, [dispatch, userId, teamHeadFilterDto]);

  const roleTabs: RoleTabs = {
    TEAMHEAD: [
      { label: 'My Requests', component: <WFA data={myRequest} />, data: myRequest },
      { label: 'In Progress', component: <RTHInProgressTable data={inProgress} />, data: inProgress },
      { label: 'Closed', component: <RTHClosedTable data={closed} />, data: closed },
    ],
    APPROVERHEAD: [
      { label: 'overall RTH', component: <WFA data={overAllRth} />, data: overAllRth },
      { label: 'My Requests', component: <WFA data={myRequest} />, data: myRequest },
      { label: 'In Progress', component: <RTHInProgressTable data={inProgress} /> },
      { label: 'Closed', component: <RTHClosedTable data={closed} /> },
    ],
    TALENTACQUISITIONHEAD: [
      { label: 'To Be Assigned', component: <WFA data={tobeAssigned} />, data: tobeAssigned },
      { label: 'Asigned RTh', component: <WFA data={asignedRTh} />, data: asignedRTh },
      { label: 'My Requests', component: <WFA data={myRequest} />, data: myRequest },
      { label: 'In Progress', component: <RTHInProgressTable data={inProgress} />, data: inProgress },
      { label: 'Closed', component: <RTHClosedTable data={closed} />, data: closed },
    ],
    RECRUITERLEAD: [
      { label: 'To Be Assigned', component: <WFA data={tobeAssigned} />, data: tobeAssigned },
      { label: 'Asigned RTh', component: <WFA data={asignedRTh} />, data: asignedRTh },
      { label: 'My Requests', component: <WFA data={myRequest} />, data: myRequest },
      { label: 'In Progress', component: <RTHInProgressTable data={inProgress} />, data: inProgress },
      { label: 'Closed', component: <RTHClosedTable data={closed} />, data: closed },
    ],
    RECRUITER:[
      {label:"Waiting to Move to Job",component:<WFA data={WaitingToMoveToJob}/>,data:WaitingToMoveToJob},
      {label:"Ready for JD Creation",component:<WFA data={ReadyForJDCreation}/>,data:ReadyForJDCreation},
      {label:"Job Post",component:<WFA data={JobPost}/>,data:JobPost},
      {label:"Resume",component:<WFA data={Resume}/>,data:Resume},
    ]
  };

  // Function to get tabs based on the role
  const getTabsByRole = (role: string): RoleTabItem[] => {
    return roleTabs[role] || [];
  };

  const tabs = getTabsByRole(userRole);


  return (
    <Box sx={{ width: '95%', backgroundColor: '#f0f4ff', display: 'flex', position: 'relative' }} >
      <Box sx={{ marginBottom: "35%" }}>
        <FilterSidebar onChangeFilter={setDrawerOpen} drawerOpen={drawerOpen} tabName={tabName} tabIndex={tabIndex} teamHeadFilterDto={teamHeadFilterDto} setTeamHeadFilterDto={setTeamHeadFilterDto} myRquestCounts={myRquestCounts} />
      </Box>
      <Box sx={{ width: '100%', transition: 'margin-left 0.3s' }}>
        <Box>
          <Paper elevation={0} sx={{ backgroundColor: 'transparent' }}>
            <Tabs
              value={tabIndex}
              onChange={(e, newIndex) => {
                setTabIndex(newIndex)

                setTabName(tabs[newIndex].label)
              }}

              indicatorColor="primary"
              aria-label="job tabs"
              sx={{ ml: drawerOpen ? '0px' : '100px' }}
              TabIndicatorProps={{
                style: {
                  width: '20px',
                  marginLeft: `${tabIndex === 0 ? '45px' : '36px'}`,
                },
              }}
            >
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  {...a11yProps(index)}
                  sx={{ fontSize: '13px', textTransform: 'none' }}
                />
              ))}
            </Tabs>
          </Paper>
          <SearchBar viewType={viewType} handleViewChange={handleViewChange} handleButtonClick={handleButtonClick} />
                                                    
          {tabs.map((tab, index) => (
            <CustomTabPanel value={tabIndex} index={index} key={index}>
              {viewType === 'grid' ? (
                <Grid container spacing={2} xs={16} sx={{ display: 'flex', flexWrap: 'wrap', gap: '0px' }}>
                  <ViewCard data={tab.data} label={tab.label} />
                </Grid>
              ) : (
                tab.component
              )}
            </CustomTabPanel>
          ))}
          <Box sx={{mt:'auto'}}>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
