import React, { useState } from 'react';
import { Box, Button, InputAdornment, InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddUserForm from './AddUserForm';

export const MasterSearch: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleButtonClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box display="flex" alignItems="center" mt={2} justifyContent="space-between">
      <InputBase
        placeholder="Search RTH..."
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon sx={{ fontSize: 18, marginLeft:"10px" }} /> 
          </InputAdornment>
        }
        sx={{ 
          border: '1px solid #ccc', 
          borderRadius: 1, 
          padding: 0, 
          backgroundColor: 'white', 
          minHeight: '3px', 
          fontSize: 14,  
          '.MuiInputBase-input': {
            padding: '5px 5px', 
          }
        }}
      />
      <Box display="flex" alignItems="center">
        <Button variant="contained" color="primary" onClick={handleButtonClick}  sx={{ textTransform: "none" }}>
          + New User
        </Button>
      </Box>
      <AddUserForm open={open} onClose={handleClose} />
    </Box>
  );
};

export default MasterSearch;
