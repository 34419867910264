import React from 'react';
import { Typography, Grid, Box, Paper, SelectChangeEvent } from '@mui/material';
import CommonSelect from '../../../common/CommomSelect';
import { Citizenship, Nationality } from '../../../../constants/dummyConst';


const AdditionalInformation = ({formValues,setFormValues}:any) => {

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  return (
    
    <Box sx={{ pt: '4px'}}>
        <Paper elevation={0} sx={{overflowY:'auto',pt:'10px',position:'relative'}}>
      <Typography sx={{fontSize:'24px',fontWeight:'500', mb:'27px'}}>Additional Information</Typography>
      <Box sx={{width:'50vw'}}>
      <Grid container spacing={2}>

      <Grid item xs={12} md={4}>
        <Typography sx={{fontSize:'14px',color:'#E5E3E3' , mb:'17px'}}>Nationality</Typography>
      <CommonSelect
                  labelId=""
                  label=""
                  name="nationality"
                  value={formValues.nationality||''}
                  onChange={handleInputChange}
                  // error={!!errors.nationality}
                  // helperText={errors.nationality}
                  options={Nationality}
                />
          </Grid>
          <Grid item xs={12} md={3}>
             <Typography sx={{fontSize:'14px',color:'#E5E3E3' , mb:'17px'}}>CitizenShip</Typography>
             <CommonSelect
                  labelId="citizenShip"
                  label=""
                  name="citizenShip"
                  value={formValues.citizenShip||''}
                  onChange={handleInputChange}
                  // error={!!errors.citizenShip}
                  // helperText={errors.citizenShip}
                  options={Citizenship}
                />
             </Grid>
        </Grid>
      
      </Box>
      {/* <Box sx={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',gap:'20px',mt:'50px',mr:'10px', position:"absolute", bottom:'10px', right:'10px'}}>
        <Button  sx={{textTransform:'none',border:'InactiveBorder',color:'#848484'}} onClick={handlePreviousPage}>Previous</Button>
      <Button variant='contained'onClick={handleContinue} sx={{textTransform:'none'}}>Continue</Button>
      </Box> */}
      </Paper>
    </Box>
  );
};

export default AdditionalInformation;
