import React, { useState } from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import JobDetails from './JobDetails';
import JobFooter from './JobFooter';
import JobProgress from './JobProgress';
import JobTime from './JobTime';
import JobHeader from './JobHeader';
import { Job, movetojob } from '../../redux/features/jobcardSlice';
import closedImg from '../../assets/images/closedtext.svg'
import ApprovalActions from './jobApprovel';
import ToBeAssigned from './tobeAssigned';
import { useNavigate,useLocation } from 'react-router-dom';
import { setL1Status, setL2Status } from '../../redux/features/rthSlice';
import { useDispatch } from 'react-redux';
import Postin from './Postin';

import { AppDispatch } from '../../redux/store';

interface JobCardProps {
  job?: Job;
  userName: string;
  userTitle: string;
  userAvatar: string;
  label?: string;
}


const JobCard: React.FC<JobCardProps> = ({ job, userName, userTitle, userAvatar, label }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [updateCount, setUpdateCount] = useState(0);
  const location = useLocation();

  const handleRctrButton=()=>{
    
    if (job?.rthId && label === "Waiting to Move to Job") 
    { // Ensure rthId exists before dispatching
      dispatch(movetojob({ rthId: job.rthId, status: "MoveToJob" })); // Pass the correct object structure
      console.log(updateCount);
    }
    if(job?.rthId && label === "Ready for JD Creation"){
      navigate(`/JDCreation/${job?.rthId}`)
    }
  }

  const handleApproveL1 = async (jobId?: string | undefined) => {

    setL1Status({ rthId: jobId, status: "Approved" })
    setUpdateCount(prev => prev + 1);
  };

  const handleApproveL2 = (jobId?: string | undefined) => {
    setL2Status({ rthId: jobId, status: "Approved" })
    setUpdateCount(prev => prev + 1);
  };

  const handleDeclineL1 = (jobId: string | undefined) => {
    // Handle decline action
    setL1Status({ rthId: jobId, status: "Declined" })
    setUpdateCount(prev => prev + 1);
  };
  const handleDeclineL2 = (jobId: string | undefined) => {
    // Handle decline action
    setL2Status({ rthId: jobId, status: "Declined" })
    setUpdateCount(prev => prev + 1);
  };

const handleBoxClick=()=>{
  if (location.pathname.includes('newRequestToHire')) {
    navigate(`/newRequestToHire/${job?.positionTitle}/${job?.rthId}`);
  } else {
    navigate(`/uxdesign`, { state: { id: job?.rthId } });
  }
}
  const checkRth = label === "overall RTH";
  const checkRthL1Status = job?.l1Status === "Pending";
  const checkRthL2Status = job?.l1Status === "Approved" && job?.l2Status === "Pending";

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: 1, position: 'relative' }} onClick={handleBoxClick}>
      <Box sx={{
        backgroundColor: label === "Closed"
          ? "#F6F6F6"
          : checkRth && checkRthL1Status
            ? "#F2ECFA"
            : checkRth && checkRthL2Status
              ? "#EAF5FE"
              : "white",
        borderRadius: "10px",
        border: checkRth && checkRthL1Status
          ? "2px solid #5708B8"
          : checkRth && checkRthL2Status
            ? "2px solid #36CFC9"
            : "1px solid lightgray",
        boxShadow: (checkRth && checkRthL1Status) || (checkRth && checkRthL2Status)
          ? "0px 4px 10px rgba(0, 0, 0, 0.1)"
          : "none",
        width: "230px",
        padding: "7px",
        cursor: 'pointer'
      }}>

        <JobHeader department={job?.positionTitle} priority={job?.priority} business={job?.business} seqId={job?.seqId} />

        <JobDetails typeOfRth={job?.typeOfRth} modeOfRth={job?.modeOfRth} rthProcess={job?.rthProcess} status={job?.status} modesOfRth={job?.modesofRth} />

        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ width: "100%" }}>
            <JobTime posted={job?.posted} />


            {checkRth && job?.l1Status === "Approved" && job?.l2Status === "Approved" ? (
              <Box p={1}>
                <Typography variant="h6" fontSize={"100%"} sx={{ color: 'green' }}>Accepted</Typography>
              </Box>

            ) : (
              checkRth && (
                <ApprovalActions
                  onApprove={(event) => {
                    event.stopPropagation();
                    if (job?.l1Status === "Pending") {
                      handleApproveL1(job?.rthId);
                    }
                    if (job?.l1Status === "Approved") {

                      handleApproveL2(job.rthId);
                    }
                  }}
                  onDecline={(event) => {
                    event.stopPropagation();
                    if (job?.l1Status === "Pending") {
                      handleDeclineL1(job?.rthId)
                    }
                    if (job?.l1Status === "Approved") {
                      handleDeclineL2(job?.rthId)
                    }
                  }}
                />
              )
            )}


            {label === "To Be Assigned" && (
              <ToBeAssigned
              />
            )  }
            {(job?.status === "Closed" || job?.status === "In Progress") ?
              <Box>
                <Box sx={{ display: "flex", marginTop: "20px", paddingLeft: "2px" }}>
                  {
                    (label !== "Job Post" && label !== "Waiting to Move to Job" && label !== "Ready for JD Creation") && (
                      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        <Box>
                          <Avatar src={job.posReportsTo.profilePicture} alt="jobcardimagea" sx={{ width: 24, height: 24 }}></Avatar>
                        </Box>

                        <Box display={'flex'} flexDirection={'column'}>
                          <Typography sx={{ fontSize: '12px' }}>{job.posReportsTo.firstName}</Typography>
                          <Typography sx={{ fontSize: '10px' }}>{job.posReportsTo.role}</Typography>
                        </Box>

                      </Box>
                    )
                  }
                  {
                    (label === "Job Post" || label === "Waiting to Move to Job" || label === "Ready for JD Creation") && (
                      <Postin 
                      label={label}
                      handleRecruiterButton={handleRctrButton}
                      />
                    )
                  }
                  <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1, marginTop: "2px" }}>
                    <Typography variant='body2' sx={{ fontSize: '10px', lineHeight: 1.2 }}>{job?.name}</Typography>
                    <Typography variant='caption' sx={{ fontSize: '8px', lineHeight: 1.2, color: 'gray' }}>{job?.reqName}</Typography>
                  </Box>
                </Box>
                {label === "Closed" && (
                  <Box sx={{ position: "absolute", top: 35, right: 15 }}>
                    <img src={closedImg} style={{ height: "90px" }} alt="close" />
                  </Box>
                )}
              </Box>
              :
              <>
                {job?.tabStatus === "open" ?
                  <JobFooter userName={userName} userTitle={userTitle} userAvatar={userAvatar} status={job?.status} />
                  :
                  <>
                    {(!checkRth && label === "My Requests") && (
                      <JobProgress L1Status={job?.l1Status} L2Status={job?.l2Status} />)}
                    <JobFooter userName={userName} userTitle={userTitle} userAvatar={userAvatar} status={job?.status} />
                  </>
                }
              </>
            }
          </Box>
        </Box>
      </Box>
    </Box>
  )
};

export default JobCard;