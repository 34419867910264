import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  IconButton,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CircleIcon from '@mui/icons-material/Circle';
import { ViewCardProps } from "../../ViewCard";





export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.divider}`,
  "&:last-child": {
    borderRight: "none",
  },
}));



const RTHClosedTable: React.FC<ViewCardProps> = ({data}) => {
  
  return (
    <Box sx={{ width: "auto", backgroundColor: "#f0f4ff" }} p={1}>
      {/* <TabPanelNew
        tabIndex={tabIndex}
        onTabChange={(e, newIndex) => setTabIndex(newIndex)}
      />
       */}
      {/* <SearchBar /> */}
      <Box sx={{ width: "100%", overflowX: "auto", mt: 2 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="RTH table">
            <TableHead sx={{ backgroundColor: '#F9F9F9' }}>
              <TableRow>
                <StyledTableCell padding="checkbox">
                  <input type="checkbox" />
                </StyledTableCell>


                <StyledTableCell>
                  SI.No.
                </StyledTableCell>

                <StyledTableCell>
                  RTH Name
                  <ArrowUpwardIcon fontSize="inherit" sx={{ ml: 0.5, color: '#CDCDCD' }} />
                </StyledTableCell>

                <StyledTableCell>
                  Type of RTH
                  <ArrowUpwardIcon fontSize="inherit" sx={{ ml: 0.5, color: '#CDCDCD' }} />
                </StyledTableCell>

                <StyledTableCell>
                  Posted Time
                  <ArrowUpwardIcon fontSize="inherit" sx={{ ml: 0.5, color: '#CDCDCD' }} />
                </StyledTableCell>

                <StyledTableCell>
                  Business
                  <ArrowUpwardIcon fontSize="inherit" sx={{ ml: 0.5, color: '#CDCDCD' }} />
                </StyledTableCell>

                <StyledTableCell>
                  Priority
                  <ArrowUpwardIcon fontSize="inherit" sx={{ ml: 0.5, color: '#CDCDCD' }} />
                </StyledTableCell>

                <StyledTableCell>
                  Status
                  <ArrowUpwardIcon fontSize="inherit" sx={{ ml: 0.5, color: '#CDCDCD' }} />
                </StyledTableCell>
                <StyledTableCell>
                  Stage
                  <ArrowUpwardIcon fontSize="inherit" sx={{ ml: 0.5, color: '#CDCDCD' }} />
                </StyledTableCell>

                <StyledTableCell>
                  Action
                </StyledTableCell>


              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={row.rthId}>
                  <StyledTableCell padding="checkbox">
                    <input type="checkbox" />
                  </StyledTableCell>
                  <StyledTableCell>{index + 1}</StyledTableCell>
                  <StyledTableCell>{row.department}</StyledTableCell>
                  <StyledTableCell>
                    <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                      <Button
                        size="small"
                        sx={{
                          borderRadius: "8px",
                          backgroundColor: "#F0F0F0",
                          border: "1px solid lightgray",
                          fontSize: "8px",
                          padding: "2px 4px",
                          color: "#8C8C8C",
                          height: "20px",
                          minWidth: "50px",
                        }}
                      >
                        Internal
                      </Button>
                      <Button
                        size="small"
                        sx={{
                          borderRadius: "8px",
                          backgroundColor: "#F0F0F0",
                          border: "1px solid lightgray",
                          fontSize: "8px",
                          padding: "2px 4px",
                          color: "#8C8C8C",
                          height: "20px",
                          minWidth: "50px",
                        }}
                      >
                        On Role
                      </Button>
                    </Box>
                  </StyledTableCell>

                  <StyledTableCell>
                    <Chip label={row.posted} size="small"
                      sx={{
                        fontSize: "12px",
                        padding: "3px 6px 0px 6px",
                        height: "px",
                        borderRadius: "1px",
                        backgroundColor: 'transparent',

                      }}
                    />
                  </StyledTableCell>




                  <StyledTableCell>
                    <Chip
                      label={row.business}
                      size="small"
                      sx={{
                        fontSize: "12px",
                        padding: "3px 6px 0px 6px",
                        height: "px",
                        borderRadius: "1px",
                        backgroundColor:
                          row.business === "HEPL" ? "#E0E7FF" : "#E0F2FE",
                        color: row.business === "HEPL" ? "#3730A3" : "#075985",
                      }}
                    />
                  </StyledTableCell>

                  <StyledTableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '1px' }}>
                      <SignalCellularAltIcon fontSize="small" sx={{ color: 'red' }} />
                      <Chip
                        label={row.priority}
                        size="small"
                        variant="outlined"
                        sx={{
                          fontSize: '10px',
                          color: 'red',
                          borderColor: 'transparent',
                          backgroundColor: 'transparent',
                          marginTop: '6px',
                          paddingRight: '0px'
                        }}
                      />
                    </Box>
                  </StyledTableCell>

                  <StyledTableCell>
                    <Chip label={row.rthProcess} size="small"
                      sx={{
                        fontSize: "12px",
                        padding: "3px 6px 0px 6px",
                        height: "px",
                        borderRadius: "1px",
                        backgroundColor: '#389E0D 10%',
                        color: '#389E0D',

                      }}
                    />
                  </StyledTableCell>

                  <StyledTableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                      <CircleIcon
                        sx={{
                          fontSize: '10px',
                          color: 'inherit',
                        }}
                      />
                      <Chip
                        label={row.rthProcess}
                        size="small"
                        sx={{
                          fontSize: '12px',
                          padding: '3px 6px 0px 6px',
                          height: 'px',
                          borderRadius: '1px',
                          backgroundColor: 'transparent',
                          color: 'inherit',
                        }}
                      />
                    </Box>
                  </StyledTableCell>

                  <StyledTableCell>
                    <IconButton size="small">
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton size="small">
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default RTHClosedTable;
