import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  SelectChangeEvent,
  CircularProgress,
  Divider
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { addUser, fetchUsers } from "../../redux/features/userSlice";
import { AppDispatch } from "../../redux/store/index";
import {
  getRthDepartmentData,
  getRoleList,
  getDesignationList,
  getOrganization,
  getSupervisors
} from "../../Crud/rth";
import { toast } from "react-toastify";

interface AddUserFormProps {
  open: boolean;
  onClose: () => void;
}

export interface UserData {
  id?: string;
  firstName: string;
  email: string;
  mobNumb: string;
  organizationId: string;
  designationId: string;
  role: string;
  roleId: string;
  departmentId: any;
  designation: any;
  department: any;
  supervisorId: string;
  supervisorName: string;
  profilePicture: string;
  supervisorImage: string;
  organizationImg: any;
}

const AddUserForm: React.FC<AddUserFormProps> = ({ open, onClose }) => {
  const [departmentData, setDepartmentData] = useState<{ value: string; label: string }[] >([]);
  const [roleData, setRoleData] = useState<{ value: string; label: string }[] >([]);
  const [designationData, setDesignationData] = useState<{ value: string; label: string }[] >([]);
  const [organizationData, setOrganizationData] = useState<{ value: string; label: string }[] >([]);
  const [SupervisorsData, setSupervisorsData] = useState<{ value: string; label: string }[] >([]);
  const dispatch = useDispatch<AppDispatch>();
  const [formData, setFormData] = useState<Partial<UserData>>({
    firstName: "",
    email: "",
    mobNumb: "",
    organizationId: "",
    designationId: "",
    roleId: "",
    departmentId: "",
    supervisorId: "",
  });

  const [formErrors, setFormErrors] = useState<
    Partial<Record<keyof UserData, string>>
  >({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getRthDepartmentData()
      .then((res) => {
        const data = res.data?.map((i: any) => ({
          value: i.departmentId,
          label: i.departmentName,
        }));
        setDepartmentData(data || []);
      })
      .catch((err) => console.error(err));

      getRoleList()
      .then((res) => {
        const data = res.data?.map((i: any) => ({
          value: i.roleId,
          label: i.roleName,
        }));
        setRoleData(data || []);
      })
      .catch((err) => console.error(err));

      getDesignationList()
      .then((res) => {
        const data = res.data?.map((i: any) => ({
          value: i.id,
          label: i.designationName,
        }));
        setDesignationData(data || []);
      })
      .catch((err) => console.error(err));

      getOrganization()
      .then((res) => {
        const data = res.data?.map((i: any) => ({
          value: i.id,
          label: i.organizationName,
        }));
        setOrganizationData(data || []);
      })
      .catch((err) => console.error(err));

      getSupervisors()
      .then((res) => {
        const data = res.data?.map((i: any) => ({
          value: i.id,
          label: i.firstName,
        }));
        setSupervisorsData(data || []);
      })
      .catch((err) => console.error(err));

  }, []);

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    validateField(name as keyof UserData, value);
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    validateField(name as keyof UserData, value);
  };

  

  const validateField = (fieldName: keyof UserData, value: string) => {
    let error = "";
    if (!value) {
      error = "This field is required";
    } else if (
      fieldName === "email" &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
    ) {
      error = "Invalid email address";
    } else if (fieldName === "mobNumb" && !/^\d{10}$/.test(value)) {
      error = "Invalid mobile number";
    }

    setFormErrors((prevErrors) => ({ ...prevErrors, [fieldName]: error }));
  };

  const handleSubmit = async () => {
    
    const newFormErrors: Partial<Record<keyof UserData, string>> = {};

    Object.keys(formData).forEach((key) => {
      const value = formData[key as keyof UserData] || "";
      validateField(key as keyof UserData, value);
      if (!value) {
        newFormErrors[key as keyof UserData] = "This field is required";
      }      
    });

    setFormErrors(newFormErrors);

    // Check if there are any validation errors
    const hasErrors = Object.values(newFormErrors).some(
      (error) => error !== ""
    );

    if (hasErrors) {
      return; // Don't submit the form if there are errors
    }

    setLoading(true);
    try {
      // let data={
      //   ...formData,
      //   designationId:formData.designation
      // }
      await dispatch(addUser(formData)).unwrap();
      toast.success("success");
      await dispatch(fetchUsers()).unwrap();
      onClose();
      setFormData({
        firstName: "",
        email: "",
        mobNumb: "",
        organizationId: "",
        designationId: "",
        roleId: "",
        departmentId: "",
        supervisorId: "",
      });
    } catch (error) {
      console.error("Failed to add the user: ", error);
      toast.error("User MailId or PhoneNo already Exist")
    } finally {
      setLoading(false);
    }
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth={false} fullWidth sx={{ maxWidth: '500px', margin: 'auto',  }}  >
      <DialogTitle>
        Add User
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        
      </DialogTitle>
      <Divider  />
      <DialogContent>
        <Box component="form" noValidate autoComplete="off" sx={{ mt: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Employee Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleTextFieldChange}
                variant="outlined"
                size="small"
                error={Boolean(formErrors.firstName)}
                helperText={formErrors.firstName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Mail ID"
                name="email"
                value={formData.email}
                onChange={handleTextFieldChange}
                variant="outlined"
                size="small"
                error={Boolean(formErrors.email)}
                helperText={formErrors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Mobile Number"
                name="mobNumb"
                value={formData.mobNumb}
                onChange={handleTextFieldChange}
                variant="outlined"
                size="small"
                error={Boolean(formErrors.mobNumb)}
                helperText={formErrors.mobNumb}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                error={Boolean(formErrors.organizationId)}
              >
                <InputLabel>Organization</InputLabel>
                <Select
                  name="organizationId"
                  value={formData.organizationId}
                  onChange={handleSelectChange}
                  label="Organization"
                >
                  <MenuItem value="">
                   </MenuItem>
                   {organizationData.map((organization) => (
                     <MenuItem key={organization.value} value={organization.value}>
                       {organization.label}
                     </MenuItem>
                   ))}
                </Select>
                <FormHelperText>{formErrors.organizationId}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                error={Boolean(formErrors.designationId)}
              >
                <InputLabel>Designation</InputLabel>
                <Select
                  name="designationId"
                  value={formData.designationId}
                  onChange={handleSelectChange}
                  label="Designation"
                >
                  <MenuItem value="">
                   </MenuItem>
                   {designationData.map((designation) => (
                     <MenuItem key={designation.value} value={designation.value}>
                       {designation.label}
                     </MenuItem>
                   ))}
                </Select>
                <FormHelperText>{formErrors.designationId}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                error={Boolean(formErrors.supervisorId)}
              >
                <InputLabel>Supervisor</InputLabel>
                <Select
                  name="supervisorId"
                  value={formData.supervisorId}
                  onChange={handleSelectChange}
                  label="Supervisor"
                >
                  <MenuItem value="">
                   </MenuItem>
                   {SupervisorsData.map((supervisor) => (
                     <MenuItem key={supervisor.value} value={supervisor.value}>
                       {supervisor.label}
                     </MenuItem>
                   ))}
                </Select>
                <FormHelperText>{formErrors.supervisorId}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                error={Boolean(formErrors.roleId)}
              >
                <InputLabel>Role</InputLabel>
                <Select
                  name="roleId"
                  value={formData.roleId}
                  onChange={handleSelectChange}
                  label="Role"
                >
                  <MenuItem value="">
                   
                   </MenuItem>
                   {roleData.map((role) => (
                     <MenuItem key={role.value} value={role.value}>
                       {role.label}
                     </MenuItem>
                   ))}
                </Select>
                <FormHelperText>{formErrors.roleId}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                error={Boolean(formErrors.departmentId)}
              >
                <InputLabel>Department</InputLabel>
               
                <Select
                  name="departmentId"
                  value={formData.departmentId}
                  onChange={handleSelectChange}
                  label="Department"
                >
                  <MenuItem value="">
                   
                  </MenuItem>
                  {departmentData.map((department) => (
                    <MenuItem key={department.value} value={department.value}>
                      {department.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{formErrors.departmentId}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserForm;
