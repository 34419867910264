import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Link,
  createTheme,
  ThemeProvider,
  useMediaQuery,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import theronimg from "../assets/images/theronimg.svg";
import bgimg from "../assets/images/login-pgimg.png";
import SignupImageBox from "./signupimgbox";
import { setPassword, resetPasswordState } from '../redux/features/passwordSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { jwtDecode } from "jwt-decode";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});


interface SignupFormInputs {
    password: string;
    confirmPassword: string;
  }
  
  interface DecodedToken {
    email: string;
  }

const SetPassword: React.FC = () => {
  const {
    handleSubmit,
    control,
    // reset,
    formState: { errors },
    watch,
  } = useForm<SignupFormInputs>({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoading, error, success } = useAppSelector((state) => state.password);
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tokenFromUrl = searchParams.get('token');
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
      try {
        const decodedToken = jwtDecode(tokenFromUrl) as DecodedToken;
        setEmail(decodedToken.email);
      } catch (error) {
        console.error('Error decoding token:', error);
        navigate('/login');
      }
    } else {
      navigate('/login');
    }
  }, [location, navigate]);

  useEffect(() => {
    if (success) {
      dispatch(resetPasswordState());
      navigate('/login');
    }
  }, [success, dispatch, navigate]);

  const onSubmit = async (data: SignupFormInputs) => {
    if (data.password === data.confirmPassword) {
      await dispatch(setPassword({
        email,
        password: data.password,
        confirmPassword: data.confirmPassword,
        token
      }));
    } else {
      // Handle password mismatch
    }
  };

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isXLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  const password = watch("password");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="xl"
        sx={{ backgroundColor: "#f0f4ff" }}
      >
        <Box
          display="flex"
          flexDirection={isSmallScreen || isMediumScreen ? "column" : "row"}
          height="100vh"
        >
          {!isSmallScreen && !isMediumScreen && <SignupImageBox />}
          <Box
            flex={isSmallScreen || isMediumScreen ? "none" : 0.7}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundImage: `url(${bgimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              padding: isSmallScreen || isMediumScreen ? "1rem" : "3rem",
              minHeight: isSmallScreen || isMediumScreen ? "90%" : "70vh",
            }}
          >
            <Box
              sx={{
                width: isSmallScreen || isMediumScreen ? "100%" : "50%",
                maxWidth: isXLargeScreen ? 400 : 340,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                minHeight: "85vh",
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                mb={isSmallScreen ? 1 : 3}
              >
                <img
                  src={theronimg}
                  alt="Theron"
                  style={{
                    height: isXLargeScreen ? 120 : 90,
                    marginBottom: "1rem",
                  }}
                />
              </Box>
              <Typography
                component="h5"
                variant={isLargeScreen || isXLargeScreen ? "h5" : "h6"}
                alignItems="start"
                sx={{ color: "#616161", fontSize: "18px" }}
              >
                Set Password
              </Typography>
              <Box
                component="form"
                sx={{ mt: 3, marginTop: "5px" }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters long",
                    },
                    pattern: {
                      value:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                      message:
                        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="password"
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      autoComplete="new-password"
                      error={Boolean(errors.password)}
                      helperText={errors.password?.message}
                      InputProps={{
                        sx: { borderRadius: 2, backgroundColor: "white" },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={togglePasswordVisibility}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (                                
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      size="small"
                    />
                  )}
                />
                <Controller
          name="confirmPassword"
          control={control}
          rules={{ 
            required: 'Confirm Password is required',
            validate: value => value === password || 'Passwords do not match'
          }}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              fullWidth
              id="confirmPassword"
              label="Confirm Password"
              type={showConfirmPassword ? 'text' : 'password'}
              autoComplete="new-password"
              error={Boolean(errors.confirmPassword)}
              helperText={errors.confirmPassword?.message}
              InputProps={{
                sx: { borderRadius: 2, backgroundColor: 'white' },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle confirm password visibility"
                      onClick={toggleConfirmPasswordVisibility}
                      edge="end"
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              size="small"
            />
          )}
        />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={isLoading}
                  sx={{
                    mt: 3,
                    mb: 2,
                    borderRadius: 2,
                    background: "linear-gradient(90deg, #36CFC9, #2F54EB)",
                  }}
                >
                  {isLoading ? 'Setting Password...' : 'Set Password'}
                </Button>
                {error && <Typography color="error">{error}</Typography>}
                <Box textAlign="center" mt={2}>
                  <Link
                   href="#"
                   variant="body2"
                   onClick={() => navigate("/login")}
                   sx={{ color: "#339ED6", textDecoration: "none", fontWeight:"600" }}
                  >
                    Back to Login
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default SetPassword;
