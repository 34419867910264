  import { Box, Button, Card, Typography } from "@mui/material";
  import SearchIcon from '@mui/icons-material/Search';
  import tempCardIcon from '../../../../../assets/images/template_card_image.svg';
  import DraftCardIcon from '../../../../../assets/images/draft_card_image.svg';
  import DraftCardDisableIcon from '../../../../../assets/images/draft_card_disable_image.svg';
  import tempCardDisableIcon from '../../../../../assets/images/template_card_disable_image.svg';
  import { useEffect, useState } from "react";
  import { useNavigate, useParams } from "react-router-dom";
  import ReactQuill from 'react-quill';
  import 'react-quill/dist/quill.snow.css';
  import { styled } from '@mui/system';
  import { getAllTemplate, saveAsTemplate } from "../../../../../redux/features/jdTemplate";
  import refreshIcon from '../../../../../assets/images/refeshIcon.svg';
  import { toast } from "react-toastify";

  export const TemplateComponent = ({pageType, goToTemplateTab}: any) => {
    const [activeCardId, setActiveCardId] = useState<any>([]);
    const [showTemplate, setShowTemplate] = useState(false);
    const [buttonName, setButtonName] = useState("Submit");
    const [hideButton, setHideButton] = useState(true);
    const[allTemplate,setAllTemplate]=useState<any>([]);
    const [value, setValue] = useState('');
    const navigate=useNavigate();
    const {id}=useParams();

    const displayIcon = pageType === 'Template' ? tempCardIcon : DraftCardIcon; 
    const disableIcon = pageType === 'Template' ? tempCardDisableIcon : DraftCardDisableIcon; 

    const handleCardClick = (id: number) => {
      setActiveCardId(id);
      const selectedCard = allTemplate.find((card:any) => card.jdId === id);
      navigate(`/JDCreation/${id}`);
      if (selectedCard) {
        setValue(selectedCard.jdTemplateText); 
      }
    };

    const handleCancel = () => {      
      goToTemplateTab()
    }

    const truncateText = (text: string | null | undefined, maxLength: number) => {
      if (text == null) {
        return '';
      }
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
      }
      return text;
    };

    const handleNewTemplateButton = () => {
      setShowTemplate(false);
      setButtonName("Create New Template");
      setHideButton(false);
    };

  const handleTemplateSave=async ()=>{
    const payload={
      "jdName":"Test Template",
      "jdTemplateText":value,
    }
    await saveAsTemplate(payload).then(async ()=>{
      await getAllTemplate().then((response)=>{
        setAllTemplate(response.data)
      })
    });
    toast.success("Template Saved Successfully");
  }

    const RefreshButton = styled(Button)({
      background: 'linear-gradient(90deg, #2F54EB 0%, #36CFC9 100%)',
      borderRadius: '8px',
      height:"42px",
      color: '#fff',
      textTransform: 'none',
      padding: '10px 20px',
      fontWeight: 'bold',
      fontSize: '16px',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      boxShadow: 'none',
      '&:hover': {
        background: 'linear-gradient(90deg, #004bbd 0%, #00a1c6 100%)',
        boxShadow: 'none',
      },
    });
      
    const modules = {
      toolbar: [
         [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      [{ align: [] }],
      ["blockquote", "code-block"],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      ["link", "image", "video"],
      ["clean"], 
      ],
    };

    const formats = [
  'header', 'font', 'size', 'align', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'video', 'color', 'background', 'script' ];
    
    useEffect(() => {
      if (pageType === "Template") {
        setActiveCardId(id)
        setShowTemplate(true);
        getAllTemplate().then(response=>{
          setAllTemplate(response.data);   
        })
      }
      else if(pageType==="Generate"){

      }
       else {
        setActiveCardId(id)
        getAllTemplate().then(response=>{
          setAllTemplate(response.data);   
        })
        setShowTemplate(false);
      }
    }, [pageType,id]);
    
    return (
      <Box>     
        <Box sx={{ display: 'flex', flexDirection: "row" }}>
          {pageType !== "Generate" && 
          <Box sx={{ width: '410px' }}>
            <Box sx={{ borderRight: '1px solid #DADADA' }}>
              {showTemplate && (
                <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', width: '380px', alignItems: 'center', m: 2 }}>
                  <Button variant="contained" onClick={handleNewTemplateButton} sx={{ bgcolor: '#1677FF', width: '145px', height: '42px', textTransform: 'none', borderRadius: '8px', boxShadow: 'none', '&:hover': { border: 'none', backgroundColor: '#1677FF', boxShadow: 'none' } }}>
                    New Template
                  </Button>
                  <SearchIcon sx={{ color: "#919191", fontSize: '22px' }} />
                </Box>
              )}

              <Box sx={{ height: '60vh', boxShadow: 'none', overflowY:'auto' }}>
                {allTemplate.map((card:any) => (
                  <Card
                    key={card.jdId}
                    onClick={() => handleCardClick(card.jdId)}
                    sx={{
                      height: '100px',
                      borderRadius: '0',
                      borderBottom: '1px dashed #DADADA',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      maxWidth: '400px',
                      gap: '10px',
                      boxShadow: 'none',
                      bgcolor: activeCardId === card.jdId ? '#F7FBF9' : 'white', 
                      cursor: 'pointer', 
                    }}
                  >
                    <img
                      style={{ width: '30px', marginLeft: '22px', marginTop: '4px' }}
                      src={activeCardId === card.jdId ? displayIcon : disableIcon} 
                      alt="Card Icon"
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                      <Typography sx={{ fontSize: '14px', fontWeight: 600, textAlign: 'left' }}>
                        {card.jdName}
                      </Typography>
                      <Typography sx={{ fontSize: '12px', color: '#A9A9A9', mr: '10px' }}>
                      <div dangerouslySetInnerHTML={{ __html: truncateText(card.jdTemplateText, 128) }} />
                      </Typography>
                    </Box>
                  </Card>
                ))}
              </Box>
            </Box>
          </Box> 
          }

          {/* Right section with textarea */}
          <Box sx={{ width: pageType !=="Generate"?'60vw':'90vw', m: 2 }}>
            {hideButton && (
              <Typography sx={{ color: '#27538D', fontWeight: 600, mb: '20px' }}>
                Edit Area
              </Typography>
            )}
                <ReactQuill
                style={{display:'flex',justifyContent:'flex-end', flexDirection:'column', height:'60vh', width: pageType==="Generate"? '88vw':'61.2vw', outline: 'none', border: 'none'}}
                  value={value}
                  onChange={setValue}
                  modules={modules}
                  formats={formats}
                  placeholder="Write something..."
                />              
          </Box>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: pageType==="Generate"? 'space-between': "flex-end", gap: '10px', mr: '15px'}}>
          {pageType==="Generate" && 
          <Box sx={{ml:'15px'}}>
          <RefreshButton>
          <img src={refreshIcon} alt="Refresh"/>
          Refresh
        </RefreshButton>
          </Box>
          }

          <Box sx={{ display: 'flex', gap:'10px'}}>
          <Button variant="contained" onClick={handleCancel} sx={{ border: 'none', bgcolor: 'transparent', color: '#848484', width: '116px', height: '42px', textTransform: 'none', borderRadius: '8px', boxShadow: 'none', '&:hover': { border: 'none', backgroundColor: 'transparent', boxShadow: 'none' } }}>
            Cancel
          </Button>
          {hideButton && (
            <>
              <Button variant="outlined" onClick={handleTemplateSave} sx={{ border: '1px solid #1677FF', color: '#1677FF', width: '185px', height: '42px', textTransform: 'none', borderRadius: '8px', boxShadow: 'none', '&:hover': { backgroundColor: 'transparent', boxShadow: 'none' } }}>
                Save as Template
              </Button>
              { ( pageType==='Generate'||showTemplate ) && (
               <Button variant="outlined" sx={{ border: '1px solid #1677FF', color: '#1677FF', width: '145px', height: '42px', textTransform: 'none', borderRadius: '8px', boxShadow: 'none', '&:hover': { backgroundColor: 'transparent', boxShadow: 'none' } }}>
                  Save as Draft
                </Button>
              )}
            </>
          )}
          <Button variant="contained" sx={{ bgcolor: '#1677FF', width: hideButton?'116px':'217px', height: '42px', textTransform: 'none', borderRadius: '8px', boxShadow: 'none', '&:hover': { border: 'none', backgroundColor: '#1677FF', boxShadow: 'none' } }}>
            {buttonName}
          </Button>          
          </Box>
        </Box>
      </Box>
    );
  };