export const cardResume=[
    {
        "label": "Waiting for JD Creation",
        "component": {
            "key": null,
            "ref": null,
            "props": {
                "data": [
                    {
                        "rthId": "66f3db12ff9b686dfd18b235",
                        "seqId": "RTH0002",
                        "userId": {
                            "id": "66ddad2bcc7a4404054954c7",
                            "email": "aanand@gmail.com",
                            "firstName": "Aanand",
                            "lastName": "MyTheron",
                            "role": "TEAM HEAD",
                            "mobNumb": "9999999999",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "typeOfRth": "Internal",
                        "modeOfRth": "OnRole",
                        "modesofRth":"JD Created",
                        "business": {
                            "businessId": "66c6d176eb1bf1110f1ca605",
                            "businessName": "CITPL",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:12 PM"
                        },
                        "positionTitle": "BackEnd",
                        "numOfPosition": "5",
                        "internalSpoc": null,
                        "posReportsTo": {
                            "id": "66ddad2bcc7a4404054954c7",
                            "email": "aanand@gmail.com",
                            "firstName": "Aanand",
                            "lastName": "MyTheron",
                            "role": "TEAM HEAD",
                            "mobNumb": "9999999999",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "l1Approval": {
                            "id": "66ddb14a9a657e68d477651a",
                            "email": "saravanan@gmail.com",
                            "firstName": "Saravanan",
                            "lastName": "MyTheron",
                            "role": "APPROVER HEAD",
                            "mobNumb": "8888888888",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908210417_Image%20(1).jfif?sig=%2BBC6MYuhuZpNQVipBPK8fRyBqIQkCMKK%2FB8eCsSD8zk%3D&se=2034-09-06T15%3A34%3A18Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "l2Approval": {
                            "id": "66ddb1959a657e68d477651b",
                            "email": "aishwarya@gmail.com",
                            "firstName": "Aishwarya",
                            "lastName": "MyTheron",
                            "role": "APPROVER HEAD",
                            "mobNumb": "7777777777",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908202041_aishwarya.jfif?sig=JtkoLvoAPLgh4ymAwrhwhXpGdsATYQ9yrRJCvMIdXyQ%3D&se=2034-09-06T14%3A50%3A41Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "reasonForHire": "Replacement",
                        "location": "Chennai",
                        "department": {
                            "departmentId": "66c6ce4beb1bf1110f1ca5e8",
                            "departmentName": "IT - Software Development",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:12 PM"
                        },
                        "band": {
                            "bandId": "66cd65fb90aacc60d698303b",
                            "bandName": "1A",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:12 PM"
                        },
                        "qualNeeded": {
                            "qualificationId": "66c6d283eb1bf1110f1ca607",
                            "degree": "B.E - Computer Science",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:12 PM"
                        },
                        "minExperience": "2",
                        "maxExperience": "5",
                        "ctc": "5LPA",
                        "primarySkills": [
                            {
                                "id": "66f25b19e403cd57cef6951b",
                                "skillName": "Java",
                                "status": "Active",
                                "createdAt": "9/24/24, 11:54 AM"
                            }
                        ],
                        "secondarySkills": [
                            {
                                "id": "66f25b20e403cd57cef6951c",
                                "skillName": "Python",
                                "status": "Active",
                                "createdAt": "9/24/24, 11:54 AM"
                            }
                        ],
                        "prefStartDate": "2024-09-25",
                        "workMode": "OnSite",
                        "priority": "High",
                        "comments": "xxxxxxxxxx",
                        "contractDur": null,
                        "contractType": null,
                        "clientName": null,
                        "clientPoc": null,
                        "approvalAttachment": null,
                        "clientRateCard": null,
                        "oneTimeCharge": null,
                        "oneTimeAmount": null,
                        "clientMarginInAmount": null,
                        "clientMarginInPer": null,
                        "l1Status": "Approved",
                        "l2Status": "Approved",
                        "assignedRecruiterLead": null,
                        "recruiterLeadAssigned": false,
                        "assignedRecruiter": null,
                        "recruiterAssigned": false,
                        "status": "TBA",
                        "rthProcess": null,
                        "rthModule": null,
                        "createdAt": "2024-09-25",
                        "updatedAt": null
                    },
                    {
                        "rthId": "66f3dc4823a4a806694a17e0",
                        "seqId": "RTH0004",
                        "userId": {
                            "id": "66ddad2bcc7a4404054954c7",
                            "email": "aanand@gmail.com",
                            "firstName": "Aanand",
                            "lastName": "MyTheron",
                            "role": "TEAM HEAD",
                            "mobNumb": "9999999999",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "typeOfRth": "Internal",
                        "modeOfRth": "OnRole",
                        "modesofRth":"JD Created",
                        "business": {
                            "businessId": "66c6d176eb1bf1110f1ca605",
                            "businessName": "CITPL",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:17 PM"
                        },
                        "positionTitle": "BackEnd",
                        "numOfPosition": "5",
                        "internalSpoc": null,
                        "posReportsTo": {
                            "id": "66ddad2bcc7a4404054954c7",
                            "email": "aanand@gmail.com",
                            "firstName": "Aanand",
                            "lastName": "MyTheron",
                            "role": "TEAM HEAD",
                            "mobNumb": "9999999999",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "l1Approval": {
                            "id": "66ddb14a9a657e68d477651a",
                            "email": "saravanan@gmail.com",
                            "firstName": "Saravanan",
                            "lastName": "MyTheron",
                            "role": "APPROVER HEAD",
                            "mobNumb": "8888888888",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908210417_Image%20(1).jfif?sig=%2BBC6MYuhuZpNQVipBPK8fRyBqIQkCMKK%2FB8eCsSD8zk%3D&se=2034-09-06T15%3A34%3A18Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "l2Approval": {
                            "id": "66ddb1959a657e68d477651b",
                            "email": "aishwarya@gmail.com",
                            "firstName": "Aishwarya",
                            "lastName": "MyTheron",
                            "role": "APPROVER HEAD",
                            "mobNumb": "7777777777",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908202041_aishwarya.jfif?sig=JtkoLvoAPLgh4ymAwrhwhXpGdsATYQ9yrRJCvMIdXyQ%3D&se=2034-09-06T14%3A50%3A41Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "reasonForHire": "Replacement",
                        "location": "Chennai",
                        "department": {
                            "departmentId": "66c6ce4beb1bf1110f1ca5e8",
                            "departmentName": "IT - Software Development",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:17 PM"
                        },
                        "band": {
                            "bandId": "66cd65fb90aacc60d698303b",
                            "bandName": "1A",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:17 PM"
                        },
                        "qualNeeded": {
                            "qualificationId": "66c6d283eb1bf1110f1ca607",
                            "degree": "B.E - Computer Science",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:17 PM"
                        },
                        "minExperience": "2",
                        "maxExperience": "5",
                        "ctc": "5LPA",
                        "primarySkills": [
                            {
                                "id": "66f25b19e403cd57cef6951b",
                                "skillName": "Java",
                                "status": "Active",
                                "createdAt": "9/24/24, 11:54 AM"
                            }
                        ],
                        "secondarySkills": [
                            {
                                "id": "66f25b20e403cd57cef6951c",
                                "skillName": "Python",
                                "status": "Active",
                                "createdAt": "9/24/24, 11:54 AM"
                            }
                        ],
                        "prefStartDate": "2024-09-25",
                        "workMode": "OnSite",
                        "priority": "High",
                        "comments": "xxxxxxxxxx",
                        "contractDur": null,
                        "contractType": null,
                        "clientName": null,
                        "clientPoc": null,
                        "approvalAttachment": null,
                        "clientRateCard": null,
                        "oneTimeCharge": null,
                        "oneTimeAmount": null,
                        "clientMarginInAmount": null,
                        "clientMarginInPer": null,
                        "l1Status": "Approved",
                        "l2Status": "Approved",
                        "assignedRecruiterLead": null,
                        "recruiterLeadAssigned": false,
                        "assignedRecruiter": null,
                        "recruiterAssigned": false,
                        "status": "TBA",
                        "rthProcess": null,
                        "rthModule": null,
                        "createdAt": "2024-09-25",
                        "updatedAt": null
                    },
                    {
                        "rthId": "66f3dc8c23a4a806694a17e1",
                        "seqId": "RTH0001",
                        "userId": {
                            "id": "66ddad2bcc7a4404054954c7",
                            "email": "aanand@gmail.com",
                            "firstName": "Aanand",
                            "lastName": "MyTheron",
                            "role": "TEAM HEAD",
                            "mobNumb": "9999999999",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "typeOfRth": "Internal",
                        "modeOfRth": "OnRole",
                        "modesofRth":"JD Created",
                        "business": {
                            "businessId": "66c6d176eb1bf1110f1ca605",
                            "businessName": "CITPL",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:19 PM"
                        },
                        "positionTitle": "BackEnd",
                        "numOfPosition": "5",
                        "internalSpoc": null,
                        "posReportsTo": {
                            "id": "66ddad2bcc7a4404054954c7",
                            "email": "aanand@gmail.com",
                            "firstName": "Aanand",
                            "lastName": "MyTheron",
                            "role": "TEAM HEAD",
                            "mobNumb": "9999999999",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "l1Approval": {
                            "id": "66ddb14a9a657e68d477651a",
                            "email": "saravanan@gmail.com",
                            "firstName": "Saravanan",
                            "lastName": "MyTheron",
                            "role": "APPROVER HEAD",
                            "mobNumb": "8888888888",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908210417_Image%20(1).jfif?sig=%2BBC6MYuhuZpNQVipBPK8fRyBqIQkCMKK%2FB8eCsSD8zk%3D&se=2034-09-06T15%3A34%3A18Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "l2Approval": {
                            "id": "66ddb1959a657e68d477651b",
                            "email": "aishwarya@gmail.com",
                            "firstName": "Aishwarya",
                            "lastName": "MyTheron",
                            "role": "APPROVER HEAD",
                            "mobNumb": "7777777777",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908202041_aishwarya.jfif?sig=JtkoLvoAPLgh4ymAwrhwhXpGdsATYQ9yrRJCvMIdXyQ%3D&se=2034-09-06T14%3A50%3A41Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "reasonForHire": "Replacement",
                        "location": "Chennai",
                        "department": {
                            "departmentId": "66c6ce4beb1bf1110f1ca5e8",
                            "departmentName": "IT - Software Development",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:19 PM"
                        },
                        "band": {
                            "bandId": "66cd65fb90aacc60d698303b",
                            "bandName": "1A",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:19 PM"
                        },
                        "qualNeeded": {
                            "qualificationId": "66c6d283eb1bf1110f1ca607",
                            "degree": "B.E - Computer Science",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:19 PM"
                        },
                        "minExperience": "2",
                        "maxExperience": "5",
                        "ctc": "5LPA",
                        "primarySkills": [
                            {
                                "id": "66f25b19e403cd57cef6951b",
                                "skillName": "Java",
                                "status": "Active",
                                "createdAt": "9/24/24, 11:54 AM"
                            }
                        ],
                        "secondarySkills": [
                            {
                                "id": "66f25b20e403cd57cef6951c",
                                "skillName": "Python",
                                "status": "Active",
                                "createdAt": "9/24/24, 11:54 AM"
                            }
                        ],
                        "prefStartDate": "2024-09-25",
                        "workMode": "OnSite",
                        "priority": "High",
                        "comments": "xxxxxxxxxx",
                        "contractDur": null,
                        "contractType": null,
                        "clientName": null,
                        "clientPoc": null,
                        "approvalAttachment": null,
                        "clientRateCard": null,
                        "oneTimeCharge": null,
                        "oneTimeAmount": null,
                        "clientMarginInAmount": null,
                        "clientMarginInPer": null,
                        "l1Status": "Approved",
                        "l2Status": "Approved",
                        "assignedRecruiterLead": null,
                        "recruiterLeadAssigned": false,
                        "assignedRecruiter": null,
                        "recruiterAssigned": false,
                        "status": "TBA",
                        "rthProcess": null,
                        "rthModule": null,
                        "createdAt": "2024-09-25",
                        "updatedAt": null
                    },
                    {
                        "rthId": "66f3dc9823a4a806694a17e2",
                        "seqId": "RTH0002",
                        "userId": {
                            "id": "66ddad2bcc7a4404054954c7",
                            "email": "aanand@gmail.com",
                            "firstName": "Aanand",
                            "lastName": "MyTheron",
                            "role": "TEAM HEAD",
                            "mobNumb": "9999999999",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "typeOfRth": "Internal",
                        "modeOfRth": "OnRole",
                        "modesofRth":"JD Created",
                        "business": {
                            "businessId": "66c6d176eb1bf1110f1ca605",
                            "businessName": "CITPL",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:19 PM"
                        },
                        "positionTitle": "BackEnd",
                        "numOfPosition": "5",
                        "internalSpoc": null,
                        "posReportsTo": {
                            "id": "66ddad2bcc7a4404054954c7",
                            "email": "aanand@gmail.com",
                            "firstName": "Aanand",
                            "lastName": "MyTheron",
                            "role": "TEAM HEAD",
                            "mobNumb": "9999999999",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "l1Approval": {
                            "id": "66ddb14a9a657e68d477651a",
                            "email": "saravanan@gmail.com",
                            "firstName": "Saravanan",
                            "lastName": "MyTheron",
                            "role": "APPROVER HEAD",
                            "mobNumb": "8888888888",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908210417_Image%20(1).jfif?sig=%2BBC6MYuhuZpNQVipBPK8fRyBqIQkCMKK%2FB8eCsSD8zk%3D&se=2034-09-06T15%3A34%3A18Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "l2Approval": {
                            "id": "66ddb1959a657e68d477651b",
                            "email": "aishwarya@gmail.com",
                            "firstName": "Aishwarya",
                            "lastName": "MyTheron",
                            "role": "APPROVER HEAD",
                            "mobNumb": "7777777777",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908202041_aishwarya.jfif?sig=JtkoLvoAPLgh4ymAwrhwhXpGdsATYQ9yrRJCvMIdXyQ%3D&se=2034-09-06T14%3A50%3A41Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "reasonForHire": "Replacement",
                        "location": "Chennai",
                        "department": {
                            "departmentId": "66c6ce4beb1bf1110f1ca5e8",
                            "departmentName": "IT - Software Development",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:19 PM"
                        },
                        "band": {
                            "bandId": "66cd65fb90aacc60d698303b",
                            "bandName": "1A",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:19 PM"
                        },
                        "qualNeeded": {
                            "qualificationId": "66c6d283eb1bf1110f1ca607",
                            "degree": "B.E - Computer Science",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:19 PM"
                        },
                        "minExperience": "2",
                        "maxExperience": "5",
                        "ctc": "5LPA",
                        "primarySkills": [
                            {
                                "id": "66f25b19e403cd57cef6951b",
                                "skillName": "Java",
                                "status": "Active",
                                "createdAt": "9/24/24, 11:54 AM"
                            }
                        ],
                        "secondarySkills": [
                            {
                                "id": "66f25b20e403cd57cef6951c",
                                "skillName": "Python",
                                "status": "Active",
                                "createdAt": "9/24/24, 11:54 AM"
                            }
                        ],
                        "prefStartDate": "2024-09-25",
                        "workMode": "OnSite",
                        "priority": "High",
                        "comments": "xxxxxxxxxx",
                        "contractDur": null,
                        "contractType": null,
                        "clientName": null,
                        "clientPoc": null,
                        "approvalAttachment": null,
                        "clientRateCard": null,
                        "oneTimeCharge": null,
                        "oneTimeAmount": null,
                        "clientMarginInAmount": null,
                        "clientMarginInPer": null,
                        "l1Status": "Approved",
                        "l2Status": "Approved",
                        "assignedRecruiterLead": null,
                        "recruiterLeadAssigned": false,
                        "assignedRecruiter": null,
                        "recruiterAssigned": false,
                        "status": "TBA",
                        "rthProcess": null,
                        "rthModule": null,
                        "createdAt": "2024-09-25",
                        "updatedAt": null
                    }
                ]
            },
            "_owner": {
                "tag": 0,
                "key": null,
                "stateNode": null,
                "return": null,
                "child": null,
                "sibling": null,
                "index": 0,
                "ref": null,
                "pendingProps": null,
                "memoizedProps": null,
                "updateQueue": null,
                "memoizedState": null,
                "dependencies": null,
                "mode": 27,
                "flags": 12617749,
                "subtreeFlags": 0,
                "deletions": null,
                "lanes": 0,
                "childLanes": 0,
                "alternate": null,
                "actualDuration": 34.80000019073486,
                "actualStartTime": 1419.7999999523163,
                "selfBaseDuration": 4.300000071525574,
                "treeBaseDuration": 4.299999952316284,
                "_debugSource": {
                    "fileName": "D:\\MYTHERON\\mytheron_frontend_react\\src\\components\\dashboard\\Rth\\resume\\RTHComponent.tsx",
                    "lineNumber": 16,
                    "columnNumber": 13
                },
                "_debugOwner": null,
                "_debugNeedsRemount": false,
                "_debugHookTypes": [
                    "useState",
                    "useState",
                    "useState",
                    "useState",
                    "useState",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useRef",
                    "useContext",
                    "useLayoutEffect",
                    "useCallback",
                    "useState",
                    "useContext",
                    "useRef",
                    "useCallback",
                    "useRef",
                    "useMemo",
                    "useSyncExternalStore",
                    "useEffect",
                    "useDebugValue",
                    "useDebugValue",
                    "useEffect",
                    "useEffect",
                    "useEffect"
                ]
            },
            "_store": {}
        },
        "data": [
            {
                "rthId": "66f3db12ff9b686dfd18b235",
                "seqId": "RTH0002",
                "userId": {
                    "id": "66ddad2bcc7a4404054954c7",
                    "email": "aanand@gmail.com",
                    "firstName": "Aanand",
                    "lastName": "MyTheron",
                    "role": "TEAM HEAD",
                    "mobNumb": "9999999999",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "typeOfRth": "Internal",
                "modeOfRth": "OnRole",
                "modesofRth":"JD Created",
                "business": {
                    "businessId": "66c6d176eb1bf1110f1ca605",
                    "businessName": "CITPL",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:12 PM"
                },
                "positionTitle": "BackEnd",
                "numOfPosition": "5",
                "internalSpoc": null,
                "posReportsTo": {
                    "id": "66ddad2bcc7a4404054954c7",
                    "email": "aanand@gmail.com",
                    "firstName": "Aanand",
                    "lastName": "MyTheron",
                    "role": "TEAM HEAD",
                    "mobNumb": "9999999999",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "l1Approval": {
                    "id": "66ddb14a9a657e68d477651a",
                    "email": "saravanan@gmail.com",
                    "firstName": "Saravanan",
                    "lastName": "MyTheron",
                    "role": "APPROVER HEAD",
                    "mobNumb": "8888888888",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908210417_Image%20(1).jfif?sig=%2BBC6MYuhuZpNQVipBPK8fRyBqIQkCMKK%2FB8eCsSD8zk%3D&se=2034-09-06T15%3A34%3A18Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "l2Approval": {
                    "id": "66ddb1959a657e68d477651b",
                    "email": "aishwarya@gmail.com",
                    "firstName": "Aishwarya",
                    "lastName": "MyTheron",
                    "role": "APPROVER HEAD",
                    "mobNumb": "7777777777",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908202041_aishwarya.jfif?sig=JtkoLvoAPLgh4ymAwrhwhXpGdsATYQ9yrRJCvMIdXyQ%3D&se=2034-09-06T14%3A50%3A41Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "reasonForHire": "Replacement",
                "location": "Chennai",
                "department": {
                    "departmentId": "66c6ce4beb1bf1110f1ca5e8",
                    "departmentName": "IT - Software Development",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:12 PM"
                },
                "band": {
                    "bandId": "66cd65fb90aacc60d698303b",
                    "bandName": "1A",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:12 PM"
                },
                "qualNeeded": {
                    "qualificationId": "66c6d283eb1bf1110f1ca607",
                    "degree": "B.E - Computer Science",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:12 PM"
                },
                "minExperience": "2",
                "maxExperience": "5",
                "ctc": "5LPA",
                "primarySkills": [
                    {
                        "id": "66f25b19e403cd57cef6951b",
                        "skillName": "Java",
                        "status": "Active",
                        "createdAt": "9/24/24, 11:54 AM"
                    }
                ],
                "secondarySkills": [
                    {
                        "id": "66f25b20e403cd57cef6951c",
                        "skillName": "Python",
                        "status": "Active",
                        "createdAt": "9/24/24, 11:54 AM"
                    }
                ],
                "prefStartDate": "2024-09-25",
                "workMode": "OnSite",
                "priority": "High",
                "comments": "xxxxxxxxxx",
                "contractDur": null,
                "contractType": null,
                "clientName": null,
                "clientPoc": null,
                "approvalAttachment": null,
                "clientRateCard": null,
                "oneTimeCharge": null,
                "oneTimeAmount": null,
                "clientMarginInAmount": null,
                "clientMarginInPer": null,
                "l1Status": "Approved",
                "l2Status": "Approved",
                "assignedRecruiterLead": null,
                "recruiterLeadAssigned": false,
                "assignedRecruiter": null,
                "recruiterAssigned": false,
                "status": "TBA",
                "rthProcess": null,
                "rthModule": null,
                "createdAt": "2024-09-25",
                "updatedAt": null
            },
            {
                "rthId": "66f3dc4823a4a806694a17e0",
                "seqId": "RTH0004",
                "userId": {
                    "id": "66ddad2bcc7a4404054954c7",
                    "email": "aanand@gmail.com",
                    "firstName": "Aanand",
                    "lastName": "MyTheron",
                    "role": "TEAM HEAD",
                    "mobNumb": "9999999999",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "typeOfRth": "Internal",
                "modeOfRth": "OnRole",
                "modesofRth":"JD Created",
                "business": {
                    "businessId": "66c6d176eb1bf1110f1ca605",
                    "businessName": "CITPL",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:17 PM"
                },
                "positionTitle": "BackEnd",
                "numOfPosition": "5",
                "internalSpoc": null,
                "posReportsTo": {
                    "id": "66ddad2bcc7a4404054954c7",
                    "email": "aanand@gmail.com",
                    "firstName": "Aanand",
                    "lastName": "MyTheron",
                    "role": "TEAM HEAD",
                    "mobNumb": "9999999999",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "l1Approval": {
                    "id": "66ddb14a9a657e68d477651a",
                    "email": "saravanan@gmail.com",
                    "firstName": "Saravanan",
                    "lastName": "MyTheron",
                    "role": "APPROVER HEAD",
                    "mobNumb": "8888888888",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908210417_Image%20(1).jfif?sig=%2BBC6MYuhuZpNQVipBPK8fRyBqIQkCMKK%2FB8eCsSD8zk%3D&se=2034-09-06T15%3A34%3A18Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "l2Approval": {
                    "id": "66ddb1959a657e68d477651b",
                    "email": "aishwarya@gmail.com",
                    "firstName": "Aishwarya",
                    "lastName": "MyTheron",
                    "role": "APPROVER HEAD",
                    "mobNumb": "7777777777",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908202041_aishwarya.jfif?sig=JtkoLvoAPLgh4ymAwrhwhXpGdsATYQ9yrRJCvMIdXyQ%3D&se=2034-09-06T14%3A50%3A41Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "reasonForHire": "Replacement",
                "location": "Chennai",
                "department": {
                    "departmentId": "66c6ce4beb1bf1110f1ca5e8",
                    "departmentName": "IT - Software Development",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:17 PM"
                },
                "band": {
                    "bandId": "66cd65fb90aacc60d698303b",
                    "bandName": "1A",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:17 PM"
                },
                "qualNeeded": {
                    "qualificationId": "66c6d283eb1bf1110f1ca607",
                    "degree": "B.E - Computer Science",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:17 PM"
                },
                "minExperience": "2",
                "maxExperience": "5",
                "ctc": "5LPA",
                "primarySkills": [
                    {
                        "id": "66f25b19e403cd57cef6951b",
                        "skillName": "Java",
                        "status": "Active",
                        "createdAt": "9/24/24, 11:54 AM"
                    }
                ],
                "secondarySkills": [
                    {
                        "id": "66f25b20e403cd57cef6951c",
                        "skillName": "Python",
                        "status": "Active",
                        "createdAt": "9/24/24, 11:54 AM"
                    }
                ],
                "prefStartDate": "2024-09-25",
                "workMode": "OnSite",
                "priority": "High",
                "comments": "xxxxxxxxxx",
                "contractDur": null,
                "contractType": null,
                "clientName": null,
                "clientPoc": null,
                "approvalAttachment": null,
                "clientRateCard": null,
                "oneTimeCharge": null,
                "oneTimeAmount": null,
                "clientMarginInAmount": null,
                "clientMarginInPer": null,
                "l1Status": "Approved",
                "l2Status": "Approved",
                "assignedRecruiterLead": null,
                "recruiterLeadAssigned": false,
                "assignedRecruiter": null,
                "recruiterAssigned": false,
                "status": "TBA",
                "rthProcess": null,
                "rthModule": null,
                "createdAt": "2024-09-25",
                "updatedAt": null
            },
            {
                "rthId": "66f3dc8c23a4a806694a17e1",
                "seqId": "RTH0001",
                "userId": {
                    "id": "66ddad2bcc7a4404054954c7",
                    "email": "aanand@gmail.com",
                    "firstName": "Aanand",
                    "lastName": "MyTheron",
                    "role": "TEAM HEAD",
                    "mobNumb": "9999999999",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "typeOfRth": "Internal",
                "modeOfRth": "OnRole",
                "modesofRth":"JD Created",
                "business": {
                    "businessId": "66c6d176eb1bf1110f1ca605",
                    "businessName": "CITPL",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:19 PM"
                },
                "positionTitle": "BackEnd",
                "numOfPosition": "5",
                "internalSpoc": null,
                "posReportsTo": {
                    "id": "66ddad2bcc7a4404054954c7",
                    "email": "aanand@gmail.com",
                    "firstName": "Aanand",
                    "lastName": "MyTheron",
                    "role": "TEAM HEAD",
                    "mobNumb": "9999999999",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "l1Approval": {
                    "id": "66ddb14a9a657e68d477651a",
                    "email": "saravanan@gmail.com",
                    "firstName": "Saravanan",
                    "lastName": "MyTheron",
                    "role": "APPROVER HEAD",
                    "mobNumb": "8888888888",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908210417_Image%20(1).jfif?sig=%2BBC6MYuhuZpNQVipBPK8fRyBqIQkCMKK%2FB8eCsSD8zk%3D&se=2034-09-06T15%3A34%3A18Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "l2Approval": {
                    "id": "66ddb1959a657e68d477651b",
                    "email": "aishwarya@gmail.com",
                    "firstName": "Aishwarya",
                    "lastName": "MyTheron",
                    "role": "APPROVER HEAD",
                    "mobNumb": "7777777777",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908202041_aishwarya.jfif?sig=JtkoLvoAPLgh4ymAwrhwhXpGdsATYQ9yrRJCvMIdXyQ%3D&se=2034-09-06T14%3A50%3A41Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "reasonForHire": "Replacement",
                "location": "Chennai",
                "department": {
                    "departmentId": "66c6ce4beb1bf1110f1ca5e8",
                    "departmentName": "IT - Software Development",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:19 PM"
                },
                "band": {
                    "bandId": "66cd65fb90aacc60d698303b",
                    "bandName": "1A",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:19 PM"
                },
                "qualNeeded": {
                    "qualificationId": "66c6d283eb1bf1110f1ca607",
                    "degree": "B.E - Computer Science",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:19 PM"
                },
                "minExperience": "2",
                "maxExperience": "5",
                "ctc": "5LPA",
                "primarySkills": [
                    {
                        "id": "66f25b19e403cd57cef6951b",
                        "skillName": "Java",
                        "status": "Active",
                        "createdAt": "9/24/24, 11:54 AM"
                    }
                ],
                "secondarySkills": [
                    {
                        "id": "66f25b20e403cd57cef6951c",
                        "skillName": "Python",
                        "status": "Active",
                        "createdAt": "9/24/24, 11:54 AM"
                    }
                ],
                "prefStartDate": "2024-09-25",
                "workMode": "OnSite",
                "priority": "High",
                "comments": "xxxxxxxxxx",
                "contractDur": null,
                "contractType": null,
                "clientName": null,
                "clientPoc": null,
                "approvalAttachment": null,
                "clientRateCard": null,
                "oneTimeCharge": null,
                "oneTimeAmount": null,
                "clientMarginInAmount": null,
                "clientMarginInPer": null,
                "l1Status": "Approved",
                "l2Status": "Approved",
                "assignedRecruiterLead": null,
                "recruiterLeadAssigned": false,
                "assignedRecruiter": null,
                "recruiterAssigned": false,
                "status": "TBA",
                "rthProcess": null,
                "rthModule": null,
                "createdAt": "2024-09-25",
                "updatedAt": null
            },
            {
                "rthId": "66f3dc9823a4a806694a17e2",
                "seqId": "RTH0002",
                "userId": {
                    "id": "66ddad2bcc7a4404054954c7",
                    "email": "aanand@gmail.com",
                    "firstName": "Aanand",
                    "lastName": "MyTheron",
                    "role": "TEAM HEAD",
                    "mobNumb": "9999999999",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "typeOfRth": "Internal",
                "modeOfRth": "OnRole",
                "modesofRth":"JD Created",
                "business": {
                    "businessId": "66c6d176eb1bf1110f1ca605",
                    "businessName": "CITPL",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:19 PM"
                },
                "positionTitle": "BackEnd",
                "numOfPosition": "5",
                "internalSpoc": null,
                "posReportsTo": {
                    "id": "66ddad2bcc7a4404054954c7",
                    "email": "aanand@gmail.com",
                    "firstName": "Aanand",
                    "lastName": "MyTheron",
                    "role": "TEAM HEAD",
                    "mobNumb": "9999999999",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "l1Approval": {
                    "id": "66ddb14a9a657e68d477651a",
                    "email": "saravanan@gmail.com",
                    "firstName": "Saravanan",
                    "lastName": "MyTheron",
                    "role": "APPROVER HEAD",
                    "mobNumb": "8888888888",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908210417_Image%20(1).jfif?sig=%2BBC6MYuhuZpNQVipBPK8fRyBqIQkCMKK%2FB8eCsSD8zk%3D&se=2034-09-06T15%3A34%3A18Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "l2Approval": {
                    "id": "66ddb1959a657e68d477651b",
                    "email": "aishwarya@gmail.com",
                    "firstName": "Aishwarya",
                    "lastName": "MyTheron",
                    "role": "APPROVER HEAD",
                    "mobNumb": "7777777777",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908202041_aishwarya.jfif?sig=JtkoLvoAPLgh4ymAwrhwhXpGdsATYQ9yrRJCvMIdXyQ%3D&se=2034-09-06T14%3A50%3A41Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "reasonForHire": "Replacement",
                "location": "Chennai",
                "department": {
                    "departmentId": "66c6ce4beb1bf1110f1ca5e8",
                    "departmentName": "IT - Software Development",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:19 PM"
                },
                "band": {
                    "bandId": "66cd65fb90aacc60d698303b",
                    "bandName": "1A",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:19 PM"
                },
                "qualNeeded": {
                    "qualificationId": "66c6d283eb1bf1110f1ca607",
                    "degree": "B.E - Computer Science",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:19 PM"
                },
                "minExperience": "2",
                "maxExperience": "5",
                "ctc": "5LPA",
                "primarySkills": [
                    {
                        "id": "66f25b19e403cd57cef6951b",
                        "skillName": "Java",
                        "status": "Active",
                        "createdAt": "9/24/24, 11:54 AM"
                    }
                ],
                "secondarySkills": [
                    {
                        "id": "66f25b20e403cd57cef6951c",
                        "skillName": "Python",
                        "status": "Active",
                        "createdAt": "9/24/24, 11:54 AM"
                    }
                ],
                "prefStartDate": "2024-09-25",
                "workMode": "OnSite",
                "priority": "High",
                "comments": "xxxxxxxxxx",
                "contractDur": null,
                "contractType": null,
                "clientName": null,
                "clientPoc": null,
                "approvalAttachment": null,
                "clientRateCard": null,
                "oneTimeCharge": null,
                "oneTimeAmount": null,
                "clientMarginInAmount": null,
                "clientMarginInPer": null,
                "l1Status": "Approved",
                "l2Status": "Approved",
                "assignedRecruiterLead": null,
                "recruiterLeadAssigned": false,
                "assignedRecruiter": null,
                "recruiterAssigned": false,
                "status": "TBA",
                "rthProcess": null,
                "rthModule": null,
                "createdAt": "2024-09-25",
                "updatedAt": null
            }
        ]
    },
    {
        "label": "Ready for JD Creation",
        "component": {
            "key": null,
            "ref": null,
            "props": {
                "data": [
                    {
                        "rthId": "66f3baee7cbc21562ee0b489",
                        "seqId": null,
                        "userId": {
                            "id": "66ddad2bcc7a4404054954c7",
                            "email": "aanand@gmail.com",
                            "firstName": "Aanand",
                            "lastName": "MyTheron",
                            "role": "TEAM HEAD",
                            "mobNumb": "9999999999",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "typeOfRth": "Internal",
                        "modeOfRth": "OnRole",
                        "business": {
                            "businessId": "66c6d176eb1bf1110f1ca605",
                            "businessName": "CITPL",
                            "status": "Active",
                            "createdAt": "9/25/24, 12:55 PM"
                        },
                        "positionTitle": "BackEnd",
                        "numOfPosition": "5",
                        "internalSpoc": null,
                        "posReportsTo": {
                            "id": "66ddad2bcc7a4404054954c7",
                            "email": "aanand@gmail.com",
                            "firstName": "Aanand",
                            "lastName": "MyTheron",
                            "role": "TEAM HEAD",
                            "mobNumb": "9999999999",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "l1Approval": {
                            "id": "66ddb14a9a657e68d477651a",
                            "email": "saravanan@gmail.com",
                            "firstName": "Saravanan",
                            "lastName": "MyTheron",
                            "role": "APPROVER HEAD",
                            "mobNumb": "8888888888",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908210417_Image%20(1).jfif?sig=%2BBC6MYuhuZpNQVipBPK8fRyBqIQkCMKK%2FB8eCsSD8zk%3D&se=2034-09-06T15%3A34%3A18Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "l2Approval": {
                            "id": "66ddb1959a657e68d477651b",
                            "email": "aishwarya@gmail.com",
                            "firstName": "Aishwarya",
                            "lastName": "MyTheron",
                            "role": "APPROVER HEAD",
                            "mobNumb": "7777777777",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908202041_aishwarya.jfif?sig=JtkoLvoAPLgh4ymAwrhwhXpGdsATYQ9yrRJCvMIdXyQ%3D&se=2034-09-06T14%3A50%3A41Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "reasonForHire": "Replacement",
                        "location": "Chennai",
                        "department": {
                            "departmentId": "66c6ce4beb1bf1110f1ca5e8",
                            "departmentName": "IT - Software Development",
                            "status": "Active",
                            "createdAt": "9/25/24, 12:55 PM"
                        },
                        "band": {
                            "bandId": "66cd65fb90aacc60d698303b",
                            "bandName": "1A",
                            "status": "Active",
                            "createdAt": "9/25/24, 12:55 PM"
                        },
                        "qualNeeded": {
                            "qualificationId": "66c6d283eb1bf1110f1ca607",
                            "degree": "B.E - Computer Science",
                            "status": "Active",
                            "createdAt": "9/25/24, 12:55 PM"
                        },
                        "minExperience": "2",
                        "maxExperience": "5",
                        "ctc": "5LPA",
                        "primarySkills": [
                            {
                                "id": "66f25b19e403cd57cef6951b",
                                "skillName": "Java",
                                "status": "Active",
                                "createdAt": "9/24/24, 11:54 AM"
                            }
                        ],
                        "secondarySkills": [
                            {
                                "id": "66f25b20e403cd57cef6951c",
                                "skillName": "Python",
                                "status": "Active",
                                "createdAt": "9/24/24, 11:54 AM"
                            }
                        ],
                        "prefStartDate": "2024-09-25",
                        "workMode": "OnSite",
                        "priority": "High",
                        "comments": "xxxxxxxxxx",
                        "contractDur": null,
                        "contractType": null,
                        "clientName": null,
                        "clientPoc": null,
                        "approvalAttachment": null,
                        "clientRateCard": null,
                        "oneTimeCharge": null,
                        "oneTimeAmount": null,
                        "clientMarginInAmount": null,
                        "clientMarginInPer": null,
                        "l1Status": "Approved",
                        "l2Status": "Pending",
                        "assignedRecruiterLead": null,
                        "recruiterLeadAssigned": false,
                        "assignedRecruiter": null,
                        "recruiterAssigned": false,
                        "status": "In Progress",
                        "rthProcess": null,
                        "rthModule": null,
                        "createdAt": "2024-09-25",
                        "updatedAt": null
                    }
                ]
            },
            "_owner": {
                "tag": 0,
                "key": null,
                "stateNode": null,
                "return": null,
                "child": null,
                "sibling": null,
                "index": 0,
                "ref": null,
                "pendingProps": null,
                "memoizedProps": null,
                "updateQueue": null,
                "memoizedState": null,
                "dependencies": null,
                "mode": 27,
                "flags": 12617749,
                "subtreeFlags": 0,
                "deletions": null,
                "lanes": 0,
                "childLanes": 0,
                "alternate": null,
                "actualDuration": 34.80000019073486,
                "actualStartTime": 1419.7999999523163,
                "selfBaseDuration": 4.300000071525574,
                "treeBaseDuration": 4.299999952316284,
                "_debugSource": {
                    "fileName": "D:\\MYTHERON\\mytheron_frontend_react\\src\\components\\dashboard\\Rth\\resume\\RTHComponent.tsx",
                    "lineNumber": 16,
                    "columnNumber": 13
                },
                "_debugOwner": null,
                "_debugNeedsRemount": false,
                "_debugHookTypes": [
                    "useState",
                    "useState",
                    "useState",
                    "useState",
                    "useState",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useRef",
                    "useContext",
                    "useLayoutEffect",
                    "useCallback",
                    "useState",
                    "useContext",
                    "useRef",
                    "useCallback",
                    "useRef",
                    "useMemo",
                    "useSyncExternalStore",
                    "useEffect",
                    "useDebugValue",
                    "useDebugValue",
                    "useEffect",
                    "useEffect",
                    "useEffect"
                ]
            },
            "_store": {}
        },
        "data": [
            {
                "rthId": "66f3baee7cbc21562ee0b489",
                "seqId": null,
                "userId": {
                    "id": "66ddad2bcc7a4404054954c7",
                    "email": "aanand@gmail.com",
                    "firstName": "Aanand",
                    "lastName": "MyTheron",
                    "role": "TEAM HEAD",
                    "mobNumb": "9999999999",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "typeOfRth": "Internal",
                "modeOfRth": "OnRole",
                "business": {
                    "businessId": "66c6d176eb1bf1110f1ca605",
                    "businessName": "CITPL",
                    "status": "Active",
                    "createdAt": "9/25/24, 12:55 PM"
                },
                "positionTitle": "BackEnd",
                "numOfPosition": "5",
                "internalSpoc": null,
                "posReportsTo": {
                    "id": "66ddad2bcc7a4404054954c7",
                    "email": "aanand@gmail.com",
                    "firstName": "Aanand",
                    "lastName": "MyTheron",
                    "role": "TEAM HEAD",
                    "mobNumb": "9999999999",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "l1Approval": {
                    "id": "66ddb14a9a657e68d477651a",
                    "email": "saravanan@gmail.com",
                    "firstName": "Saravanan",
                    "lastName": "MyTheron",
                    "role": "APPROVER HEAD",
                    "mobNumb": "8888888888",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908210417_Image%20(1).jfif?sig=%2BBC6MYuhuZpNQVipBPK8fRyBqIQkCMKK%2FB8eCsSD8zk%3D&se=2034-09-06T15%3A34%3A18Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "l2Approval": {
                    "id": "66ddb1959a657e68d477651b",
                    "email": "aishwarya@gmail.com",
                    "firstName": "Aishwarya",
                    "lastName": "MyTheron",
                    "role": "APPROVER HEAD",
                    "mobNumb": "7777777777",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908202041_aishwarya.jfif?sig=JtkoLvoAPLgh4ymAwrhwhXpGdsATYQ9yrRJCvMIdXyQ%3D&se=2034-09-06T14%3A50%3A41Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "reasonForHire": "Replacement",
                "location": "Chennai",
                "department": {
                    "departmentId": "66c6ce4beb1bf1110f1ca5e8",
                    "departmentName": "IT - Software Development",
                    "status": "Active",
                    "createdAt": "9/25/24, 12:55 PM"
                },
                "band": {
                    "bandId": "66cd65fb90aacc60d698303b",
                    "bandName": "1A",
                    "status": "Active",
                    "createdAt": "9/25/24, 12:55 PM"
                },
                "qualNeeded": {
                    "qualificationId": "66c6d283eb1bf1110f1ca607",
                    "degree": "B.E - Computer Science",
                    "status": "Active",
                    "createdAt": "9/25/24, 12:55 PM"
                },
                "minExperience": "2",
                "maxExperience": "5",
                "ctc": "5LPA",
                "primarySkills": [
                    {
                        "id": "66f25b19e403cd57cef6951b",
                        "skillName": "Java",
                        "status": "Active",
                        "createdAt": "9/24/24, 11:54 AM"
                    }
                ],
                "secondarySkills": [
                    {
                        "id": "66f25b20e403cd57cef6951c",
                        "skillName": "Python",
                        "status": "Active",
                        "createdAt": "9/24/24, 11:54 AM"
                    }
                ],
                "prefStartDate": "2024-09-25",
                "workMode": "OnSite",
                "priority": "High",
                "comments": "xxxxxxxxxx",
                "contractDur": null,
                "contractType": null,
                "clientName": null,
                "clientPoc": null,
                "approvalAttachment": null,
                "clientRateCard": null,
                "oneTimeCharge": null,
                "oneTimeAmount": null,
                "clientMarginInAmount": null,
                "clientMarginInPer": null,
                "l1Status": "Approved",
                "l2Status": "Pending",
                "assignedRecruiterLead": null,
                "recruiterLeadAssigned": false,
                "assignedRecruiter": null,
                "recruiterAssigned": false,
                "status": "In Progress",
                "rthProcess": null,
                "rthModule": null,
                "createdAt": "2024-09-25",
                "updatedAt": null
            }
        ]
    },
    {
        "label": "Job Post",
        "component": {
            "key": null,
            "ref": null,
            "props": {
                "data": [
                    {
                        "rthId": "66f3baee7cbc21562ee0b489",
                        "seqId": null,
                        "userId": {
                            "id": "66ddad2bcc7a4404054954c7",
                            "email": "aanand@gmail.com",
                            "firstName": "Aanand",
                            "lastName": "MyTheron",
                            "role": "TEAM HEAD",
                            "mobNumb": "9999999999",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "typeOfRth": "Internal",
                        "modeOfRth": "OnRole",
                        "business": {
                            "businessId": "66c6d176eb1bf1110f1ca605",
                            "businessName": "CITPL",
                            "status": "Active",
                            "createdAt": "9/25/24, 12:55 PM"
                        },
                        "positionTitle": "BackEnd",
                        "numOfPosition": "5",
                        "internalSpoc": null,
                        "posReportsTo": {
                            "id": "66ddad2bcc7a4404054954c7",
                            "email": "aanand@gmail.com",
                            "firstName": "Aanand",
                            "lastName": "MyTheron",
                            "role": "TEAM HEAD",
                            "mobNumb": "9999999999",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "l1Approval": {
                            "id": "66ddb14a9a657e68d477651a",
                            "email": "saravanan@gmail.com",
                            "firstName": "Saravanan",
                            "lastName": "MyTheron",
                            "role": "APPROVER HEAD",
                            "mobNumb": "8888888888",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908210417_Image%20(1).jfif?sig=%2BBC6MYuhuZpNQVipBPK8fRyBqIQkCMKK%2FB8eCsSD8zk%3D&se=2034-09-06T15%3A34%3A18Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "l2Approval": {
                            "id": "66ddb1959a657e68d477651b",
                            "email": "aishwarya@gmail.com",
                            "firstName": "Aishwarya",
                            "lastName": "MyTheron",
                            "role": "APPROVER HEAD",
                            "mobNumb": "7777777777",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908202041_aishwarya.jfif?sig=JtkoLvoAPLgh4ymAwrhwhXpGdsATYQ9yrRJCvMIdXyQ%3D&se=2034-09-06T14%3A50%3A41Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "reasonForHire": "Replacement",
                        "location": "Chennai",
                        "department": {
                            "departmentId": "66c6ce4beb1bf1110f1ca5e8",
                            "departmentName": "IT - Software Development",
                            "status": "Active",
                            "createdAt": "9/25/24, 12:55 PM"
                        },
                        "band": {
                            "bandId": "66cd65fb90aacc60d698303b",
                            "bandName": "1A",
                            "status": "Active",
                            "createdAt": "9/25/24, 12:55 PM"
                        },
                        "qualNeeded": {
                            "qualificationId": "66c6d283eb1bf1110f1ca607",
                            "degree": "B.E - Computer Science",
                            "status": "Active",
                            "createdAt": "9/25/24, 12:55 PM"
                        },
                        "minExperience": "2",
                        "maxExperience": "5",
                        "ctc": "5LPA",
                        "primarySkills": [
                            {
                                "id": "66f25b19e403cd57cef6951b",
                                "skillName": "Java",
                                "status": "Active",
                                "createdAt": "9/24/24, 11:54 AM"
                            }
                        ],
                        "secondarySkills": [
                            {
                                "id": "66f25b20e403cd57cef6951c",
                                "skillName": "Python",
                                "status": "Active",
                                "createdAt": "9/24/24, 11:54 AM"
                            }
                        ],
                        "prefStartDate": "2024-09-25",
                        "workMode": "OnSite",
                        "priority": "High",
                        "comments": "xxxxxxxxxx",
                        "contractDur": null,
                        "contractType": null,
                        "clientName": null,
                        "clientPoc": null,
                        "approvalAttachment": null,
                        "clientRateCard": null,
                        "oneTimeCharge": null,
                        "oneTimeAmount": null,
                        "clientMarginInAmount": null,
                        "clientMarginInPer": null,
                        "l1Status": "Approved",
                        "l2Status": "Pending",
                        "assignedRecruiterLead": null,
                        "recruiterLeadAssigned": false,
                        "assignedRecruiter": null,
                        "recruiterAssigned": false,
                        "status": "In Progress",
                        "rthProcess": null,
                        "rthModule": null,
                        "createdAt": "2024-09-25",
                        "updatedAt": null
                    }
                ]
            },
            "_owner": {
                "tag": 0,
                "key": null,
                "stateNode": null,
                "return": null,
                "child": null,
                "sibling": null,
                "index": 0,
                "ref": null,
                "pendingProps": null,
                "memoizedProps": null,
                "updateQueue": null,
                "memoizedState": null,
                "dependencies": null,
                "mode": 27,
                "flags": 12617749,
                "subtreeFlags": 0,
                "deletions": null,
                "lanes": 0,
                "childLanes": 0,
                "alternate": null,
                "actualDuration": 34.80000019073486,
                "actualStartTime": 1419.7999999523163,
                "selfBaseDuration": 4.300000071525574,
                "treeBaseDuration": 4.299999952316284,
                "_debugSource": {
                    "fileName": "D:\\MYTHERON\\mytheron_frontend_react\\src\\components\\dashboard\\Rth\\resume\\RTHComponent.tsx",
                    "lineNumber": 16,
                    "columnNumber": 13
                },
                "_debugOwner": null,
                "_debugNeedsRemount": false,
                "_debugHookTypes": [
                    "useState",
                    "useState",
                    "useState",
                    "useState",
                    "useState",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useRef",
                    "useContext",
                    "useLayoutEffect",
                    "useCallback",
                    "useState",
                    "useContext",
                    "useRef",
                    "useCallback",
                    "useRef",
                    "useMemo",
                    "useSyncExternalStore",
                    "useEffect",
                    "useDebugValue",
                    "useDebugValue",
                    "useEffect",
                    "useEffect",
                    "useEffect"
                ]
            },
            "_store": {}
        },
        "data": [
            {
                "rthId": "66f3baee7cbc21562ee0b489",
                "seqId": null,
                "userId": {
                    "id": "66ddad2bcc7a4404054954c7",
                    "email": "aanand@gmail.com",
                    "firstName": "Aanand",
                    "lastName": "MyTheron",
                    "role": "TEAM HEAD",
                    "mobNumb": "9999999999",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "typeOfRth": "Internal",
                "modeOfRth": "OnRole",
                "business": {
                    "businessId": "66c6d176eb1bf1110f1ca605",
                    "businessName": "CITPL",
                    "status": "Active",
                    "createdAt": "9/25/24, 12:55 PM"
                },
                "positionTitle": "BackEnd",
                "numOfPosition": "5",
                "internalSpoc": null,
                "posReportsTo": {
                    "id": "66ddad2bcc7a4404054954c7",
                    "email": "aanand@gmail.com",
                    "firstName": "Aanand",
                    "lastName": "MyTheron",
                    "role": "TEAM HEAD",
                    "mobNumb": "9999999999",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "l1Approval": {
                    "id": "66ddb14a9a657e68d477651a",
                    "email": "saravanan@gmail.com",
                    "firstName": "Saravanan",
                    "lastName": "MyTheron",
                    "role": "APPROVER HEAD",
                    "mobNumb": "8888888888",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908210417_Image%20(1).jfif?sig=%2BBC6MYuhuZpNQVipBPK8fRyBqIQkCMKK%2FB8eCsSD8zk%3D&se=2034-09-06T15%3A34%3A18Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "l2Approval": {
                    "id": "66ddb1959a657e68d477651b",
                    "email": "aishwarya@gmail.com",
                    "firstName": "Aishwarya",
                    "lastName": "MyTheron",
                    "role": "APPROVER HEAD",
                    "mobNumb": "7777777777",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908202041_aishwarya.jfif?sig=JtkoLvoAPLgh4ymAwrhwhXpGdsATYQ9yrRJCvMIdXyQ%3D&se=2034-09-06T14%3A50%3A41Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "reasonForHire": "Replacement",
                "location": "Chennai",
                "department": {
                    "departmentId": "66c6ce4beb1bf1110f1ca5e8",
                    "departmentName": "IT - Software Development",
                    "status": "Active",
                    "createdAt": "9/25/24, 12:55 PM"
                },
                "band": {
                    "bandId": "66cd65fb90aacc60d698303b",
                    "bandName": "1A",
                    "status": "Active",
                    "createdAt": "9/25/24, 12:55 PM"
                },
                "qualNeeded": {
                    "qualificationId": "66c6d283eb1bf1110f1ca607",
                    "degree": "B.E - Computer Science",
                    "status": "Active",
                    "createdAt": "9/25/24, 12:55 PM"
                },
                "minExperience": "2",
                "maxExperience": "5",
                "ctc": "5LPA",
                "primarySkills": [
                    {
                        "id": "66f25b19e403cd57cef6951b",
                        "skillName": "Java",
                        "status": "Active",
                        "createdAt": "9/24/24, 11:54 AM"
                    }
                ],
                "secondarySkills": [
                    {
                        "id": "66f25b20e403cd57cef6951c",
                        "skillName": "Python",
                        "status": "Active",
                        "createdAt": "9/24/24, 11:54 AM"
                    }
                ],
                "prefStartDate": "2024-09-25",
                "workMode": "OnSite",
                "priority": "High",
                "comments": "xxxxxxxxxx",
                "contractDur": null,
                "contractType": null,
                "clientName": null,
                "clientPoc": null,
                "approvalAttachment": null,
                "clientRateCard": null,
                "oneTimeCharge": null,
                "oneTimeAmount": null,
                "clientMarginInAmount": null,
                "clientMarginInPer": null,
                "l1Status": "Approved",
                "l2Status": "Pending",
                "assignedRecruiterLead": null,
                "recruiterLeadAssigned": false,
                "assignedRecruiter": null,
                "recruiterAssigned": false,
                "status": "In Progress",
                "rthProcess": null,
                "rthModule": null,
                "createdAt": "2024-09-25",
                "updatedAt": null
            }
        ]
    },
    {
        "label": "Resume",
        "component": {
            "key": null,
            "ref": null,
            "props": {
                "data": [
                    {
                        "rthId": "66f3db12ff9b686dfd18b235",
                        "seqId": "RTH0002",
                        "userId": {
                            "id": "66ddad2bcc7a4404054954c7",
                            "email": "aanand@gmail.com",
                            "firstName": "Aanand",
                            "lastName": "MyTheron",
                            "role": "TEAM HEAD",
                            "mobNumb": "9999999999",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "typeOfRth": "Internal",
                        "modeOfRth": "OnRole",
                        "modesofRth":"JD Created",
                        "business": {
                            "businessId": "66c6d176eb1bf1110f1ca605",
                            "businessName": "CITPL",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:12 PM"
                        },
                        "positionTitle": "Sr.Ui Ux Designer",
                        "numOfPosition": "5",
                        "internalSpoc": null,
                        "posReportsTo": {
                            "id": "66ddad2bcc7a4404054954c7",
                            "email": "aanand@gmail.com",
                            "firstName": "Aanand",
                            "lastName": "MyTheron",
                            "role": "TEAM HEAD",
                            "mobNumb": "9999999999",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "l1Approval": {
                            "id": "66ddb14a9a657e68d477651a",
                            "email": "saravanan@gmail.com",
                            "firstName": "Saravanan",
                            "lastName": "MyTheron",
                            "role": "APPROVER HEAD",
                            "mobNumb": "8888888888",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908210417_Image%20(1).jfif?sig=%2BBC6MYuhuZpNQVipBPK8fRyBqIQkCMKK%2FB8eCsSD8zk%3D&se=2034-09-06T15%3A34%3A18Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "l2Approval": {
                            "id": "66ddb1959a657e68d477651b",
                            "email": "aishwarya@gmail.com",
                            "firstName": "Aishwarya",
                            "lastName": "MyTheron",
                            "role": "APPROVER HEAD",
                            "mobNumb": "7777777777",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908202041_aishwarya.jfif?sig=JtkoLvoAPLgh4ymAwrhwhXpGdsATYQ9yrRJCvMIdXyQ%3D&se=2034-09-06T14%3A50%3A41Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "reasonForHire": "Replacement",
                        "location": "Chennai",
                        "department": {
                            "departmentId": "66c6ce4beb1bf1110f1ca5e8",
                            "departmentName": "IT - Software Development",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:12 PM"
                        },
                        "band": {
                            "bandId": "66cd65fb90aacc60d698303b",
                            "bandName": "1A",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:12 PM"
                        },
                        "qualNeeded": {
                            "qualificationId": "66c6d283eb1bf1110f1ca607",
                            "degree": "B.E - Computer Science",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:12 PM"
                        },
                        "minExperience": "2",
                        "maxExperience": "5",
                        "ctc": "5LPA",
                        "primarySkills": [
                            {
                                "id": "66f25b19e403cd57cef6951b",
                                "skillName": "Java",
                                "status": "Active",
                                "createdAt": "9/24/24, 11:54 AM"
                            }
                        ],
                        "secondarySkills": [
                            {
                                "id": "66f25b20e403cd57cef6951c",
                                "skillName": "Python",
                                "status": "Active",
                                "createdAt": "9/24/24, 11:54 AM"
                            }
                        ],
                        "prefStartDate": "2024-09-25",
                        "workMode": "OnSite",
                        "priority": "High",
                        "comments": "xxxxxxxxxx",
                        "contractDur": null,
                        "contractType": null,
                        "clientName": null,
                        "clientPoc": null,
                        "approvalAttachment": null,
                        "clientRateCard": null,
                        "oneTimeCharge": null,
                        "oneTimeAmount": null,
                        "clientMarginInAmount": null,
                        "clientMarginInPer": null,
                        "l1Status": "Approved",
                        "l2Status": "Approved",
                        "assignedRecruiterLead": null,
                        "recruiterLeadAssigned": false,
                        "assignedRecruiter": null,
                        "recruiterAssigned": false,
                        "status": "TBA",
                        "rthProcess": null,
                        "rthModule": null,
                        "createdAt": "2024-09-25",
                        "updatedAt": null
                    },
                    {
                        "rthId": "66f3dc4823a4a806694a17e0",
                        "seqId": "RTH0004",
                        "userId": {
                            "id": "66ddad2bcc7a4404054954c7",
                            "email": "aanand@gmail.com",
                            "firstName": "Aanand",
                            "lastName": "MyTheron",
                            "role": "TEAM HEAD",
                            "mobNumb": "9999999999",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "typeOfRth": "Internal",
                        "modeOfRth": "OnRole",
                        "modesofRth":"JD Created",
                        "business": {
                            "businessId": "66c6d176eb1bf1110f1ca605",
                            "businessName": "CITPL",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:17 PM"
                        },
                        "positionTitle": "BackEnd",
                        "numOfPosition": "5",
                        "internalSpoc": null,
                        "posReportsTo": {
                            "id": "66ddad2bcc7a4404054954c7",
                            "email": "aanand@gmail.com",
                            "firstName": "Aanand",
                            "lastName": "MyTheron",
                            "role": "TEAM HEAD",
                            "mobNumb": "9999999999",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "l1Approval": {
                            "id": "66ddb14a9a657e68d477651a",
                            "email": "saravanan@gmail.com",
                            "firstName": "Saravanan",
                            "lastName": "MyTheron",
                            "role": "APPROVER HEAD",
                            "mobNumb": "8888888888",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908210417_Image%20(1).jfif?sig=%2BBC6MYuhuZpNQVipBPK8fRyBqIQkCMKK%2FB8eCsSD8zk%3D&se=2034-09-06T15%3A34%3A18Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "l2Approval": {
                            "id": "66ddb1959a657e68d477651b",
                            "email": "aishwarya@gmail.com",
                            "firstName": "Aishwarya",
                            "lastName": "MyTheron",
                            "role": "APPROVER HEAD",
                            "mobNumb": "7777777777",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908202041_aishwarya.jfif?sig=JtkoLvoAPLgh4ymAwrhwhXpGdsATYQ9yrRJCvMIdXyQ%3D&se=2034-09-06T14%3A50%3A41Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "reasonForHire": "Replacement",
                        "location": "Chennai",
                        "department": {
                            "departmentId": "66c6ce4beb1bf1110f1ca5e8",
                            "departmentName": "IT - Software Development",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:17 PM"
                        },
                        "band": {
                            "bandId": "66cd65fb90aacc60d698303b",
                            "bandName": "1A",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:17 PM"
                        },
                        "qualNeeded": {
                            "qualificationId": "66c6d283eb1bf1110f1ca607",
                            "degree": "B.E - Computer Science",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:17 PM"
                        },
                        "minExperience": "2",
                        "maxExperience": "5",
                        "ctc": "5LPA",
                        "primarySkills": [
                            {
                                "id": "66f25b19e403cd57cef6951b",
                                "skillName": "Java",
                                "status": "Active",
                                "createdAt": "9/24/24, 11:54 AM"
                            }
                        ],
                        "secondarySkills": [
                            {
                                "id": "66f25b20e403cd57cef6951c",
                                "skillName": "Python",
                                "status": "Active",
                                "createdAt": "9/24/24, 11:54 AM"
                            }
                        ],
                        "prefStartDate": "2024-09-25",
                        "workMode": "OnSite",
                        "priority": "High",
                        "comments": "xxxxxxxxxx",
                        "contractDur": null,
                        "contractType": null,
                        "clientName": null,
                        "clientPoc": null,
                        "approvalAttachment": null,
                        "clientRateCard": null,
                        "oneTimeCharge": null,
                        "oneTimeAmount": null,
                        "clientMarginInAmount": null,
                        "clientMarginInPer": null,
                        "l1Status": "Approved",
                        "l2Status": "Approved",
                        "assignedRecruiterLead": null,
                        "recruiterLeadAssigned": false,
                        "assignedRecruiter": null,
                        "recruiterAssigned": false,
                        "status": "TBA",
                        "rthProcess": null,
                        "rthModule": null,
                        "createdAt": "2024-09-25",
                        "updatedAt": null
                    },
                    {
                        "rthId": "66f3dc8c23a4a806694a17e1",
                        "seqId": "RTH0001",
                        "userId": {
                            "id": "66ddad2bcc7a4404054954c7",
                            "email": "aanand@gmail.com",
                            "firstName": "Aanand",
                            "lastName": "MyTheron",
                            "role": "TEAM HEAD",
                            "mobNumb": "9999999999",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "typeOfRth": "Internal",
                        "modeOfRth": "OnRole",
                        "modesofRth":"JD Created",
                        "business": {
                            "businessId": "66c6d176eb1bf1110f1ca605",
                            "businessName": "CITPL",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:19 PM"
                        },
                        "positionTitle": "BackEnd",
                        "numOfPosition": "5",
                        "internalSpoc": null,
                        "posReportsTo": {
                            "id": "66ddad2bcc7a4404054954c7",
                            "email": "aanand@gmail.com",
                            "firstName": "Aanand",
                            "lastName": "MyTheron",
                            "role": "TEAM HEAD",
                            "mobNumb": "9999999999",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "l1Approval": {
                            "id": "66ddb14a9a657e68d477651a",
                            "email": "saravanan@gmail.com",
                            "firstName": "Saravanan",
                            "lastName": "MyTheron",
                            "role": "APPROVER HEAD",
                            "mobNumb": "8888888888",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908210417_Image%20(1).jfif?sig=%2BBC6MYuhuZpNQVipBPK8fRyBqIQkCMKK%2FB8eCsSD8zk%3D&se=2034-09-06T15%3A34%3A18Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "l2Approval": {
                            "id": "66ddb1959a657e68d477651b",
                            "email": "aishwarya@gmail.com",
                            "firstName": "Aishwarya",
                            "lastName": "MyTheron",
                            "role": "APPROVER HEAD",
                            "mobNumb": "7777777777",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908202041_aishwarya.jfif?sig=JtkoLvoAPLgh4ymAwrhwhXpGdsATYQ9yrRJCvMIdXyQ%3D&se=2034-09-06T14%3A50%3A41Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "reasonForHire": "Replacement",
                        "location": "Chennai",
                        "department": {
                            "departmentId": "66c6ce4beb1bf1110f1ca5e8",
                            "departmentName": "IT - Software Development",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:19 PM"
                        },
                        "band": {
                            "bandId": "66cd65fb90aacc60d698303b",
                            "bandName": "1A",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:19 PM"
                        },
                        "qualNeeded": {
                            "qualificationId": "66c6d283eb1bf1110f1ca607",
                            "degree": "B.E - Computer Science",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:19 PM"
                        },
                        "minExperience": "2",
                        "maxExperience": "5",
                        "ctc": "5LPA",
                        "primarySkills": [
                            {
                                "id": "66f25b19e403cd57cef6951b",
                                "skillName": "Java",
                                "status": "Active",
                                "createdAt": "9/24/24, 11:54 AM"
                            }
                        ],
                        "secondarySkills": [
                            {
                                "id": "66f25b20e403cd57cef6951c",
                                "skillName": "Python",
                                "status": "Active",
                                "createdAt": "9/24/24, 11:54 AM"
                            }
                        ],
                        "prefStartDate": "2024-09-25",
                        "workMode": "OnSite",
                        "priority": "High",
                        "comments": "xxxxxxxxxx",
                        "contractDur": null,
                        "contractType": null,
                        "clientName": null,
                        "clientPoc": null,
                        "approvalAttachment": null,
                        "clientRateCard": null,
                        "oneTimeCharge": null,
                        "oneTimeAmount": null,
                        "clientMarginInAmount": null,
                        "clientMarginInPer": null,
                        "l1Status": "Approved",
                        "l2Status": "Approved",
                        "assignedRecruiterLead": null,
                        "recruiterLeadAssigned": false,
                        "assignedRecruiter": null,
                        "recruiterAssigned": false,
                        "status": "TBA",
                        "rthProcess": null,
                        "rthModule": null,
                        "createdAt": "2024-09-25",
                        "updatedAt": null
                    },
                    {
                        "rthId": "66f3dc9823a4a806694a17e2",
                        "seqId": "RTH0002",
                        "userId": {
                            "id": "66ddad2bcc7a4404054954c7",
                            "email": "aanand@gmail.com",
                            "firstName": "Aanand",
                            "lastName": "MyTheron",
                            "role": "TEAM HEAD",
                            "mobNumb": "9999999999",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "typeOfRth": "Internal",
                        "modeOfRth": "OnRole",
                        "modesofRth":"JD Created",
                        "business": {
                            "businessId": "66c6d176eb1bf1110f1ca605",
                            "businessName": "CITPL",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:19 PM"
                        },
                        "positionTitle": "BackEnd",
                        "numOfPosition": "5",
                        "internalSpoc": null,
                        "posReportsTo": {
                            "id": "66ddad2bcc7a4404054954c7",
                            "email": "aanand@gmail.com",
                            "firstName": "Aanand",
                            "lastName": "MyTheron",
                            "role": "TEAM HEAD",
                            "mobNumb": "9999999999",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "l1Approval": {
                            "id": "66ddb14a9a657e68d477651a",
                            "email": "saravanan@gmail.com",
                            "firstName": "Saravanan",
                            "lastName": "MyTheron",
                            "role": "APPROVER HEAD",
                            "mobNumb": "8888888888",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908210417_Image%20(1).jfif?sig=%2BBC6MYuhuZpNQVipBPK8fRyBqIQkCMKK%2FB8eCsSD8zk%3D&se=2034-09-06T15%3A34%3A18Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "l2Approval": {
                            "id": "66ddb1959a657e68d477651b",
                            "email": "aishwarya@gmail.com",
                            "firstName": "Aishwarya",
                            "lastName": "MyTheron",
                            "role": "APPROVER HEAD",
                            "mobNumb": "7777777777",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908202041_aishwarya.jfif?sig=JtkoLvoAPLgh4ymAwrhwhXpGdsATYQ9yrRJCvMIdXyQ%3D&se=2034-09-06T14%3A50%3A41Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "reasonForHire": "Replacement",
                        "location": "Chennai",
                        "department": {
                            "departmentId": "66c6ce4beb1bf1110f1ca5e8",
                            "departmentName": "IT - Software Development",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:19 PM"
                        },
                        "band": {
                            "bandId": "66cd65fb90aacc60d698303b",
                            "bandName": "1A",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:19 PM"
                        },
                        "qualNeeded": {
                            "qualificationId": "66c6d283eb1bf1110f1ca607",
                            "degree": "B.E - Computer Science",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:19 PM"
                        },
                        "minExperience": "2",
                        "maxExperience": "5",
                        "ctc": "5LPA",
                        "primarySkills": [
                            {
                                "id": "66f25b19e403cd57cef6951b",
                                "skillName": "Java",
                                "status": "Active",
                                "createdAt": "9/24/24, 11:54 AM"
                            }
                        ],
                        "secondarySkills": [
                            {
                                "id": "66f25b20e403cd57cef6951c",
                                "skillName": "Python",
                                "status": "Active",
                                "createdAt": "9/24/24, 11:54 AM"
                            }
                        ],
                        "prefStartDate": "2024-09-25",
                        "workMode": "OnSite",
                        "priority": "High",
                        "comments": "xxxxxxxxxx",
                        "contractDur": null,
                        "contractType": null,
                        "clientName": null,
                        "clientPoc": null,
                        "approvalAttachment": null,
                        "clientRateCard": null,
                        "oneTimeCharge": null,
                        "oneTimeAmount": null,
                        "clientMarginInAmount": null,
                        "clientMarginInPer": null,
                        "l1Status": "Approved",
                        "l2Status": "Approved",
                        "assignedRecruiterLead": null,
                        "recruiterLeadAssigned": false,
                        "assignedRecruiter": null,
                        "recruiterAssigned": false,
                        "status": "TBA",
                        "rthProcess": null,
                        "rthModule": null,
                        "createdAt": "2024-09-25",
                        "updatedAt": null
                    }
                ]
            },
            "_owner": {
                "tag": 0,
                "key": null,
                "stateNode": null,
                "return": null,
                "child": null,
                "sibling": null,
                "index": 0,
                "ref": null,
                "pendingProps": null,
                "memoizedProps": null,
                "updateQueue": null,
                "memoizedState": null,
                "dependencies": null,
                "mode": 27,
                "flags": 12617749,
                "subtreeFlags": 0,
                "deletions": null,
                "lanes": 0,
                "childLanes": 0,
                "alternate": null,
                "actualDuration": 34.80000019073486,
                "actualStartTime": 1419.7999999523163,
                "selfBaseDuration": 4.300000071525574,
                "treeBaseDuration": 4.299999952316284,
                "_debugSource": {
                    "fileName": "D:\\MYTHERON\\mytheron_frontend_react\\src\\components\\dashboard\\Rth\\resume\\RTHComponent.tsx",
                    "lineNumber": 16,
                    "columnNumber": 13
                },
                "_debugOwner": null,
                "_debugNeedsRemount": false,
                "_debugHookTypes": [
                    "useState",
                    "useState",
                    "useState",
                    "useState",
                    "useState",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useRef",
                    "useContext",
                    "useLayoutEffect",
                    "useCallback",
                    "useState",
                    "useContext",
                    "useRef",
                    "useCallback",
                    "useRef",
                    "useMemo",
                    "useSyncExternalStore",
                    "useEffect",
                    "useDebugValue",
                    "useDebugValue",
                    "useEffect",
                    "useEffect",
                    "useEffect"
                ]
            },
            "_store": {}
        },
        "data": [
            {
                "rthId": "66f3db12ff9b686dfd18b235",
                "seqId": "RTH0002",
                "userId": {
                    "id": "66ddad2bcc7a4404054954c7",
                    "email": "aanand@gmail.com",
                    "firstName": "Aanand",
                    "lastName": "MyTheron",
                    "role": "TEAM HEAD",
                    "mobNumb": "9999999999",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "typeOfRth": "Internal",
                "modeOfRth": "OnRole",
                "modesofRth":"JD Created",
                "business": {
                    "businessId": "66c6d176eb1bf1110f1ca605",
                    "businessName": "CITPL",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:12 PM"
                },
                "positionTitle": "Sr.Ui Ux Designer",
                "numOfPosition": "5",
                "internalSpoc": null,
                "posReportsTo": {
                    "id": "66ddad2bcc7a4404054954c7",
                    "email": "aanand@gmail.com",
                    "firstName": "Aanand",
                    "lastName": "MyTheron",
                    "role": "TEAM HEAD",
                    "mobNumb": "9999999999",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "l1Approval": {
                    "id": "66ddb14a9a657e68d477651a",
                    "email": "saravanan@gmail.com",
                    "firstName": "Saravanan",
                    "lastName": "MyTheron",
                    "role": "APPROVER HEAD",
                    "mobNumb": "8888888888",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908210417_Image%20(1).jfif?sig=%2BBC6MYuhuZpNQVipBPK8fRyBqIQkCMKK%2FB8eCsSD8zk%3D&se=2034-09-06T15%3A34%3A18Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "l2Approval": {
                    "id": "66ddb1959a657e68d477651b",
                    "email": "aishwarya@gmail.com",
                    "firstName": "Aishwarya",
                    "lastName": "MyTheron",
                    "role": "APPROVER HEAD",
                    "mobNumb": "7777777777",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908202041_aishwarya.jfif?sig=JtkoLvoAPLgh4ymAwrhwhXpGdsATYQ9yrRJCvMIdXyQ%3D&se=2034-09-06T14%3A50%3A41Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "reasonForHire": "Replacement",
                "location": "Chennai",
                "department": {
                    "departmentId": "66c6ce4beb1bf1110f1ca5e8",
                    "departmentName": "IT - Software Development",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:12 PM"
                },
                "band": {
                    "bandId": "66cd65fb90aacc60d698303b",
                    "bandName": "1A",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:12 PM"
                },
                "qualNeeded": {
                    "qualificationId": "66c6d283eb1bf1110f1ca607",
                    "degree": "B.E - Computer Science",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:12 PM"
                },
                "minExperience": "2",
                "maxExperience": "5",
                "ctc": "5LPA",
                "primarySkills": [
                    {
                        "id": "66f25b19e403cd57cef6951b",
                        "skillName": "Java",
                        "status": "Active",
                        "createdAt": "9/24/24, 11:54 AM"
                    }
                ],
                "secondarySkills": [
                    {
                        "id": "66f25b20e403cd57cef6951c",
                        "skillName": "Python",
                        "status": "Active",
                        "createdAt": "9/24/24, 11:54 AM"
                    }
                ],
                "prefStartDate": "2024-09-25",
                "workMode": "OnSite",
                "priority": "High",
                "comments": "xxxxxxxxxx",
                "contractDur": null,
                "contractType": null,
                "clientName": null,
                "clientPoc": null,
                "approvalAttachment": null,
                "clientRateCard": null,
                "oneTimeCharge": null,
                "oneTimeAmount": null,
                "clientMarginInAmount": null,
                "clientMarginInPer": null,
                "l1Status": "Approved",
                "l2Status": "Approved",
                "assignedRecruiterLead": null,
                "recruiterLeadAssigned": false,
                "assignedRecruiter": null,
                "recruiterAssigned": false,
                "status": "TBA",
                "rthProcess": null,
                "rthModule": null,
                "createdAt": "2024-09-25",
                "updatedAt": null
            },
            // {
            //     "rthId": "66f3dc4823a4a806694a17e0",
            //     "seqId": "RTH0004",
            //     "userId": {
            //         "id": "66ddad2bcc7a4404054954c7",
            //         "email": "aanand@gmail.com",
            //         "firstName": "Aanand",
            //         "lastName": "MyTheron",
            //         "role": "TEAM HEAD",
            //         "mobNumb": "9999999999",
            //         "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
            //         "enabled": true,
            //         "accountNonExpired": true,
            //         "accountNonLocked": true,
            //         "credentialsNonExpired": true
            //     },
            //     "typeOfRth": "Internal",
            //     "modeOfRth": "OnRole",
            //     "modesofRth":"JD Created",
            //     "business": {
            //         "businessId": "66c6d176eb1bf1110f1ca605",
            //         "businessName": "CITPL",
            //         "status": "Active",
            //         "createdAt": "9/25/24, 3:17 PM"
            //     },
            //     "positionTitle": "BackEnd",
            //     "numOfPosition": "5",
            //     "internalSpoc": null,
            //     "posReportsTo": {
            //         "id": "66ddad2bcc7a4404054954c7",
            //         "email": "aanand@gmail.com",
            //         "firstName": "Aanand",
            //         "lastName": "MyTheron",
            //         "role": "TEAM HEAD",
            //         "mobNumb": "9999999999",
            //         "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
            //         "enabled": true,
            //         "accountNonExpired": true,
            //         "accountNonLocked": true,
            //         "credentialsNonExpired": true
            //     },
            //     "l1Approval": {
            //         "id": "66ddb14a9a657e68d477651a",
            //         "email": "saravanan@gmail.com",
            //         "firstName": "Saravanan",
            //         "lastName": "MyTheron",
            //         "role": "APPROVER HEAD",
            //         "mobNumb": "8888888888",
            //         "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908210417_Image%20(1).jfif?sig=%2BBC6MYuhuZpNQVipBPK8fRyBqIQkCMKK%2FB8eCsSD8zk%3D&se=2034-09-06T15%3A34%3A18Z&sv=2019-02-02&sp=rl&sr=c",
            //         "enabled": true,
            //         "accountNonExpired": true,
            //         "accountNonLocked": true,
            //         "credentialsNonExpired": true
            //     },
            //     "l2Approval": {
            //         "id": "66ddb1959a657e68d477651b",
            //         "email": "aishwarya@gmail.com",
            //         "firstName": "Aishwarya",
            //         "lastName": "MyTheron",
            //         "role": "APPROVER HEAD",
            //         "mobNumb": "7777777777",
            //         "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908202041_aishwarya.jfif?sig=JtkoLvoAPLgh4ymAwrhwhXpGdsATYQ9yrRJCvMIdXyQ%3D&se=2034-09-06T14%3A50%3A41Z&sv=2019-02-02&sp=rl&sr=c",
            //         "enabled": true,
            //         "accountNonExpired": true,
            //         "accountNonLocked": true,
            //         "credentialsNonExpired": true
            //     },
            //     "reasonForHire": "Replacement",
            //     "location": "Chennai",
            //     "department": {
            //         "departmentId": "66c6ce4beb1bf1110f1ca5e8",
            //         "departmentName": "IT - Software Development",
            //         "status": "Active",
            //         "createdAt": "9/25/24, 3:17 PM"
            //     },
            //     "band": {
            //         "bandId": "66cd65fb90aacc60d698303b",
            //         "bandName": "1A",
            //         "status": "Active",
            //         "createdAt": "9/25/24, 3:17 PM"
            //     },
            //     "qualNeeded": {
            //         "qualificationId": "66c6d283eb1bf1110f1ca607",
            //         "degree": "B.E - Computer Science",
            //         "status": "Active",
            //         "createdAt": "9/25/24, 3:17 PM"
            //     },
            //     "minExperience": "2",
            //     "maxExperience": "5",
            //     "ctc": "5LPA",
            //     "primarySkills": [
            //         {
            //             "id": "66f25b19e403cd57cef6951b",
            //             "skillName": "Java",
            //             "status": "Active",
            //             "createdAt": "9/24/24, 11:54 AM"
            //         }
            //     ],
            //     "secondarySkills": [
            //         {
            //             "id": "66f25b20e403cd57cef6951c",
            //             "skillName": "Python",
            //             "status": "Active",
            //             "createdAt": "9/24/24, 11:54 AM"
            //         }
            //     ],
            //     "prefStartDate": "2024-09-25",
            //     "workMode": "OnSite",
            //     "priority": "High",
            //     "comments": "xxxxxxxxxx",
            //     "contractDur": null,
            //     "contractType": null,
            //     "clientName": null,
            //     "clientPoc": null,
            //     "approvalAttachment": null,
            //     "clientRateCard": null,
            //     "oneTimeCharge": null,
            //     "oneTimeAmount": null,
            //     "clientMarginInAmount": null,
            //     "clientMarginInPer": null,
            //     "l1Status": "Approved",
            //     "l2Status": "Approved",
            //     "assignedRecruiterLead": null,
            //     "recruiterLeadAssigned": false,
            //     "assignedRecruiter": null,
            //     "recruiterAssigned": false,
            //     "status": "TBA",
            //     "rthProcess": null,
            //     "rthModule": null,
            //     "createdAt": "2024-09-25",
            //     "updatedAt": null
            // },
            // {
            //     "rthId": "66f3dc8c23a4a806694a17e1",
            //     "seqId": "RTH0001",
            //     "userId": {
            //         "id": "66ddad2bcc7a4404054954c7",
            //         "email": "aanand@gmail.com",
            //         "firstName": "Aanand",
            //         "lastName": "MyTheron",
            //         "role": "TEAM HEAD",
            //         "mobNumb": "9999999999",
            //         "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
            //         "enabled": true,
            //         "accountNonExpired": true,
            //         "accountNonLocked": true,
            //         "credentialsNonExpired": true
            //     },
            //     "typeOfRth": "Internal",
            //     "modeOfRth": "OnRole",
            //     "modesofRth":"JD Created",
            //     "business": {
            //         "businessId": "66c6d176eb1bf1110f1ca605",
            //         "businessName": "CITPL",
            //         "status": "Active",
            //         "createdAt": "9/25/24, 3:19 PM"
            //     },
            //     "positionTitle": "BackEnd",
            //     "numOfPosition": "5",
            //     "internalSpoc": null,
            //     "posReportsTo": {
            //         "id": "66ddad2bcc7a4404054954c7",
            //         "email": "aanand@gmail.com",
            //         "firstName": "Aanand",
            //         "lastName": "MyTheron",
            //         "role": "TEAM HEAD",
            //         "mobNumb": "9999999999",
            //         "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
            //         "enabled": true,
            //         "accountNonExpired": true,
            //         "accountNonLocked": true,
            //         "credentialsNonExpired": true
            //     },
            //     "l1Approval": {
            //         "id": "66ddb14a9a657e68d477651a",
            //         "email": "saravanan@gmail.com",
            //         "firstName": "Saravanan",
            //         "lastName": "MyTheron",
            //         "role": "APPROVER HEAD",
            //         "mobNumb": "8888888888",
            //         "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908210417_Image%20(1).jfif?sig=%2BBC6MYuhuZpNQVipBPK8fRyBqIQkCMKK%2FB8eCsSD8zk%3D&se=2034-09-06T15%3A34%3A18Z&sv=2019-02-02&sp=rl&sr=c",
            //         "enabled": true,
            //         "accountNonExpired": true,
            //         "accountNonLocked": true,
            //         "credentialsNonExpired": true
            //     },
            //     "l2Approval": {
            //         "id": "66ddb1959a657e68d477651b",
            //         "email": "aishwarya@gmail.com",
            //         "firstName": "Aishwarya",
            //         "lastName": "MyTheron",
            //         "role": "APPROVER HEAD",
            //         "mobNumb": "7777777777",
            //         "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908202041_aishwarya.jfif?sig=JtkoLvoAPLgh4ymAwrhwhXpGdsATYQ9yrRJCvMIdXyQ%3D&se=2034-09-06T14%3A50%3A41Z&sv=2019-02-02&sp=rl&sr=c",
            //         "enabled": true,
            //         "accountNonExpired": true,
            //         "accountNonLocked": true,
            //         "credentialsNonExpired": true
            //     },
            //     "reasonForHire": "Replacement",
            //     "location": "Chennai",
            //     "department": {
            //         "departmentId": "66c6ce4beb1bf1110f1ca5e8",
            //         "departmentName": "IT - Software Development",
            //         "status": "Active",
            //         "createdAt": "9/25/24, 3:19 PM"
            //     },
            //     "band": {
            //         "bandId": "66cd65fb90aacc60d698303b",
            //         "bandName": "1A",
            //         "status": "Active",
            //         "createdAt": "9/25/24, 3:19 PM"
            //     },
            //     "qualNeeded": {
            //         "qualificationId": "66c6d283eb1bf1110f1ca607",
            //         "degree": "B.E - Computer Science",
            //         "status": "Active",
            //         "createdAt": "9/25/24, 3:19 PM"
            //     },
            //     "minExperience": "2",
            //     "maxExperience": "5",
            //     "ctc": "5LPA",
            //     "primarySkills": [
            //         {
            //             "id": "66f25b19e403cd57cef6951b",
            //             "skillName": "Java",
            //             "status": "Active",
            //             "createdAt": "9/24/24, 11:54 AM"
            //         }
            //     ],
            //     "secondarySkills": [
            //         {
            //             "id": "66f25b20e403cd57cef6951c",
            //             "skillName": "Python",
            //             "status": "Active",
            //             "createdAt": "9/24/24, 11:54 AM"
            //         }
            //     ],
            //     "prefStartDate": "2024-09-25",
            //     "workMode": "OnSite",
            //     "priority": "High",
            //     "comments": "xxxxxxxxxx",
            //     "contractDur": null,
            //     "contractType": null,
            //     "clientName": null,
            //     "clientPoc": null,
            //     "approvalAttachment": null,
            //     "clientRateCard": null,
            //     "oneTimeCharge": null,
            //     "oneTimeAmount": null,
            //     "clientMarginInAmount": null,
            //     "clientMarginInPer": null,
            //     "l1Status": "Approved",
            //     "l2Status": "Approved",
            //     "assignedRecruiterLead": null,
            //     "recruiterLeadAssigned": false,
            //     "assignedRecruiter": null,
            //     "recruiterAssigned": false,
            //     "status": "TBA",
            //     "rthProcess": null,
            //     "rthModule": null,
            //     "createdAt": "2024-09-25",
            //     "updatedAt": null
            // },
            // {
            //     "rthId": "66f3dc9823a4a806694a17e2",
            //     "seqId": "RTH0002",
            //     "userId": {
            //         "id": "66ddad2bcc7a4404054954c7",
            //         "email": "aanand@gmail.com",
            //         "firstName": "Aanand",
            //         "lastName": "MyTheron",
            //         "role": "TEAM HEAD",
            //         "mobNumb": "9999999999",
            //         "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
            //         "enabled": true,
            //         "accountNonExpired": true,
            //         "accountNonLocked": true,
            //         "credentialsNonExpired": true
            //     },
            //     "typeOfRth": "Internal",
            //     "modeOfRth": "OnRole",
            //     "modesofRth":"JD Created",
            //     "business": {
            //         "businessId": "66c6d176eb1bf1110f1ca605",
            //         "businessName": "CITPL",
            //         "status": "Active",
            //         "createdAt": "9/25/24, 3:19 PM"
            //     },
            //     "positionTitle": "BackEnd",
            //     "numOfPosition": "5",
            //     "internalSpoc": null,
            //     "posReportsTo": {
            //         "id": "66ddad2bcc7a4404054954c7",
            //         "email": "aanand@gmail.com",
            //         "firstName": "Aanand",
            //         "lastName": "MyTheron",
            //         "role": "TEAM HEAD",
            //         "mobNumb": "9999999999",
            //         "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
            //         "enabled": true,
            //         "accountNonExpired": true,
            //         "accountNonLocked": true,
            //         "credentialsNonExpired": true
            //     },
            //     "l1Approval": {
            //         "id": "66ddb14a9a657e68d477651a",
            //         "email": "saravanan@gmail.com",
            //         "firstName": "Saravanan",
            //         "lastName": "MyTheron",
            //         "role": "APPROVER HEAD",
            //         "mobNumb": "8888888888",
            //         "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908210417_Image%20(1).jfif?sig=%2BBC6MYuhuZpNQVipBPK8fRyBqIQkCMKK%2FB8eCsSD8zk%3D&se=2034-09-06T15%3A34%3A18Z&sv=2019-02-02&sp=rl&sr=c",
            //         "enabled": true,
            //         "accountNonExpired": true,
            //         "accountNonLocked": true,
            //         "credentialsNonExpired": true
            //     },
            //     "l2Approval": {
            //         "id": "66ddb1959a657e68d477651b",
            //         "email": "aishwarya@gmail.com",
            //         "firstName": "Aishwarya",
            //         "lastName": "MyTheron",
            //         "role": "APPROVER HEAD",
            //         "mobNumb": "7777777777",
            //         "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908202041_aishwarya.jfif?sig=JtkoLvoAPLgh4ymAwrhwhXpGdsATYQ9yrRJCvMIdXyQ%3D&se=2034-09-06T14%3A50%3A41Z&sv=2019-02-02&sp=rl&sr=c",
            //         "enabled": true,
            //         "accountNonExpired": true,
            //         "accountNonLocked": true,
            //         "credentialsNonExpired": true
            //     },
            //     "reasonForHire": "Replacement",
            //     "location": "Chennai",
            //     "department": {
            //         "departmentId": "66c6ce4beb1bf1110f1ca5e8",
            //         "departmentName": "IT - Software Development",
            //         "status": "Active",
            //         "createdAt": "9/25/24, 3:19 PM"
            //     },
            //     "band": {
            //         "bandId": "66cd65fb90aacc60d698303b",
            //         "bandName": "1A",
            //         "status": "Active",
            //         "createdAt": "9/25/24, 3:19 PM"
            //     },
            //     "qualNeeded": {
            //         "qualificationId": "66c6d283eb1bf1110f1ca607",
            //         "degree": "B.E - Computer Science",
            //         "status": "Active",
            //         "createdAt": "9/25/24, 3:19 PM"
            //     },
            //     "minExperience": "2",
            //     "maxExperience": "5",
            //     "ctc": "5LPA",
            //     "primarySkills": [
            //         {
            //             "id": "66f25b19e403cd57cef6951b",
            //             "skillName": "Java",
            //             "status": "Active",
            //             "createdAt": "9/24/24, 11:54 AM"
            //         }
            //     ],
            //     "secondarySkills": [
            //         {
            //             "id": "66f25b20e403cd57cef6951c",
            //             "skillName": "Python",
            //             "status": "Active",
            //             "createdAt": "9/24/24, 11:54 AM"
            //         }
            //     ],
            //     "prefStartDate": "2024-09-25",
            //     "workMode": "OnSite",
            //     "priority": "High",
            //     "comments": "xxxxxxxxxx",
            //     "contractDur": null,
            //     "contractType": null,
            //     "clientName": null,
            //     "clientPoc": null,
            //     "approvalAttachment": null,
            //     "clientRateCard": null,
            //     "oneTimeCharge": null,
            //     "oneTimeAmount": null,
            //     "clientMarginInAmount": null,
            //     "clientMarginInPer": null,
            //     "l1Status": "Approved",
            //     "l2Status": "Approved",
            //     "assignedRecruiterLead": null,
            //     "recruiterLeadAssigned": false,
            //     "assignedRecruiter": null,
            //     "recruiterAssigned": false,
            //     "status": "TBA",
            //     "rthProcess": null,
            //     "rthModule": null,
            //     "createdAt": "2024-09-25",
            //     "updatedAt": null
            // }
        ]
    },
    {
        "label": "Closed",
        "component": {
            "key": null,
            "ref": null,
            "props": {
                "data": []
            },
            "_owner": {
                "tag": 0,
                "key": null,
                "stateNode": null,
                "return": null,
                "child": null,
                "sibling": null,
                "index": 0,
                "ref": null,
                "pendingProps": null,
                "memoizedProps": null,
                "updateQueue": null,
                "memoizedState": null,
                "dependencies": null,
                "mode": 27,
                "flags": 12617749,
                "subtreeFlags": 0,
                "deletions": null,
                "lanes": 0,
                "childLanes": 0,
                "alternate": null,
                "actualDuration": 34.80000019073486,
                "actualStartTime": 1419.7999999523163,
                "selfBaseDuration": 4.300000071525574,
                "treeBaseDuration": 4.299999952316284,
                "_debugSource": {
                    "fileName": "D:\\MYTHERON\\mytheron_frontend_react\\src\\components\\dashboard\\Rth\\resume\\RTHComponent.tsx",
                    "lineNumber": 16,
                    "columnNumber": 13
                },
                "_debugOwner": null,
                "_debugNeedsRemount": false,
                "_debugHookTypes": [
                    "useState",
                    "useState",
                    "useState",
                    "useState",
                    "useState",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useRef",
                    "useContext",
                    "useLayoutEffect",
                    "useCallback",
                    "useState",
                    "useContext",
                    "useRef",
                    "useCallback",
                    "useRef",
                    "useMemo",
                    "useSyncExternalStore",
                    "useEffect",
                    "useDebugValue",
                    "useDebugValue",
                    "useEffect",
                    "useEffect",
                    "useEffect"
                ]
            },
            "_store": {}
        },
        "data": []
    }
]

export const ResumeCard=
    [

        {
    
            "name": "John Doe",
    
            "arrivalType": "newly arrived",
    
            "phoneNumber": "+1234567890",
    
            "gmail": "john.doe@gmail.com",
    
            "file": "resume_john_doe.pdf",
    
            "scheduleType": "screening",
              
            "label":"Resume"
    
        },
    
        {
    
            "name": "Jane Smith",
    
            "arrivalType": "uploaded 2 days ago",
    
            "phoneNumber": "+0987654321",
    
            "gmail": "jane.smith@gmail.com",
    
            "file": "resume_jane_smith.pdf",
    
            "scheduleType": "l1 interview",
            "label":"Resume"
    
        },
    
        {
    
            "name": "Emily Johnson",
    
            "arrivalType": "newly arrived",
    
            "phoneNumber": "+1122334455",
    
            "gmail": "emily.johnson@gmail.com",
    
            "file": "resume_emily_johnson.pdf",
    
            "scheduleType": "screening",
            "label":"Resume"
    
        },
    
        {
    
            "name": "Michael Brown",
    
            "arrivalType": "uploaded 5 days ago",
    
            "phoneNumber": "+5566778899",
    
            "gmail": "michael.brown@gmail.com",
    
            "file": "resume_michael_brown.pdf",
    
            "scheduleType": "l1 interview",
            "label":"Resume"
    
        }
    
    ]
    export const candidatesData = [
        {
          id: 1,
          name: 'Logapriya K',
          status: 'Newly Arrived',
          daysAgo: '',
          phone: '6745845632',
          email: 'abidesigner@gmail.com',
          pdfLink: 'LP123.pdf',
          priorityId: {
            "id": "66d6ff54d6d855787104e4d0",
            "name": "None"
        },
        },
        {
          id: 2,
          name: 'Abishek T',
          status: 'Applied',
          daysAgo: '10 days ago',
          phone: '6745845632',
          email: 'abidesigner@gmail.com',
          pdfLink: 'doc123.pdf',
          priorityId: {
            "id": "66d6ff54d6d855787104e4d0",
            "name": "None"
        },
        },
        {
          id: 3,
          name: 'Ajay M',
          status: 'Newly Arrived',
          daysAgo: '',
          phone: '6745845632',
          email: 'abidesigner@gmail.com',
          pdfLink: 'doc123.pdf',
          priorityId: {
            "id": "66d6ff54d6d855787104e4d0",
            "name": "None"
        },
        },
        {
          id: 4,
          name: 'Riya L',
          status: 'Uploaded',
          daysAgo: '10 days ago',
          phone: '6745845632',
          email: 'abidesigner@gmail.com',
          pdfLink: 'doc123.pdf',
          priorityId: {
                "id": "66d6ff54d6d855787104e4d0",
                "name": "None"
            },
        },
        // Add more candidate data as needed
      ];
    
export const SelectDataOptions=[
    {
        "id": "66d6ff54d6d855787104e4cd",
        "name": "High"
    },
    {
        "id": "66d6ff54d6d855787104e4ce",
        "name": "Medium"
    },
    {
        "id": "66d6ff54d6d855787104e4cf",
        "name": "Low"
    },
    {
        "id": "66d6ff54d6d855787104e4d0",
        "name": "None"
    }
]
export const gender=[
    {
        label:'Male',
        value:'Male'
    },
    {
        label:'Female',
        value:'Female'
    }
]

export const knownOptions=[
    {
        label:'Social Media',
        value:'Social Media'
    },
    {
        label:'LinkedIn',
        value:'LinkedIn'
    },
    {
        label:'others',
        value:'others'
    }

]
export const Nationality=[
    {
        label:"Indian",
        value:"Indian",
    },
    {
        label:"American",
        value:"American",
    },
    {
        label:"others",
        value:"others",
    }
]
export const Citizenship=[
    {
        label:"Permanent Resident",
        value:"Permanent Resident",
    },
    {
        label:"others",
        value:"others",
    },
    
]
export const Country=[
    {
        label:"India",
        value:"India",
    },
    {
        label:'Germany',
        value:'Germany',
    }
]
export const State=[
    {
        label:'TamilNadu',
        value:'TamilNadu'
    },
    {
        label:'Maharashtra',
        value:'Maharashtra'
    },
    {
        label:'karnataka',
        value:'karnataka'
    },
]
export const Cities=[
    {
        label:'Chennai',
        value:'Chennai'
    },
    {
        label:'Coimbatore',
        value:'Coimbatore'
    },
    {
        label:'Madurai',
        value:'Madurai'
    },
]
export const Skills=[
    {
        label:'Java',
        value:'Java'
    },
    {
        label:'Python',
        value:'Python'
    },
    {
        label:'SQL',
        value:'SQL'
    },
]
export const YesNo=[
    {
        label:'Yes',
        value:'Yes',
    },
    {
        label:'No',
        value:'No',
    },
]
export const YearsInExp=[
    {
        label:'3-months',
        value:'3-months',
    },
    {
        label:'6-months',
        value:'6-months',
    },
    {
        label:'1 Year',
        value:'1 Year',
    },
]