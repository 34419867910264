import React from 'react';
import { Box, Button } from '@mui/material';

interface JobDetailsProps 
{
  typeOfRth?: string;
  modeOfRth?: string;
  modesOfRth?:string;
  rthProcess?: string;
  status?: string;
}

const JobDetails: React.FC<JobDetailsProps> = ({ typeOfRth, modeOfRth, rthProcess, status,modesOfRth }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: "2px 3px" }}>
      <Box sx={{ display: 'flex', gap: "5px" }}>
        <Button size="small" sx={{ mr: 1, borderRadius: '8px', backgroundColor: '#F0F0F0', border: "1px solid lightgray", fontSize: '7px', padding: '1px', margin: '1px', color: "#8C8C8C", height: '15px', minWidth: '50px' }}>
          {typeOfRth}
        </Button>
        <Button size="small" sx={{ mr: 1, borderRadius: '8px', backgroundColor: '#F0F0F0', border: "1px solid lightgray", fontSize: '7px', padding: '1px', margin: '1px', color: "#8C8C8C", height: '15px', minWidth: '50px' }}>
          {modeOfRth}
        </Button>
        {modesOfRth&&
        <Button size="small" sx={{ mr: 1, borderRadius: '8px', backgroundColor: '#F0F0F0', border: "1px solid #427BC5", fontSize: '7px', padding: '1px', margin: '1px', color: "#427BC5", height: '15px', minWidth: '50px' }}>
          {modesOfRth}
        </Button>
}
        {
          status === "In Progress" &&
          <Button size="small" sx={{ mr: 1, borderRadius: '8px', backgroundColor: '#F0F0F0', border: "1px solid lightgray", fontSize: '7px', padding: '1px', margin: '1px', color: "#8C8C8C", height: '15px', minWidth: '60px' }}>
            {rthProcess}
          </Button>
        }
      </Box>
    </Box>
  );
}

export default JobDetails;
