
import axios from 'axios';
import { RTH_API_URL } from '../../constants/Crudurl';


export const getRthData = async (id: string) => {
  const token = localStorage.getItem('token');


  try {
    const response = await axios.get(
      `https://mytheron-dev-backend.citpl.info/rth/viewRthByUserId/${id}}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error: any) {    

    return error.response?.data || { message: 'An unknown error occurred' };
  }
};


//https:mytheron-dev-backend.citpl.info/master/getBusinessList

export const getRthBusinessInputData = async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(
      `${RTH_API_URL}/getBusinessList`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    console.error('getRthData', error);

    return error.response?.data || { message: 'An unknown error occurred' };
  }
};





export const submitRthForm = async (data: any) => {
    const token = localStorage.getItem("token");

    try {
        const response = await axios.post('https://mytheron-dev-backend.citpl.info/rth/addRth', data,
            {
                headers: { Authorization: `Bearer ${token}` },
                
            },
          )

        // console.log('response.data', response.data)
        return response.data
    } catch (error) {
        console.error("submitRthForm", error)
    }

}


export const setL1Status = async({rthId,status}:{rthId:string | undefined;status:string})=>{
  const token = localStorage.getItem("token");
  try{
    const response=await axios.put(`https://mytheron-dev-backend.citpl.info/rth/setL1Status/${rthId}/${status}`,
      {
      headers:{Authorization:`Bearer ${token}`},
    })
    return response.data;
  }
  catch(error){
    console.log(error);
  }
}

export const setL2Status = async({rthId,status}:{rthId:string | undefined;status:string})=>{
  const token = localStorage.getItem("token");
  try{
    const response=await axios.put(`https://mytheron-dev-backend.citpl.info/rth/setL2Status/${rthId}/${status}`,
      {
      headers:{Authorization:`Bearer ${token}`},
    })
    return response.data;
  }
  catch(error){
    console.log(error);
  }
}
