import React from 'react';
import { Typography,  Grid, Box, Paper, SelectChangeEvent } from '@mui/material';
import CommonTextField from '../../../common/CommonTextField';
import CommonSelect from '../../../common/CommomSelect';
import {  Skills, YearsInExp, YesNo } from '../../../../constants/dummyConst';
import CommonMultiSelect from '../../../common/CommonMultiSelect';

const Experience = ({formValues,setFormValues}:any) => {
  
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };


  return (
    
    <Box sx={{ pt: '4px'}}>
        <Paper elevation={0} sx={{overflowY:'auto',pt:'10px',position:'relative',height:'62vh'}}>
      <Typography sx={{fontSize:'24px',fontWeight:'500', mb:'27px'}}>Experience</Typography>
      <Box sx={{width:'50vw'}}>
      <Grid container spacing={2}>

      <Grid item xs={12} md={4}>
        <Typography sx={{fontSize:'14px',color:'#E5E3E3' , mb:'17px'}}>Skills(You can choose upto 5 skills)</Typography>
        <CommonMultiSelect
                  labelId=""
                  label=""
                  name="skills"
                  value={formValues.skills||[]}
                  onChange={handleInputChange}
                  // error={!!errors.skills}
                  // helperText={errors.skills}
                  options={Skills}
                />
          </Grid>
          </Grid>
          <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
             <Typography sx={{fontSize:'14px',color:'#E5E3E3' , mb:'17px'}}>Do you have work Experience?</Typography>
             <CommonSelect
                  labelId="experience"
                  label=""
                  name="experience"
                  value={formValues.experience||''}
                  onChange={handleInputChange}
                  // error={!!errors.experience}
                  // helperText={errors.experience}
                  options={YesNo}
                />
             </Grid>
             <Grid item xs={12} md={4}>
             <Typography sx={{fontSize:'14px',color:'#E5E3E3' , mb:'17px'}}>Total Years in Experience</Typography>
             <CommonSelect
                  labelId="yearsInExperience"
                  label=""
                  name="yearsInExperience"
                  value={formValues.yearsInExperience||''}
                  onChange={handleInputChange}
                  // error={!!errors.yearsInExperience}
                  // helperText={errors.yearsInExperience}
                  options={YearsInExp}
                />
             </Grid>
             </Grid>
             <Typography sx={{mt:'10px',fontSize:'16px',fontWeight:'bold'}}>i)Latest Experience</Typography>
             <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
          
             <Typography sx={{fontSize:'14px',color:'#E5E3E3' , mb:'17px',mt:'22px'}}>Job Title</Typography>
                <CommonTextField
                  label="jobTitle"
                  name="jobTitle"
                  value={formValues.jobTitle||''}
                  onChange={handleInputChange}
                  // error={!!errors.jobTitle}
                  // helperText={errors.jobTitle}
                  
                />
             </Grid>
             <Grid item xs={12} md={4}>
             <Typography sx={{fontSize:'14px',color:'#E5E3E3' , mb:'17px',mt:'22px'}}>Company</Typography>
             <CommonTextField
                  label="company"
                  name="company"
                  value={formValues.company||''}
                  onChange={handleInputChange}
                  // error={!!errors.company}
                  // helperText={errors.company}
                />
             </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
          
             <Typography sx={{fontSize:'14px',color:'#E5E3E3' , mb:'17px',mt:'22px'}}>From</Typography>
             <CommonTextField
                  label="expFrom"
                  name="expFrom"
                  value={formValues.expFrom||''}
                  onChange={handleInputChange}
                  // error={!!errors.expFrom}
                  // helperText={errors.expFrom}
                />
             </Grid>
             <Grid item xs={12} md={4}>
             <Typography sx={{fontSize:'14px',color:'#E5E3E3' , mb:'17px',mt:'22px'}}>To</Typography>
             <CommonTextField
                  label="expTo"
                  name="expTo"
                  value={formValues.expTo||''}
                  onChange={handleInputChange}
                  // error={!!errors.expTo}
                  // helperText={errors.expTo}
                />
             </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
          
             <Typography sx={{fontSize:'14px',color:'#E5E3E3' , mb:'17px',mt:'22px'}}>From</Typography>
             <CommonTextField
                  label="expFrom"
                  name="expFrom"
                  value={formValues.expFrom||''}
                  onChange={handleInputChange}
                  // error={!!errors.expFrom}
                  // helperText={errors.expFrom}
                />
             </Grid>
             <Grid item xs={12} md={4}>
             <Typography sx={{fontSize:'14px',color:'#E5E3E3' , mb:'17px',mt:'22px'}}>To</Typography>
             <CommonTextField
                  label="expTo"
                  name="expTo"
                  value={formValues.expTo||''}
                  onChange={handleInputChange}
                  // error={!!errors.expTo}
                  // helperText={errors.expTo}
                />
             </Grid>
        </Grid>
      </Box>
      
      </Paper>
    </Box>
  );
};

export default Experience;
