import React, { useState } from 'react';
import { Button, Grid, Box, Paper, Typography, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import CommonTextField from '../../../common/CommonTextField';

const Education = ({ formValues, setFormValues }: any) => {
  // const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newEducationLabel, setNewEducationLabel] = useState(''); // Label for new education entry
  if (!formValues.education || formValues.education.length === 0) {
    setFormValues({
      ...formValues,
      education: [{
        university: '',
        degree: '',
        fieldOfStudy: '',
        from: '',
        to: '',
        label: 'Highest Education', // Label for the default entry
      }]
    });
  }

  // Open dialog for adding new education entry
  const handleAddEducation = () => {
    setIsDialogOpen(true);
  };

  // Handle closing the dialog
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setNewEducationLabel('');
  };

  // Handle the input change for the current form values (including education entries)
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index?: number) => {
    const { name, value } = event.target;
    if (typeof index === 'number') {
      const updatedEducation = [...formValues.education];
      updatedEducation[index][name] = value;
      setFormValues({
        ...formValues,
        education: updatedEducation,
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };
  
  // Add a new education entry with the label from the dialog
  const handleAddEducationConfirm = () => {
    const newEducation = {
      university: '',
      degree: '',
      fieldOfStudy: '',
      from: '',
      to: '',
      label: newEducationLabel, // The label set in the dialog
    };
    setFormValues({
      ...formValues,
      education: [...(formValues.education || []), newEducation],
    });
    handleCloseDialog();
  };

  

  return (
    <Box sx={{ pt: '4px' }}>
      <Paper elevation={0} sx={{ overflowY: 'scroll', pt: '10px', position: 'relative', height: '62vh' }}>
        <Typography sx={{ fontSize: '24px', fontWeight: '500', mb: '27px' }}>Education</Typography>

        {/* Loop through education entries in formValues */}
        {(formValues.education || []).map((entry: any, index: number) => (
          <Box key={index} sx={{ width: '50vw', mb: 2 }}>
            <Typography sx={{ mt: '10px', fontSize: '16px', fontWeight: 'bold' }}>
              {index === 0 ? 'Highest Education' : entry.label || `Education ${index + 1}`}
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography sx={{ fontSize: '14px', color: '#E5E3E3', mb: '17px', mt: '10px' }}>University</Typography>
                <CommonTextField
                  label="university"
                  name="university"
                  value={entry.university}
                  onChange={(event) => handleInputChange(event, index)}
                  // error={!!errors.university}
                  // helperText={errors.university}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography sx={{ fontSize: '14px', color: '#E5E3E3', mb: '17px', mt: '10px' }}>Degree</Typography>
                <CommonTextField
                  label="degree"
                  name="degree"
                  value={entry.degree}
                  onChange={(event) => handleInputChange(event, index)}
                  // error={!!errors.degree}
                  // helperText={errors.degree}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography sx={{ fontSize: '14px', color: '#E5E3E3', mb: '17px', mt: '10px' }}>Field of Study</Typography>
                <CommonTextField
                  label="fieldOfStudy"
                  name="fieldOfStudy"
                  value={entry.fieldOfStudy}
                  onChange={(event) => handleInputChange(event, index)}
                  // error={!!errors.fieldOfStudy}
                  // helperText={errors.fieldOfStudy}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography sx={{ fontSize: '14px', color: '#E5E3E3', mb: '17px' }}>From</Typography>
                <CommonTextField
                  label="from"
                  name="from"
                  value={entry.from}
                  onChange={(event) => handleInputChange(event, index)}
                  // error={!!errors.from}
                  // helperText={errors.from}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography sx={{ fontSize: '14px', color: '#E5E3E3', mb: '17px' }}>To</Typography>
                <CommonTextField
                  label="to"
                  name="to"
                  value={entry.to}
                  onChange={(event) => handleInputChange(event, index)}
                  // error={!!errors.to}
                  // helperText={errors.to}
                />
              </Grid>
            </Grid>
          </Box>
        ))}

        <Box sx={{ mt: '8px' }}>
          <Button onClick={handleAddEducation}>+ Add New Education</Button>
        </Box>
      </Paper>

      {/* Dialog for Adding Education */}
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Add Education</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Education Label"
            type="text"
            fullWidth
            value={newEducationLabel}
            onChange={(e) => setNewEducationLabel(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleAddEducationConfirm}>OK</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Education;
