import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, Tab, Tabs, Typography } from '@mui/material';
import SearchBar from '../../commoncomponent/SearchBar';
import ViewCard from '../../../dashboard/ViewCard';
import FilterSidebar from '../../commoncomponent/FilterSidebar';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../redux/store';
import { fetchJobs } from '../../../../redux/features/jobcardSlice';
import { jwtDecode } from "jwt-decode";
import { cardResume } from '../../../../constants/dummyConst';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} pt={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}



export const RTHTabs = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [viewType, setViewType] = useState<'grid' | 'list'>('grid');
  const token: any = localStorage.getItem("token");
  const dispatch = useDispatch<AppDispatch>();
  const [tabsData,setTabsData]=useState<any>([]);

  const decoded: any = jwtDecode(token);
  const userId = decoded.userId;


  const handleViewChange = (view: 'grid' | 'list') => {
    setViewType(view);
  };


  useEffect(() => {
    dispatch(fetchJobs(userId));
  }, [dispatch, userId]);

  useEffect(()=>{
    setTabsData(cardResume);
  },[])

  return (
    <Box sx={{ width: '95%', backgroundColor: '#f0f4ff', display: 'flex', position: 'relative' }} >
        
      <FilterSidebar onChangeFilter={setDrawerOpen} drawerOpen={drawerOpen} tabIndex={tabIndex}/>
      <Box sx={{ width: '100%', transition: 'margin-left 0.3s' }}>
      <Box>
        <Paper elevation={0} sx={{ backgroundColor: 'transparent' }}>
          <Tabs
            value={tabIndex}
            onChange={(e, newIndex) => setTabIndex(newIndex)}

            indicatorColor="primary"
            aria-label="job tabs"
            sx={{ ml: drawerOpen ? '0px' : '100px' }}
            TabIndicatorProps={{
              style: {
                width: '20px',
                marginLeft: `${tabIndex === 0 ? '45px' : '36px'}`,
              },
            }}            
          >
            {tabsData.map((tab:any, index:any) => (
              <Tab
                key={index}
                label={tab.label}
                {...a11yProps(index)}
                sx={{ fontSize: '13px', textTransform: 'none' }}
              />
            ))}
          </Tabs>
        </Paper>
        <SearchBar viewType={viewType} handleViewChange={handleViewChange} pageType="Resume"/>

        {tabsData.map((tab:any, index:any) => {
            console.log(tab.data,'5678');
            return(
          <CustomTabPanel value={tabIndex} index={index} key={index}>
            {viewType === 'grid' ? (
              <Grid container spacing={2} xs={16} sx={{ display: 'flex', flexWrap: 'wrap', gap: '0px' }}>
                <ViewCard data={tab.data} label={tab.label}/>
              </Grid>
            ) : (
              tab.component
            )}
          </CustomTabPanel>
            )
})}
      </Box>
      </Box>
    </Box>
  );
};
