import axios from "axios";
import { FILE_API_URL } from "../constants/Crudurl";

 
export const uploadFile = async (formData: FormData) => {
  const token = localStorage.getItem('token');

  return axios.post(
    `${FILE_API_URL}/addAttachment`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
};