import React from 'react';
import { Paper, Tabs, Tab } from '@mui/material';

interface TabsProps {
  tabsData: { label: string }[];
  drawerOpen: boolean;
  tabIndex: number;
  setTabIndex: (index: number) => void;
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

export default function CustomTabs({ tabsData, drawerOpen, tabIndex, setTabIndex }: TabsProps) {
  return (
    <Paper elevation={0} sx={{ backgroundColor: 'transparent' }}>
      <Tabs
        value={tabIndex}
        onChange={(e, newIndex) => setTabIndex(newIndex)}
        indicatorColor="primary"
        aria-label="job tabs"
        sx={{ ml: drawerOpen ? '0px' : '100px' }} // Dynamic margin-left based on drawer status
        TabIndicatorProps={{
          style: {
            width: '20px',
            marginLeft: `${tabIndex === 0 ? '45px' : '36px'}`, // Adjusting indicator based on selected tab
          },
        }}
      >
        {tabsData.map((tab: any, index: any) => (
          <Tab
            key={index}
            label={tab.label}
            {...a11yProps(index)}
            sx={{ fontSize: '13px', textTransform: 'none' }} // Custom font size and text transformation
          />
        ))}
      </Tabs>
    </Paper>
  );
}
