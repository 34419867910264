import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Grid, Alert } from "@mui/material";
import { useDispatch } from 'react-redux';
import { getRthBusinessInputData, submitRthForm } from '../../../../redux/features/rthSlice';
import CommonSelect from "../../../common/CommomSelect";
import { SelectChangeEvent } from "@mui/material";
import CommonTextField from "../../../common/CommonTextField";
import type { AppDispatch } from '../../../../redux/store';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { getRthBandData, getRthDepartmentData, getRthLocationData, getRthPositionReportsTo, getRthPrimarySkillsData, getRthQualificationData, getRthSecondarySkillsData, getRthWorkModeData } from "../../../../Crud/rth";
import { uploadFile } from "../../../../Crud/choosefile";
import { jwtDecode } from "jwt-decode";
import { toast } from 'react-toastify';

interface SkillOption {
  value: string;
  label: string;
}



export const useAppDispatch: () => AppDispatch = useDispatch;

interface FormData {
  userId: string;
  businessId: string;
  positionTitle: string;
  numOfPosition: string;
  internalSpoc: string;
  location: string;
  departmentId: string;
  band: string;
  qualNeeded: string;
  minExperience: string;
  maxExperience: string;
  primarySkills?: any;  
  secondarySkills?: any;  
  prefStartDate: string;
  workMode: string;
  priority: string;
  comments: string;
  clientName: string;
  clientpoc: string;
  clientRateCard: string;
  OneTimeAmount: string;
  OneTimeCharge: string;
  approvalAttachment?: string;
  typeOfRth:string,
  modeOfRth:string,
}

const arr: any = [];
const secondarySkills: string[] = [];
const ExternalDetails: React.FC = () => {

  const [chooseFile, setChooseFile] = useState()
  const [posReportsTo, setposReportsTo] = useState([])
  const [LocationData, setLocationData] = useState([])
  const [ QualificationData, setQualificationData] = useState([])
  // const [PriorityData,setPriorityData] = useState([])
  // const [L2ApproverData,setL2ApproverData] = useState([])
  // const [L1ApproverData,setL1ApproverData] = useState([])
  const [secondarySkillsData,setSecondarySkillsData] = useState([])
  const [primarySkillsData,setPrimarySkillsData] = useState([])
  const [WorkModeData,setWorkModeData] = useState([])
  const [departmentData,setDepartmentData] = useState([])
  const [bandData,setBandData] = useState([])
  const [businessData, setBusinessData] = useState([])
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [submitted, setSubmitted] = useState(false);
  const [pSkills,setPSkills] = useState<any>([]);
  const [sSkills,setSSkills] = useState<any>([]);


  const token: any = localStorage.getItem("token")
  const decoded: any = jwtDecode(token);
  const userId = decoded.userId;

  const [formData, setFormData] = useState<FormData>({
    userId: userId || "",
    businessId: "",
    positionTitle: "",
    numOfPosition: "",
    internalSpoc: "",
    location: "",
    departmentId: "",
    band: "",
    qualNeeded: "",
    minExperience: "",
    maxExperience: "",
    primarySkills: "",  
    secondarySkills: "",
    prefStartDate:"",
    workMode:"",
    priority: "",
    comments: "",
    clientName: "",
    clientpoc: "",
    clientRateCard: "",
    OneTimeAmount: "",
    OneTimeCharge: "",
    approvalAttachment: "",
    typeOfRth:"External",
    modeOfRth:"OneTime",
  });
  
  useEffect(() => {
    getRthBusinessInputData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.businessId, label: i.businessName } })
      setBusinessData(data)
     // console.log('data', data)
    }).catch((err) => console.error(err));
    
    getRthBandData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.bandId, label: i.bandName } })
      setBandData(data)
     // console.log('data', data)
    }).catch((err) => console.error(err));

    getRthDepartmentData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.departmentId, label: i.departmentName } })
      setDepartmentData(data)
      //console.log('data', data)
    }).catch((err) => console.error(err));

    getRthWorkModeData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.workId, label: i.workName } })
      setWorkModeData(data)
     // console.log('data', data)
    }).catch((err) => console.error(err));

    getRthPrimarySkillsData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.id, label: i.skillName } })
      setPrimarySkillsData(data)
      // console.log('data', data)
    }).catch((err) => console.error(err));

    getRthSecondarySkillsData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.id, label: i.skillName } })
      setSecondarySkillsData(data)
      // console.log('data', data)
    }).catch((err) => console.error(err));

    // getRthL1ApproverData().then(res => {
    //   const data = res.data?.map((i: any) => { return { value: i.id, label: i.firstName } })
    //   setL1ApproverData(data)
    // }).catch((err) => console.error(err));

    // getRthL2ApproverData().then(res => {
    //   const data = res.data?.map((i: any) => { return { value: i.id, label: i.firstName } })
    //   setL2ApproverData(data)
    // }).catch((err) => console.error(err));

    // getRthPriorityData().then(res => {
    //   const data = res.data?.map((i: any) => { return { value: i.priorityId, label: i.priorityName } })
    //   setPriorityData(data)
    // }).catch((err) => console.error(err));

    getRthQualificationData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.qualificationId, label: i.degree } })
      setQualificationData(data)
      //console.log('data', data)
    }).catch((err) => console.error(err));

    getRthLocationData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.locationId, label: i.locationName } })
      setLocationData(data)
      //console.log('data', data)
    }).catch((err) => console.error(err));

    getRthPositionReportsTo().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.id, label: i.firstName } })
      setposReportsTo(data)
      //console.log('data', data)
    }).catch((err) => console.error(err));
  }, []);
  

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>
  ) => {
    const { name, value } = event.target;
    console.log("change", name, value);
    if (name === "secondarySkills") {
      secondarySkills.push(value);
     
    }
    if (name === "primarySkills") {
      arr.push(value);
      console.log("arr", arr)
      setFormData({
        ...formData,
        primarySkills: [arr],
      });
      console.log("....formData", formData);
    }
    
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  
  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      const formData = new FormData();
      formData.append("image", file);

      // setProfilePic("")
      reader.onload = (e) => {
        // setProfilePic(e.target?.result as string);
        // const imageData = e.target?.result as string;
        uploadFile(formData).then((res) => setChooseFile(res.data.data)).catch(err => console.error(err));
      };
      reader.readAsDataURL(file);
      // console.log("profilePic33", profilePic);
    }
  };
  const validate = () => {
    const newErrors: { [key: string]: string } = {};
    Object.keys(formData).forEach((key) => {
      if (key === 'approvalAttachment' && formData.approvalAttachment === '') {
        return;
      }
      if (!formData[key as keyof typeof formData]) {
        newErrors[key] = key+" field is required";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setSubmitted(true);
    console.log("arr", arr) 

    let data = {
      ...formData
    }
    if (pSkills.length>=1) {
      data = {
        ...data,
        primarySkills: pSkills
      }
    }
    if (setSSkills.length>=1) {
      data = {
        ...data,
        secondarySkills: sSkills
      }
    }
    if (chooseFile) {
      data = {
        ...data,
        approvalAttachment: chooseFile
      }
    }
    console.log("Contract", data)

    if (validate()) {
      await submitRthForm(data);
      // Form is valid, handle form submission
      toast.success("Form Submitted Sucessfully")
      setFormData({
        userId: userId || "",
        businessId: "",
      positionTitle: "",
      numOfPosition: "",
      internalSpoc: "",
      location: "",
      departmentId: "",
      band: "",
      qualNeeded: "",
      minExperience: "",
      maxExperience: "",
      primarySkills: "",  
      secondarySkills: "",
      prefStartDate:"",
      workMode:"",
      priority: "",
      comments: "",
      clientName: "",
      clientpoc: "",
      clientRateCard: "",
      OneTimeAmount: "",
      OneTimeCharge: "",
      approvalAttachment: "",
      typeOfRth:"External",
      modeOfRth:"OneTime",
    });
      setErrors({});
      setSubmitted(false);
    }
  };

  return (
    <>
     <Box component="form" onSubmit={handleSubmit}>
      <Box
        sx={{
          p: 3,
          backgroundColor: "white",
          marginTop: "10px",
          minWidth: "103%",
        }}
      >
        {/* Main content goes here */}
        <Box component="form" sx={{ mt: 3 }}>
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              md={2}
              sx={{ paddingTop: "33px", paddingLeft: "30px" }}
            >
              <CommonSelect
                labelId="business-label"
                label="Business"
                name="businessId"
                value={formData.businessId}
                onChange={handleChange}
                options={businessData}
                error={!!errors.businessId}
                helperText={errors.businessId}
              />
            </Grid>
            <Grid item xs={12} md={2}>
            <CommonTextField
                    label="Position Title"
                  name="positionTitle"
                  value={formData.positionTitle.replace(/[^a-zA-Z\s]/g, '')}
                  onChange={handleChange}
                  error={!!errors.positionTitle}
                  helperText={errors.positionTitle}
                />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonTextField
                label="Number of Positions"
                name="numOfPosition"
                value={formData.numOfPosition.replace(/[^0-9]/g, '')}
                onChange={handleChange}
                error={!!errors.numOfPosition}
                helperText={errors.numOfPosition}
              />
            </Grid>
            <Grid item xs={12} md={2}>
            <CommonSelect
                  labelId="Internal SPOC"
                  label="Internal SPOC"
                  name="internalSpoc"
                  value={formData.internalSpoc}
                  onChange={handleChange}
                  error={!!errors.internalSpoc}
                  helperText={errors.internalSpoc}
                  options={posReportsTo}
                />
            </Grid>
            <Grid item xs={12} md={2}>
            <CommonSelect
                  labelId="location"
                  label="Location"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  error={!!errors.location}
                  helperText={errors.location}
                  options={LocationData}
                />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonSelect
                 labelId="Department"
                 label="Department"
                 name="departmentId"
                 value={formData.departmentId}
                 onChange={handleChange}
                 options={departmentData}
                 error={!!errors.departmentId}
                 helperText={errors.departmentId}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonSelect
               labelId="Band"
               label="Band"
               name="band"
               value={formData.band}
               onChange={handleChange}
               options={bandData}
               error={!!errors.band}
               helperText={errors.band}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonSelect
                labelId="Qualification "
                label="Qualification "
                name="qualNeeded"
                value={formData.qualNeeded}
                onChange={handleChange}
                options={QualificationData}
                error={!!errors.qualNeeded}
                helperText={errors.qualNeeded}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonSelect
                labelId="Min Experience"
                label="Min Experience"
                name="minExperience"
                value={formData.minExperience}
                onChange={handleChange}
                options={[
                  { value: "1 year", label: "1 year" },
                  { value: "2 years", label: "2 years" },
                ]}
                error={!!errors.minExperience}
                helperText={errors.minExperience}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonSelect
               labelId="Max Experience"
               label="Max Experience"
               name="maxExperience"
               value={formData.maxExperience}
               onChange={handleChange}
               options={[
                  { value: "5 years", label: "5 years" },
                  { value: "10 years", label: "10 years" },
                ]}
                error={!!errors.maxExperience}
                helperText={errors.maxExperience}
              />
            </Grid>
            <Grid item xs={12} md={2}>
            <Autocomplete
                  multiple
                  limitTags={1}
                  options={primarySkillsData}
                  getOptionLabel={(option: SkillOption) => option.label}
                  onChange={(event, newValue) => {
                    const pData = newValue.map(i=>i.value)
                    setPSkills(pData)
                    const sSkills = secondarySkillsData.filter((i:any)=>!pData.includes(i.value))
                    
                    if(newValue.length>=1){
                      setSecondarySkillsData(sSkills)
                    }else{
                      setSecondarySkillsData(primarySkillsData)
                    }
                    setFormData((prevData) => ({
                      ...prevData,
                      primarySkills: pData,
                    }));
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      primarySkills: "",
                    }));
                  }}

                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Primary Skills"
                      error={!!errors.primarySkills}
                      helperText={errors.primarySkills}
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          fontSize: '0.875rem', 
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: '0.875rem', 
                        },
                      }}


                    />
                  )}
                  disableCloseOnSelect
                  renderOption={(props, option: SkillOption, { selected }) => (
                    <li {...props}
                    style={{
                      fontSize: '0.875rem', 
                      padding: '1px 1px', 
                      margin: '2px 0', 
                    }}
                    >
                      <Checkbox
                        sx={{
                          transform: 'scale(0.8)', 
                          marginRight: 2,
                          padding: 0,
                        }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  )}
                />
            </Grid>
            <Grid item xs={12} md={2}>
            <Autocomplete
                  multiple
                  limitTags={1}
                  options={secondarySkillsData}
                  getOptionLabel={(option: SkillOption) => option.label}
                  onChange={(event, newValue) => {
                    const sData = newValue.map(i=>i.value)
                    setSSkills(sData);
                    
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      secondarySkills: "",
                    }));
                    setFormData((prevData) => ({
                      ...prevData,
                      secondarySkills: sData
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Secondary Skills"
                      error={!!errors.secondarySkills}
                      helperText={errors.secondarySkills}
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          fontSize: '0.875rem', 
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: '0.875rem', 
                        },
                      }}
                    />
                  )}
                  disableCloseOnSelect
                  renderOption={(props, option: SkillOption, { selected }) => (
                    <li {...props}
                    style={{
                      fontSize: '0.875rem', 
                      padding: '1px 1px', 
                      margin: '2px 0', 
                    }}
                    >
                      <Checkbox
                        sx={{
                          transform: 'scale(0.8)', 
                          marginRight: 2,
                          padding: 0,
                        }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  )}
                />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonTextField
                 label="Preferred Start Date"
                 type="date"
                 name="prefStartDate"
                 value={formData.prefStartDate}
                 onChange={handleChange}
                 error={!!errors.prefStartDate}
                 helperText={errors.prefStartDate}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonSelect
                 labelId="work-mode-label"
                 label="Work Mode"
                 name="workMode"
                 value={formData.workMode}
                 onChange={handleChange}
                 options={WorkModeData}
                 error={!!errors.workMode}
                 helperText={errors.workMode}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonSelect
                 labelId="priority-label"
                 label="Priority"
                 name="priority"
                 value={formData.priority}
                 onChange={handleChange}
                 options={[
                   { value: "High", label: "High" },
                   { value: "Medium", label: "Medium" },
                   { value: "Low", label: "Low" },
                 ]}
                 error={!!errors.priority}
                 helperText={errors.priority}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CommonTextField
                label="Comments"
                name="comments"
                value={formData.comments}
                onChange={handleChange}
                error={!!errors.comments}
                helperText={errors.comments}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          p: 3,
          backgroundColor: "white",
          marginTop: "10px",
          minWidth: "103%",
        }}
      >
        <Typography color="gray">ExternalDetails</Typography>
        {/* Main content goes here */}
        <Box component="form" sx={{ mt: 1 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={2}>
              <CommonTextField
              label="Client Name"
              name="clientName"
              value={formData.clientName.replace(/[^a-zA-Z\s]/g, '')}
              onChange={handleChange}
              error={!!errors.clientName}
              helperText={errors.clientName}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonSelect
                 labelId="Client POC"
                 label="Client POC"
                 name="clientpoc"
                 value={formData.clientpoc}
                 onChange={handleChange}
                 options={[
                   { value: "3 month", label: "3 month" },
                   { value: "6 month", label: "6 month" },
                 ]}
                 error={!!errors.clientpoc}
                 helperText={errors.clientpoc}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <input
                accept=".pdf,.doc,.docx"
                id="file-input"
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <label htmlFor="file-input">
                <Button
                  variant="outlined"
                  component="span"
                  sx={{
                    minWidth: "168px",
                    fontSize: "0.75rem",
                    padding: "2px 4px",
                    minHeight: "40px",
                    border: "1px solid",
                    borderColor: "darkgray",
                    borderRadius: "4px",
                    marginTop: "1px",
                    backgroundColor: "transparent",
                  }}
                  // sx={{ textTransform: "none" }}
                >
                  {!formData.approvalAttachment ? "File Upload" : formData.approvalAttachment}
                </Button>
              </label>
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonSelect
                labelId="Client Rate Card"
                label="Client Rate Card"
                name="clientRateCard"
                value={formData.clientRateCard}
                onChange={handleChange}
                options={[
                  { value: "3 month", label: "3 month" },
                  { value: "6 month", label: "6 month" },
                ]}
                error={!!errors.clientRateCard}
                helperText={errors.clientRateCard}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonTextField
                 label="One Time Charge%"
                 name="OneTimeCharge"
                 value={`${formData.OneTimeCharge.replace(/[^0-9]/g, '')}%`}
                 onChange={(event) => {
                  const sanitizedValue = event.target.value.replace(/[^0-9]/g, '');
                  setFormData((prevData) => ({
                    ...prevData,
                    OneTimeCharge:sanitizedValue,
                  }));
                  if (errors.clientMarginInPer) {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      OneTimeCharge: '',
                    }));
                  }
                }}
                 error={!!errors.OneTimeCharge}
                 helperText={errors.OneTimeCharge}
                
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <CommonTextField
                label="One Time Amount"
                name="OneTimeAmount"
                value={formData.OneTimeAmount.replace(/[^0-9]/g, '')}
                onChange={handleChange}
                error={!!errors.OneTimeAmount}
                helperText={errors.OneTimeAmount}
                
              />
            </Grid>
            
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          mt: 3,
          display: "flex",
          justifyContent: "flex-end",
          width: "106%",
        }}
      >
        <Button
          variant="contained"
          sx={{
            mr: 2,
            color: "black",
            backgroundColor: "transparent",
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "transparent", // Ensure hover doesn't add background color
              boxShadow: "none",
            },
          }}
          onClick={() => {
            setFormData({
              userId: userId || "",
              businessId: "",
              positionTitle: "",
              numOfPosition: "",
              internalSpoc: "",
              location: "",
              departmentId: "",
              band: "",
              qualNeeded: "",
              minExperience: "",
              maxExperience: "",
              primarySkills: [],  
              secondarySkills: [],
              prefStartDate:"",
              workMode:"",
              priority: "",
              comments: "",
              clientName: "",
              clientpoc: "",
              clientRateCard: "",
              OneTimeAmount: "",
              OneTimeCharge: "",
              approvalAttachment: "",
              typeOfRth:"External",
              modeOfRth:"OneTime",
            });
            setErrors({});
            setSubmitted(false);
          }}
        >
          Cancel
        </Button>
        <Button variant="contained" sx={{ color: "#F0F5FF" }} type="submit">
          Submit
        </Button>
      </Box>
      {submitted && Object.keys(errors).length > 0 && (
        <Alert severity="error" sx={{ mt: 2 }}>
          Please correct the errors in the form before submitting.
        </Alert>
      )}
      </Box>
    </>
  );
};

export default ExternalDetails;
