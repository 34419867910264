import React, { useRef, useState } from 'react'
import CustomTabs from '../../../common/tabs';
import { Box,  Paper, Typography } from '@mui/material';
import FilterSidebar from '../../commoncomponent/FilterSidebar';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useParams } from 'react-router-dom';
import SearchBar from '../../commoncomponent/SearchBar';
import JobDetails from '../JobDetails';
import { CandidateDetails } from '../rthtable/CandidateDetails';
import FileUploadDialog from './BulkUploadDialog';

export default function ResumeCardTab() {
    const [tabIndex, setTabIndex] = React.useState(1);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [viewType, setViewType] = useState<'grid' | 'list'>('grid');
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<any[]>([]); // Initialize as an empty array

    const {jobTitle,id}=useParams();
    const navigate=useNavigate();

const tabsData = [
  { label: 'Job Details' },
  { label: 'Candidate' },
  { label: 'Interview Details' },
];
const fileInputRef = useRef<HTMLInputElement | null>(null);

const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const file = event.target.files;
  if (file) {
    const newFiles = Array.from(file);
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setModalOpen(true); 
  }
};

const handleViewChange = (view: 'grid' | 'list') => {
  setViewType(view);
};

const handleBulkUploadClick = () => {
  if (fileInputRef.current) {
    fileInputRef.current.click(); 
  }
};

const handleButtonClick = (event: React.ChangeEvent<HTMLInputElement>) => {
  const file = event.target.files?.[0]; // Get the first file
  console.log('clicked1213');
  
  if (file) {
    navigate(`/newRequestToHire/${jobTitle}/${id}/Forms`); // Navigate to the desired route
  }
};

const handleCloseModal = () => {
  setModalOpen(false);
};


 const renderTabContent = () => {
    switch (tabIndex) {
      case 0:
        return <JobDetails />;
      case 1:
        return <CandidateDetails viewType={viewType} handleViewChange={handleViewChange} />;
      case 2:
        return <JobDetails />;
      default:
        return null;
    }
  };
  return (
    <Box sx={{ width: '95%', backgroundColor: '#f0f4ff', display: 'flex', position: 'relative' }} >        
    <FilterSidebar onChangeFilter={setDrawerOpen} drawerOpen={drawerOpen} tabIndex={tabIndex}/>
    <Box sx={{ width: '100%', transition: 'margin-left 0.3s' }}>
    <Box sx={{ml:'100px',mt:'8px',display:'flex',flexDirection:'row',gap:'10px'}}>
      <KeyboardBackspaceIcon sx={{color:'#948F8F',fontSize:'24px',mt:'4px'}}/>
      <Typography variant="h6" sx={{fontWeight:'bold'}}>{jobTitle}</Typography>
      </Box>
    <Paper elevation={0} sx={{ backgroundColor: 'transparent' }}>
    
    <CustomTabs
  tabsData={tabsData}
  drawerOpen={drawerOpen} 
  tabIndex={tabIndex}
  setTabIndex={setTabIndex}
/>
</Paper>
<SearchBar viewType={viewType} handleViewChange={handleViewChange}handleBulkUploadClick={handleBulkUploadClick} handleButtonClick={handleButtonClick} />
{renderTabContent()}
<input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange} 
          accept=".pdf,.doc,.docx"
        />
        
        <FileUploadDialog
          open={modalOpen}
          onClose={handleCloseModal}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
        />
</Box>
</Box>
  )
}
