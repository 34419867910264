import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import signupimg from '../assets/images/Login-page-layout.png';
import { theme } from './login';

const SignupImageBox: React.FC = () => {
  const isXLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));
  return (
    <Box
      flex={0.5}
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding={'25PX 16PX'}
      sx={{ position: 'relative' }}
    >
      <img src={signupimg} alt="Sign Up" style={{ width: isXLargeScreen? '800px': '510px', height: '100%' }} />
    </Box>
  );
};

export default SignupImageBox;
