import React  from 'react';
import { Box, Button, InputAdornment, InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';

import { IconButton } from '@mui/material';
import { useLocation } from 'react-router-dom';
// import { jwtDecode } from 'jwt-decode';

// interface SearchBarProps {
//   viewType: 'grid' | 'list';
//   handleViewChange: (view: 'grid' | 'list') => void;
//   handleButtonClick: () => void;
// }

export const SearchBar = ({
  viewType,
  handleViewChange,
  handleButtonClick,
  handleBulkUploadClick,
  pageType
}:any) => {
  // const navigate = useNavigate();

  // const handleButtonClick = () => {
  //   navigate('/newrth'); 
  // };
  const location=useLocation();

  const buttonContent = location.pathname.includes("newRequestToHire") ? "Import" : "New Request to hire (RTH)";

  return (
    <Box display="flex" flexDirection={'row'} alignItems="center" padding={"20px"} justifyContent="space-between" width={"100%"}>
      <InputBase
        placeholder="Search RTH..."
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon sx={{ fontSize: 18, marginLeft:"10px" }} /> 
          </InputAdornment>
        }
        sx={{ 
          border: '1px solid #ccc', 
          borderRadius: 1, 
          padding: 0, 
          backgroundColor: 'white', 
          minHeight: '3px', 
          fontSize: 14,  
          '.MuiInputBase-input': {
            padding: '5px 5px', 
          }
        }}
      />
       <Box display="flex" alignItems="center" >
      
      <IconButton onClick={() => handleViewChange('grid')}>
          <GridViewRoundedIcon sx={{ color: viewType === 'grid' ? '#1677FF' : 'inherit' }} />
        </IconButton>
        <IconButton onClick={() => handleViewChange('list')}>
          <FormatListBulletedIcon sx={{ color: viewType === 'list' ? '#1677FF' : 'inherit' }} />
        </IconButton>
        {/* <GridViewRoundedIcon sx={{ ml: 2 ,color:'#1677FF' }} /> */}
      {/* <Button >
      <FormatListBulletedIcon sx={{ ml: 1, mr: 2 , backgroundColor:'#000000 50%', backgroundSize:'10px'}}  />
      </Button> */}
       {location.pathname.includes("newRequestToHire") && pageType!=='Resume' &&(
          <Button
            variant="outlined"
            color="primary"
            sx={{ fontSize: '12px', textTransform: "none", mr: 2 }}
            onClick={handleBulkUploadClick}
          >
            Bulk Upload
          </Button>
        )}
        {pageType!=='Resume'&&
      <Button variant="contained" color="primary" 
      sx={{ fontSize: '12px', textTransform: "none" }} onClick={handleButtonClick} > 
        {/* sx={{ mr: 5}} */}
        {buttonContent}
      </Button>
}
    </Box>
    </Box>
  );
};

export default SearchBar;
