import { Box } from '@mui/material';
import { RTHTabs } from './RTHTabs';

export const RTHComponent=()=>{
    return(
        <Box>
        <Box sx={{ flexDirection: 'row', flex: 1, display: "flex" }}>
            <RTHTabs/>
        </Box>
    </Box>  
    )
}