import React from 'react';
import { Box, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

interface JobTimeProps {
  posted?: string;
}

const JobTime: React.FC<JobTimeProps> = ({ posted }) => {
  return (
    <Box display="flex" alignItems="center">
      <AccessTimeIcon sx={{ fontSize: '16px', marginLeft: '1px', color: '#8C8C8C' }} />
      <Typography color="textSecondary" sx={{ paddingLeft: '4px', fontSize: '8px', color: "#8C8C8C" }}>posted 10 days ago</Typography>
    </Box>
  );
}

export default JobTime;
