import React from 'react';
import { Box } from '@mui/material';
import JobCard from '../common/JobCard';
import { Job } from '../../redux/features/jobcardSlice';

export interface ViewCardProps {
  data: Job[]; 
  label?: string; 
}

const ViewCard: React.FC<ViewCardProps> = ({ data = [], label }) => (
  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0px' }}>
    {data.length > 0 ? (
      data.map((job, index) => (
        <JobCard
          key={index}
          label={label}
          job={job}
          userName="Nithya Shree"
          userTitle="Senior Recruiter"
          userAvatar="https://mui.com/static/images/avatar/3.jpg"
        />
      ))
    ) : (
      <p>No data available</p>  // You can customize this as needed
    )}
  </Box>
);

export default ViewCard;
