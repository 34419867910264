import axios from 'axios';
import { RTH_API_URL } from '../constants/Crudurl';



//GET Business 
export const getRthBusinessInputData = async () => {
    const token = localStorage.getItem('token');
    // console.log('token', token);
  
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getBusinessList`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log('response.data', response.data);
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
  
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };

  // GET Band
  export const getRthBandData = async () => {
    const token = localStorage.getItem('token');
   // console.log('token', token);
  
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getBandList`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log('response.data', response.data);
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
  
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };

  // GET Department
  export const getRthDepartmentData = async () => {
    const token = localStorage.getItem('token');
   // console.log('token', token);
  
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getDepartmentList`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log('response.data', response.data);
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
  
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };

  // GET WorkMode
  export const getRthWorkModeData = async () => {
    const token = localStorage.getItem('token');
    //console.log('token', token);
  
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getWorkModeList`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log('response.data', response.data);
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
  
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };

  // GET Primary Skill

  export const getRthPrimarySkillsData = async () => {
    const token = localStorage.getItem('token');
   // console.log('token', token);
  
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getSkillsList`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log('response.data', response.data);
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
  
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };

   // GET Secondary Skill

   export const getRthSecondarySkillsData = async () => {
    const token = localStorage.getItem('token');
   // console.log('token', token);
  
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getSkillsList`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
     // console.log('response.data', response.data);
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };

   // GET  l1 approver

   export const getRthL1ApproverData = async () => {
    const token = localStorage.getItem('token');
    //console.log('token', token);
  
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getLOneApprovers`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    //  console.log('response.data', response.data);
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
  
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };

   // GET  l2 approver

   export const getRthL2ApproverData = async () => {
    const token = localStorage.getItem('token');
    // console.log('token', token);
  
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getLTwoApprovers`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log('response.data', response.data);
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
  
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };

  // GET  Priority

  export const getRthPriorityData = async () => {
    const token = localStorage.getItem('token');
    //console.log('token', token);
  
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getPriorityList`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log('response.data', response.data);
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
  
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };

  // GET  Qualification

  export const getRthQualificationData = async () => {
    const token = localStorage.getItem('token');
   // console.log('token', token);
  
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getQualificationList`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log('response.data', response.data);
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
  
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };

    // GET Position Reports to

    export const getRthPositionReportsTo = async () => {
      const token = localStorage.getItem('token');
     // console.log('token', token);
    
      try {
        const response = await axios.get(
          `${RTH_API_URL}/getPositionReportsTo`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log('response.data', response.data);
        return response.data;
      } catch (error: any) {
        console.error('getRthData', error);
    
        return error.response?.data || { message: 'An unknown error occurred' };
      }
    };

    // GET Location

    export const getRthLocationData = async () => {
      const token = localStorage.getItem('token');
     // console.log('token', token);
    
      try {
        const response = await axios.get(
          `${RTH_API_URL}/getLocationList`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log('response.data', response.data);
        return response.data;
      } catch (error: any) {
        console.error('getRthData', error);
    
        return error.response?.data || { message: 'An unknown error occurred' };
      }
    };

    // GET Reason of Hire

    export const getRthReasonForHire = async () => {
      const token = localStorage.getItem('token');
     // console.log('token', token);
    
      try {
        const response = await axios.get(
          `${RTH_API_URL}/getReasonOfHireList`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log('response.data', response.data);
        return response.data;
      } catch (error: any) {
        console.error('getRthData', error);
    
        return error.response?.data || { message: 'An unknown error occurred' };
      }
    };

   // GET Role

   export const getRoleList = async () => {
    const token = localStorage.getItem('token');
   // console.log('token', token);
  
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getRoleList`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log('response.data', response.data);
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };

   // GET Designation

   export const getDesignationList = async () => {
    const token = localStorage.getItem('token');
   // console.log('token', token);
  
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getDesignationList`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log('response.data', response.data);
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };


  // GET Designation

  export const getOrganization = async () => {
    const token = localStorage.getItem('token');
   // console.log('token', token);
  
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getOrganization`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log('response.data', response.data);
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };

// GET getSupervisors

  export const getSupervisors = async () => {
    const token = localStorage.getItem('token');
   // console.log('token', token);
  
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getSupervisors`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log('response.data', response.data);
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };


  // GET getRecruiterLead

  export const getRecruiterLead = async () => {
    const token = localStorage.getItem('token');
   // console.log('token', token);
  
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getRecruiterLead`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log('response.data', response.data);
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };



  // GET getRecruiter

  export const getRecruiter = async () => {
    const token = localStorage.getItem('token');
   // console.log('token', token);
  
    try {
      const response = await axios.get(
        `${RTH_API_URL}/getRecruiter`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log('response.data', response.data);
      return response.data;
    } catch (error: any) {
      console.error('getRthData', error);
      return error.response?.data || { message: 'An unknown error occurred' };
    }
  };




  

  







  