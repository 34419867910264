import { Card, Typography, Avatar, Button, Box } from '@mui/material';
import pdfImage from '../../assets/images/pdf 1.svg';
import docImage from '../../assets/images/doc 1.svg';
import phonecall from '../../assets/images/phone-call.svg'
import emailopen from '../../assets/images/e-mail.svg'


const getFileIcon = (fileName: string) => {
  const extension = fileName.split('.').pop()?.toLowerCase();
  if (extension === 'pdf') {
    return <img src={pdfImage} alt="PDF Icon" style={{ width: '16px', height: '16px' }} />;
  }
  if(extension==='doc'||extension==='docx'){
    return <img src ={docImage} alt="DOC Icon" style={{width:'16px',height:'16px'}}/>;
  }
  return <img src={pdfImage} alt="PDF Icon" style={{ width: '16px', height: '16px' }} />;
};

const getFileNameAndType = (url: string) => {
  const parts = url.split('/');
  const fileName = parts[parts.length - 1].split('?')[0]; // Extract filename before the query params
  const extension = fileName.split('.').pop()?.toLowerCase(); // Get file extension
  return { fileName, extension };
};

const getInitials = (name: string, lastName: string) => {
  const firstNameInitial = name?.trim()[0] || ''; // Get first letter of first name
  const lastNameInitial = lastName?.trim()[0] || ''; // Get first letter of last name
  return firstNameInitial + lastNameInitial; // Combine both initials
};


const truncateFileName = (fileName: string, extension: string, maxLength = 10) => {
  if (fileName.length > maxLength) {
    return `${fileName.slice(0, maxLength)}...${extension}`;
  }
  return `${fileName}.${extension}`;
};

const getStatusStyles = (status: string) => {
  if (status === 'Newly Arrived') {
    return { color: '#57DB57', backgroundColor: '#FBFBFB' };
  }
  return { color: '#7F7F7F', backgroundColor: '#FBFBFB' }; // Default styles if no conditions are met
};


const CandidateCard = ({
  name,
  status,
  daysAgo,
  phone,
  email,
  pdfLink,
  schedule,
  lastName
}: any) => {
  const statusStyles = getStatusStyles(status);
  const { fileName, extension } = getFileNameAndType(pdfLink); // Extracted filename and file type
  const displayedFileName = truncateFileName(fileName, extension!, 10); // Truncate filename to 10 characters

  console.log(extension,'678');
  
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: 1, justifyContent: 'space-between' }}>
      <Card style={{ borderRadius: '10px', margin: '5px', width: '343px', height: '183px' }}>

        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '6px' }}>
            <Avatar sx={{ width: '35px',height:'35px' }}>{getInitials(name,lastName)}</Avatar>
            <Typography sx={{ mt: '4px', fontSize: '18px' }}>{name}</Typography>
          </Box>
          <Typography
            variant="body2"
            sx={{
              color: statusStyles.color,
              backgroundColor: statusStyles.backgroundColor,
              padding: '4px 8px',
              borderRadius: '4px',
              display: 'inline-block',
              fontSize: '11px',
            }}
          >
            {daysAgo ? `${status} : ${daysAgo}` : status}
          </Typography>
        </Box>

        <Box sx={{ padding: '10px' }}>
          <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={phonecall} alt={'phone'} style={{ fontSize: '14px', marginRight: '4px' }} /> {phone}
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', alignItems: 'center', mt: '10px' }}>
            <img src={emailopen}  alt={'emailOpen'} style={{ fontSize: '14px', marginRight: '4px' }} /> {email}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            // width: '100%',          
            padding: '10px'
          }}
        >
          <Button
            variant="outlined"
            // href={pdfLink}
            // target="_blank"
            disabled
            startIcon={getFileIcon(fileName)}
            sx={{
              width: '150px',
              height: '30px',
              // mt: '12px',
              color: '#888888',
              fontSize: '11px',
              border: '1px solid #DEDEDE',
            }}
          >
            {displayedFileName}
          </Button>

          {/* <SelectData
            type={'select'}
            fieldName={'priorityId'}
            data={schedule}
            options={SelectDataOptions}
            isEditable={'true'}
          /> */}
        </Box>

      </Card>
    </Box>
  );
};

export default CandidateCard;
