import React from "react";
import { Box, Table, TableContainer, Chip, Paper, Button, TableBody, TableHead, TableRow, Icon, Typography } from '@mui/material';
import { StyledTableCell } from './RthClosedTable';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import pendingIcon from '../../../../assets/images/pendingicon.svg';
import { ViewCardProps } from "../../ViewCard";
import priorityHighSymbol from '../../../../assets/images/priorityHighSymbol.svg'
import priorityMediumSymbol from '../../../../assets/images/priorityMediumSymbol.svg'
import priorityLowSymbol from '../../../../assets/images/priorityLowSymbol.svg'

  
const WFA: React.FC <ViewCardProps>= ({ data }) => {
  return ( 
    <Box>
      {/* <SearchBar /> */}
      <Box sx={{ width: "100%", overflowX: "auto", mt: 2 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="RTH table">
            <TableHead sx={{ backgroundColor: "#F9F9F9" }}>
              <TableRow>
                <StyledTableCell>SI.No.</StyledTableCell>
                <StyledTableCell>
                  RTH Name
                  <ArrowUpwardIcon
                    fontSize="inherit"
                    sx={{ ml: 0.5, color: "#CDCDCD" }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  Type of RTH
                  <ArrowUpwardIcon
                    fontSize="inherit"
                    sx={{ ml: 0.5, color: "#CDCDCD" }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  Business
                  <ArrowUpwardIcon
                    fontSize="inherit"
                    sx={{ ml: 0.5, color: "#CDCDCD" }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  Priority
                  <ArrowUpwardIcon
                    fontSize="inherit"
                    sx={{ ml: 0.5, color: "#CDCDCD" }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  Status
                  <ArrowUpwardIcon
                    fontSize="inherit"
                    sx={{ ml: 0.5, color: "#CDCDCD" }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  L1 Status
                  <ArrowUpwardIcon
                    fontSize="inherit"
                    sx={{ ml: 0.5, color: "#CDCDCD" }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  L2 Status
                  <ArrowUpwardIcon
                    fontSize="inherit"
                    sx={{ ml: 0.5, color: "#CDCDCD" }}
                  />
                </StyledTableCell>                
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <StyledTableCell>{index + 1}</StyledTableCell>
                  <StyledTableCell>{row.department.departmentName}</StyledTableCell>
                  <StyledTableCell>
                    <Box
                      sx={{ display: "flex", gap: "4px", alignItems: "center" }}
                    >
                      <Button
                        size="small"
                        sx={{                          
                          backgroundColor: "#F0F0F0",
                          border: "1px solid lightgray",
                          fontSize: "8px",
                          padding: "2px 4px",
                          color: "#8C8C8C",
                          height: "20px",
                          minWidth: "50px",
                        }}
                      >
                        Internal
                      </Button>
                      <Button
                        size="small"
                        sx={{                          
                          backgroundColor: "#F0F0F0",
                          border: "1px solid lightgray",
                          fontSize: "8px",
                          padding: "2px 4px",
                          color: "#8C8C8C",
                          height: "20px",
                          minWidth: "50px",
                        }}
                      >
                        On Role
                      </Button>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>

                    <Typography                       
                    sx={{
                        width:'fit-content',
                        fontSize: "12px",
                        padding: "3%",
                        height: "px",
                        border: "1px solid "+(row.business.businessName === "HEPL" ? "#D3ADF7":"#87E8DE"),
                        backgroundColor:
                        row.business.businessName === "HEPL" ? "#EFDBFF" : "#B5F5EC",
                        color: row.business.businessName === "HEPL" ? "#722ED1" : "#08979C",
                      }}>{row.business.businessName}</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: "1px" }}
                    >
                      { row.priority ==='High'?
                      <Icon ><img src={priorityHighSymbol} alt="" style={{paddingBottom:'6px'}} /></Icon>
                      :row.priority === 'Medium'?
                      <Icon ><img src={priorityMediumSymbol} alt="" style={{paddingBottom:'6px'}} /></Icon>:
                      <Icon><img src={priorityLowSymbol} alt="" style={{paddingBottom:'6px'}} /></Icon>
                      }
                      <Typography sx={
                        {color: row.priority==='High' ? '#F04E4E' : row.priority === 'Medium' ? '#FFA940' : '#73D13D'}
                        }
                        >{row.priority}</Typography>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Chip
                      label={row.status}
                      size="small"
                      sx={{
                        fontSize: "12px",                        
                        height: "px",
                        borderRadius: "1px",
                        backgroundColor: "#389E0D1A",
                        color: "#389E0D",
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                  {row.l1Status === "Pending" ? (
                      <Chip
                        label={row.l1Status}
                        icon={
                          <img
                            src={pendingIcon}
                            alt="pending"
                            style={{ width: "10px", height: "10px" }}
                          />
                        }
                        size="small"
                        sx={{ backgroundColor: "#FFF7E6",color:'#595959',border:'1px solid #FFE7BA' }}
                      />
                    ) : (
                      <Chip
                        label={row.l1Status}
                        icon={
                          <CheckCircleIcon
                            style={{ color: "#4caf50", fontSize: "12px" }}
                          />
                        }
                        size="small"
                        sx={{ backgroundColor: "#F6FFED", color: "#595959",border:'1px solid #D9F7BE' }}
                      />
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.l2Status === "Pending" ? (
                      <Chip
                        label={row.l2Status}
                        icon={
                          <img
                            src={pendingIcon}
                            alt="pending"
                            style={{ width: "10px", height: "10px" }}
                          />
                        }
                        size="small"
                        sx={{ backgroundColor: "#FFF7E6",color:'#595959',border:'1px solid #FFE7BA' }}
                      />
                    ) : (
                      <Chip
                        label={row.l2Status}
                        icon={
                          <CheckCircleIcon
                            style={{ color: "#4caf50", fontSize: "12px" }}
                          />
                        }
                        size="small"
                        sx={{ backgroundColor: "#F6FFED", color: "#595959",border:'1px solid #D9F7BE' }}
                      />
                    )}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default WFA;