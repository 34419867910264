import  { useEffect } from 'react';
import { List, ListItem, ListItemText,Box} from '@mui/material';

const FileList= ({ files, selectedFile, onSelect }:any) => {
  useEffect(()=>{
    onSelect(files[0].id)
  },[files,onSelect])

  return (
    <Box sx={{ width: '20vw',height:'50vh' }}>
    <Box sx={{ padding: 2, borderRadius: 2 }}>
      
      <List sx={{width:'14vw'}}>
        {files.map((file:any) => (
          <ListItem
            key={file.id}
            sx={{
              border: selectedFile === file.id ? '1px solid blue' : '1px solid transparent',
              borderRadius: '8px',
              marginBottom: 1,
              backgroundColor:'#fff' ,
            }}
            onClick={() => onSelect(file.id)}
          >
              

            <ListItemText primary={file.name} />
            {/* <IconButton onClick={(e) => { e.stopPropagation(); onRemove(file.id); }}>
              <Close />
            </IconButton> */}
          </ListItem>
        ))}
      </List>
    </Box>
    </Box>
  );
};

export default FileList;
