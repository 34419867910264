import axios from 'axios';


export const getAllTemplate = async () => {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(
      `https://mytheron-dev-backend.citpl.info/jdTemplate/getAllJd`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log('response.data', response.data);
    return response.data;
  } catch (error: any) {
    console.error('getAllTemplate', error);

    return error.response?.data || { message: 'An unknown error occurred' };
  }
}


export const getTemplateUrl = async (data:any) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.post('https://mytheron-dev-backend.citpl.info/rth/addAttachment', data,
            {
                headers: { Authorization: `Bearer ${token}` },
                
            },
          )

        // console.log('response.data', response.data)
        return response.data
    } catch (error) {
        console.error("getTemplateUrl", error)
    }


}


export const saveAsTemplate = async (data:any)=>{
    const token = localStorage.getItem('token');
    try{
        const response = await axios.post('https://mytheron-dev-backend.citpl.info/jdTemplate/addJdTemplate', data,
            {
                headers: { Authorization: `Bearer ${token}` },
                
            },
        )
        return response.data
    } catch (error) {
        console.error("saveAsTemplate", error)
    }

}