import React from 'react';
import { Box, Typography} from '@mui/material';

interface JobFooterProps {
  userName: string;
  userTitle: string;
  userAvatar: string;
  status?: string
}

const JobFooter: React.FC<JobFooterProps> = ({ userName, userTitle, userAvatar, status }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
      {/* <IconButton size='small' edge='end' aria-label='account of current user' color='inherit' sx={{ ml: 1, padding: 0 }}>
        <Avatar alt={userName} src={userAvatar} sx={{ width: 24, height: 24 }} />
      </IconButton>
      <Box sx={{ ml: 1 }}>
        <Typography variant='body2' sx={{ fontSize: '10px', display: 'inline', marginRight: '4px' }}>{userName}</Typography>
        <Typography variant='caption' sx={{ fontSize: '8px', display: 'inline' }}>{userTitle}</Typography>
      </Box> */}
      { status === "waitingForL1L2" && (
       <Typography variant="body2" color="textSecondary" sx={{ padding:"2px", fontSize:'8px',color:"#8C8C8C" }}>
        Progress
      </Typography>)}
    </Box>
  );
}

export default JobFooter;
