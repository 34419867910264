import { Box } from '@mui/material';
import { TabsPanelNew } from './commoncomponent/TabpannelNew';
import { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';




const Dashboard = () => {

    const [userRole, setUserRole] = useState<string>('');

    const [tabNamee,setTabNamee]=useState<string>('')

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
          const decoded: any = jwtDecode(token);
          const role = decoded.role;
          const rrole = role.replace(/\s+/g, '')
          setUserRole(rrole);
        }
        if(userRole === "TEAMHEAD")
        {
            setTabNamee("My Requests")            
        }
        else if(userRole === "APPROVERHEAD")
        {
            setTabNamee("overall RTH")
        }
        else if(userRole === "RECRUITER")
        {
            setTabNamee("Waiting to Move to Job")
        }
      }, [userRole,tabNamee]);

      

    
    
    
    return (
        <Box>
            {/* <Box sx={{ backgroundColor: '#f0f4ff',  }}>
                <HeaderBar />
            </Box> */}
            <Box sx={{ flexDirection: 'row', flex: 1, display: "flex" }}>
                {/* <FilterSidebar /> */}
                {/* <TabsPanel /> */}
                <TabsPanelNew tabNamee={tabNamee}/>                
                {/* <ViewCard/> */}
            </Box>
        </Box>
    )
}

export default Dashboard;