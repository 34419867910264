import axios from "axios";
// import { CANDIDATE_API_URL } from "../../constants/Crudurl";

export const getCandidateByRthId=async(id:string)=>{
    const token=localStorage.getItem('token');

try{
    const response=await axios.get(
        `https://mytheron-dev-backend.citpl.info/candidate/getCandidateByRthId/${id}`,
        {
            headers:{
                Authorization:`Bearer ${token}`,
            },
        }
    );
    return response.data;
}
catch(error:any){
    return error.response?.data || { message: 'An unknown error occurred' };
}
}