import React, { useEffect } from 'react';
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { fetchJobById } from '../../../redux/features/jobcardSlice';
import Headergpt from './headergpt';
import InternalOnRoleDetails from './internalonroledetails';
import heplLogo from '../../../assets/images/heplLogo.svg'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';


const UxDesign: React.FC = () => {
  const location = useLocation();
  const { id } = location.state || {};
  const dispatch = useAppDispatch();
  const navigate=useNavigate();

  const handleBackButtonClick=()=>{
    navigate('/Dashboard')
  }

  const { jobData, loading, error } = useAppSelector((state) => state.jobs);

  useEffect(() => {
    if (id) {
      dispatch(fetchJobById(id)); // Dispatch the async thunk to fetch job data
    }
  }, [dispatch, id]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!jobData) {
    return <Typography>No job data found</Typography>;
  }
  
  const processSteps = [
    "Open",
    "Move to Job",
    "Create JD",
    "Job Post",
    "Uploading Resumes",
    "Screening",
    "Interviews",
    "Feedback",
    "Closed",
  ];
  const approvalStatus = { l1: "Approved", l2: "Pending" };

  console.log(jobData)

  return (
    <Box sx={{ p: 3 }}>
      <Box display={'flex'}   >
        <IconButton onClick={handleBackButtonClick}><KeyboardBackspaceIcon /></IconButton>
        <Box sx={{ backgroundColor: 'white', width: 'fit-content', borderRadius: '5%' }} p={0.5}><img src={heplLogo} alt="" /></Box>
      </Box>
      <Headergpt
        l1={jobData?.l1Status}
        l2={jobData?.l2Status}
        positionTitle={jobData.positionTitle}
        status={jobData.status}
        numOfPosition={jobData?.numOfPosition}
        Steps={processSteps}
        approvalStatus={approvalStatus}
      />
      <InternalOnRoleDetails jobData={jobData} />
    </Box>
  );
};

export default UxDesign;
