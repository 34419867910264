import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

interface RecruiterState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  selectedRecruiter: string | null;
}

const initialState: RecruiterState = {
  status: 'idle',
  error: null,
  selectedRecruiter: null,
};

interface AssignRecruiterLeadPayload {
  rthId: string;
  recruiterLeadId: string;
  assignedDate: string;
}

interface AssignRecruiterPayload {
  rthId: string;
  recruiterLeadId: string;
  assignedDate: string;
}

export const assignRecruiterLead = createAsyncThunk<
  void,
  AssignRecruiterLeadPayload,
  { rejectValue: string }
>(
  'recruiter/assignRecruiterLead',
  async ({ rthId, recruiterLeadId, assignedDate }, { rejectWithValue }) => {
    try {
      await axios.put(
        `https://mytheron-dev-backend.citpl.info/rth/setRecruiterLead/${rthId}`,
        null,
        {
          params: {
            recruiterLead: recruiterLeadId,
            assignedDate: assignedDate,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);

// Adding the new API call for `assignRecruiter`
export const assignRecruiter = createAsyncThunk<
  void,
  AssignRecruiterPayload,
  { rejectValue: string }
>(
  'recruiter/assignRecruiter',
  async ({ rthId, recruiterLeadId, assignedDate }, { rejectWithValue }) => {
    try {
      await axios.put(
        `https://mytheron-dev-backend.citpl.info/rth/assignRecruiter/${rthId}`,
        null,
        {
          params: {
            recruiter: recruiterLeadId, // Updated parameter from recruiterLead to recruiter
            assignedDate: assignedDate,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);


const recruiterSlice = createSlice({
  name: 'recruiter',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = 'idle';
      state.error = null;
    },
    setSelectedRecruiter: (state, action: PayloadAction<string>) => {
      state.selectedRecruiter = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      // Assign Recruiter Lead cases
      .addCase(assignRecruiterLead.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(assignRecruiterLead.fulfilled, (state) => {
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(assignRecruiterLead.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload ?? 'An unknown error occurred';
      })
      // Assign Recruiter cases
      .addCase(assignRecruiter.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(assignRecruiter.fulfilled, (state) => {
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(assignRecruiter.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload ?? 'An unknown error occurred';
      });
  },
});

export const { resetStatus, setSelectedRecruiter } = recruiterSlice.actions;
export default recruiterSlice.reducer;
