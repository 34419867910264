import React from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Link,
  createTheme,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import theronimg from "../assets/images/theronimg.svg";
import bgimg from "../assets/images/login-pgimg.png";
import SignupImageBox from "./signupimgbox";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

interface SignupFormInputs {
  password: string;
}

const CreatePasswordEmail: React.FC = () => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<SignupFormInputs>({
    defaultValues: {
      password: "",
    },
  });

  const navigate = useNavigate();

  const onSubmit = (data: SignupFormInputs) => {
    console.log(data);
    reset();
    navigate("/login");
  };

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isXLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="xl"
        sx={{ backgroundColor: "#f0f4ff" }}
      >
        <Box
          display="flex"
          flexDirection={isSmallScreen || isMediumScreen ? "column" : "row"}
          height="100vh"
        >
          {!isSmallScreen && !isMediumScreen && <SignupImageBox />}
          <Box
            flex={isSmallScreen || isMediumScreen ? "none" : 0.7}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundImage: `url(${bgimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              padding: isSmallScreen || isMediumScreen ? "1rem" : "3rem",
              minHeight: isSmallScreen || isMediumScreen ? "90%" : "70vh",
            }}
          >
            <Box
              sx={{
                width: isSmallScreen || isMediumScreen ? "100%" : "50%",
                maxWidth: isXLargeScreen ? 400 : 340,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                minHeight: "85vh",
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                mb={isSmallScreen ? 1 : 3}
              >
                <img
                  src={theronimg}
                  alt="Theron"
                  style={{
                    height: isXLargeScreen ? 120 : 90,
                    marginBottom: "1rem",
                  }}
                />
              </Box>
              <Typography
                component="h5"
                variant={isLargeScreen || isXLargeScreen ? "h5" : "h6"}
                alignItems="start"
                sx={{ color: "#616161", fontSize: "18px" }}
              >
                Create Password
              </Typography>
              <Box
                component="form"
                sx={{ mt: 3, marginTop: "5px" }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters long",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="password"
                      label="Password"
                      type="password"
                      autoComplete="current-password"
                      error={Boolean(errors.password)}
                      helperText={errors.password?.message}
                      InputProps={{
                        sx: { borderRadius: 2, backgroundColor: "white" },
                      }}
                      size="small"
                    />
                  )}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    borderRadius: 2,
                    background: "linear-gradient(90deg, #36CFC9, #2F54EB)",
                    textTransform: "none"
                  }}
                >
                  Confirm Password
                </Button>
                <Box textAlign="center" mt={2}>
                  <Link
                   href="#"
                   variant="body2"
                   onClick={() => navigate("/login")}
                   sx={{ color: "#339ED6", textDecoration: "none", fontWeight:"600" }}
                  >
                    Back to Login
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default CreatePasswordEmail;
