import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Slide, Typography } from '@mui/material';
import BulkUploadForm from './forms';
import AdditionalInformation from './AdditionalInformation';
import Address from './AddressForm';
import Education from './EducationForm';
import Review from './ReviewForm';
import FileList from './resumeListGrid';
import CustomTabs from '../../../common/tabs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TransitionProps } from '@mui/material/transitions';
import { useNavigate } from 'react-router-dom';
import Experience from './ExperienceForm';

const Forms = () => {
  const [tabIndex, setTabIndex] = useState(0); // Initialize the tab index
  const [formValues, setFormValues] = useState<any>({});
  const navigate = useNavigate();

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
  const drawerOpen = true;
  const [uploadedResumes, setUploadedResumes] = useState([
    { name: 'Ajay Resume.pdf',id:'1' },
    { name: 'Lp Resume.pdf',id:'2' },
    
  ]);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false); // State to control dialog open/close

  const handleReviewLaterClick = () => {
    setDialogOpen(true); 
  };

  const handleCloseDialog = () => {
    setDialogOpen(false); // Close the dialog
  };


  const tabsData = [
    { label: 'Personal Information' },
    { label: 'Additional Information' },
    { label: 'Address' },
    { label: 'Experience' },
    { label: 'Education' },
    { label: 'Review' },
  ];

  const handleSelectFile = (fileId: string) => {
    setSelectedFile(fileId);
  };

  const handleDelete = (index: number) => {
    const newResumes = uploadedResumes.filter((_, i) => i !== index);
    setUploadedResumes(newResumes);
  };

  const handleNextPage = () => {  
    if (tabIndex < tabsData.length - 1) {
      setTabIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePreviousPage = () => {
    if (tabIndex > 0) {
      setTabIndex((prevIndex) => prevIndex - 1);
    }
  };


  const handleBack=()=>{
    navigate(-1);
  }

  const renderTabContent = () => {
    switch (tabIndex) {
      case 0:
        return <BulkUploadForm formValues={formValues} setFormValues={setFormValues} handleNextPage={handleNextPage} />;
      case 1:
        return <AdditionalInformation formValues={formValues} setFormValues={setFormValues} />;
      case 2:
        return <Address formValues={formValues} setFormValues={setFormValues} />;
      case 3:
        return <Experience formValues={formValues} setFormValues={setFormValues} />;
      case 4:
        return <Education formValues={formValues} setFormValues={setFormValues} />;
      case 5:
        return <Review formValues={formValues} />;
      default:
        return null;
    }
  };

  const renderButtons = () => {

    if (tabIndex === 0) {
      return null; // No buttons will be displayed for the first tab
    }
  
    if (tabIndex === 5) {
      return (
        <Box sx={{display:'flex',justifyContent:'flex-end'}}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => console.log('Completed')} 
        >
          Completed
        </Button>
        </Box>
      );
    }

    return (
      <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, mb: 2,gap:'10px' }}>
        <Button
          variant="outlined"
          onClick={handlePreviousPage}
          disabled={tabIndex === 0} // Disable "Previous" button on the first tab
        >
          Previous
        </Button>
        <Button variant="contained" color="primary" onClick={handleNextPage} sx={{mr:'10px'}}>
          Continue
        </Button>
      </Box>
      </Box>
    );
  };


  return (

  <><Box sx={{display:'flex',flexDirection:'row',gap:'8px',mt:'4px',mb:'8px',justifyContent:'space-between'}} >
    <Box sx={{display:'flex',flexDirection:'row'}}>
    <ArrowBackIcon sx={{color:'grey',cursor:'pointer'}} onClick={handleBack}/>
      <Typography sx={{ml:'6px',fontWeight:'500'}}>Bulk Upload</Typography>
      </Box>
      <Box sx={{display:'flex',justifyContent:'flex-end'}}>
    <Button variant='contained' sx={{width:'270px',fontSize:'14px',textTransform:'none',backgroundColor:'#1677FF',color:'white',borderRadius:'4px',mr:'4px'}}
      onClick={handleReviewLaterClick}
    >Review Later & Upload All Profile</Button>
    </Box>
    </Box>
    
    <Paper elevation={3} sx={{ height:'80vh', display: 'flex',mr:'6px',m:1}}>
        <Box
          sx={{
            width: '240px',
            flexShrink: 0,
            borderRight: '1px solid #E5E3E3',
            mr: '4px',
            ml: '2px',
          }}
        >

          <Box sx={{ width: '16vw', height: '72vh', mt: '10px', overflowY: 'auto', overflowX: 'hidden' }}>
            <FileList files={uploadedResumes} selectedFile={selectedFile} onSelect={handleSelectFile} onRemove={handleDelete} />
          </Box>

        </Box>

        <Box component="main" sx={{ flexGrow: 1, ml: '10px' }}>
          <CustomTabs tabsData={tabsData} tabIndex={tabIndex} setTabIndex={setTabIndex} drawerOpen={drawerOpen} />
          <Box sx={{position:"relative"}}>
            <Box sx={{overflow:'hidden',height:480}}>
          {renderTabContent()}
          </Box>
          <Box sx={{position:'absolute',bottom:'0',right:'20px'}}>
          {renderButtons()}
          </Box>
          </Box>
        </Box>
      </Paper>

      <Dialog
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        sx={{borderRadius:'20px'}}
      >
        <DialogTitle sx={{pb:'0'}}>
          <Typography sx={{fontSize:'20px',fontWeight:'600'}}>
          You've completed 8 out of 10 candidate profiles.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{display:'flex',justifyContent:'center', fontSize:'14px', mt:'4px'}}>
            Would you like to review later?
          </DialogContentText>
        </DialogContent>
        <Box sx={{display:'flex', justifyContent:'center'}}>
        <DialogActions sx={{mb:'32px'}}>
          <Button onClick={handleCloseDialog} variant="outlined" sx={{border:'InactiveBorder',color:'#848484',textTransform:'none'}}>
            Continue Editing
          </Button>
          <Button onClick={handleCloseDialog} variant="contained" color="primary" sx={{color:'white',backgroundColor:'#1677FF',borderRadius:'8px',textTransform:'none'}}>
            Review Later
          </Button>
        </DialogActions>
        </Box>
      </Dialog>

      </>
  );
};

export default Forms;
