import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  LOGIN_API_URL,
  LOGIN_AUTH_URL,
} from "../constants/Crudurl";
import { apiCall } from "../utils";
import axios from "axios";

// Define the LoginTypes interface
export interface LoginTypes {
  identifier: string;
  password: string;
}

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (loginData: any) => {
    // const response = await apiCall.post(`${LOGIN_API_URL}`, {
    //     params: {
    //         loginData
    //     },
    //   });
    // return response.data;
  }
);
// Define the loginUser function as a thunk action
export const loginUse1r = (data1: LoginTypes) => {
  return async () => {
    try {
      const { data } = await apiCall.post(`${LOGIN_API_URL}`, {
        params: {
          identifier: data1.identifier,
          password: data1.password,
        },
      });
      console.warn("api call", data);
      //   if (status === 200) {
      //     // Dispatch a success action here if needed
      //     // dispatch({ type: 'LOGIN_SUCCESS', payload: data });
      //   } else {
      //     // Dispatch a failure action here if needed
      //     // dispatch({ type: 'LOGIN_FAILURE', payload: data });
      //   }
    } catch (error) {
      // Dispatch a failure action with the error message
      // dispatch({ type: 'LOGIN_ERROR', payload: error.message });
    }
  };
};
// useEffect(()=>{
//     const myToken={
//       refToken:token
//     }
//     axios.post(REFRESH_API_URL,myToken).then((res)=>console.log("res",res)).catch((err)=>console.error(err))
//   },[])

// export const RefreshTokenGetAPI =async(token:string)=>{
//       return await axios.post(REFRESH_API_URL,{refToken:token});
// }

// https://mytheron-dev-backend.citpl.info/auth/sendMail?email=praveenkumarganesan96%40gmail.com
//   const token = "eyJhbGciOiJIUzI1NiJ9.eyJyb2xlIjoiVVNFUiIsIm5hbWUiOiJwcmF2ZWVuQGdtYWlsLmNvbSIsInVzZXJJZCI6IjY2YzU3NDQ1MjEzYzM5MzllMzQ2MjgyNiIsImVtYWlsIjoicHJhdmVlbkBnbWFpbC5jb20iLCJzdWIiOiJwcmF2ZWVuQGdtYWlsLmNvbSIsImlhdCI6MTcyNDIxNjM4OSwiZXhwIjoxNzI0MzAyNzg5fQ.HlFrVYtIUG-MGA4AQDvbum1z-2KTHgsyPxMCVIE7x1Y"
// if (!token) throw new Error('No token found');
// const response = await axios.put(`https://mytheron-dev-backend.citpl.info/profile/updateProfile?id=${userId}`,
//   profileData,
//   {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   }
// );

// return response.data;
//   export const forgotPasswordGetAPI =async(email:string)=>{
//   const token = localStorage.getItem('token');
//     return await axios.get(`${LOGIN_AUTH_URL}/sendMail?email=${email}`,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
// }
export const forgotPasswordGetAPI = async (email: string) => {
  try {
    const response = await axios.post(
      `${LOGIN_AUTH_URL}/sendMail/${encodeURIComponent(email)}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to send forgot password email:", error);
    throw error;
  }
};
