import React from "react";
import { Box, Typography, Tabs, Tab } from "@mui/material";

interface RthTabProps {
  currentTab: number;
  onChangeTab: (type: number) => void;
}

const INTERNAL_ON_ROLE = 1;
const INTERNAL_CONTRACT = 2;
const EXTERNAL_CONTRACT = 3;
const EXTERNAL_ONTIME = 4;

const RthTab: React.FC<RthTabProps> = ({ currentTab, onChangeTab }) => {
  // console.log("RTHTAB", currentTab);

  const handleType = (event: React.SyntheticEvent, value: number) => {
    onChangeTab(value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "8px", // Reduced padding
        gap: "20px", // Reduced gap
        width: "106%", // Adjusted width
        height: "auto",
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.01)",
        borderBottom: 1,
        borderColor: "divider",
        flex: "none",
        order: 1,
        flexGrow: 0,
      }}
    >
      <Typography
        variant="body2"
        sx={{ alignSelf: "center", fontWeight: "bold" }}
        gutterBottom
      >
        Type of RTH
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "2px",
          gap: "12px", // Reduced gap between text and tabs
          width: "21%", // Adjusted width
          height: "auto",
          backgroundColor: "#FFFFFF",
          border: 1,
          borderColor: "divider",
          borderRadius: 2,
          flex: "none",
          order: 1,
          flexGrow: 0,
        }}
      >
        <Box>
          <Typography variant="body2" sx={{ alignSelf: "center", paddingLeft:'5px' }} gutterBottom>
            Internal -
          </Typography>
        </Box>
        <Tabs
          value={currentTab}
          onChange={handleType}
          aria-label="internal tabs"
          sx={{ "& .MuiTabs-indicator": { display: "none" } }}
        >
          <Tab
            label="On-role"
            value={1}
            sx={{
              minWidth: "80px", // Adjusted tab width
              fontSize: "0.75rem", // Reduced font size
              padding: "2px 4px", // Reduced padding
              minHeight: "40px", // Reduced tab height
              border:
                currentTab === INTERNAL_ON_ROLE ? "1px solid" : "1px solid",
              borderColor:
                currentTab === INTERNAL_ON_ROLE ? "#1677FF" : "divider",
              borderRadius: "4px",
              margin: "4px",
              backgroundColor:
                currentTab === INTERNAL_ON_ROLE
                  ? "#F0F5FF"
                  : "transparent",
            }}
          />
          <Tab
            label="Contract"
            value={2}
            sx={{
              minWidth: "80px", // Adjusted tab width
              fontSize: "0.75rem", // Reduced font size
              padding: "2px 4px", // Reduced padding
              minHeight: "40px", // Reduced tab height
              border:
                currentTab === INTERNAL_CONTRACT ? "1px solid" : "1px solid",
              borderColor:
                currentTab === INTERNAL_CONTRACT ? "#1677FF" : "divider",
              borderRadius: "4px",
              margin: "4px",
              backgroundColor:
                currentTab === INTERNAL_CONTRACT
                  ? "#F0F5FF"
                  : "transparent",
            }}
          />
        </Tabs>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "2px",
          gap: "12px", // Reduced gap between text and tabs
          width: "21%", // Adjusted width
          height: "auto",
          backgroundColor: "#FFFFFF",
          border: 1,
          borderColor: "divider",
          borderRadius: 2,
          flex: "none",
          order: 1,
          flexGrow: 0,
        }}
      >
        <Box>
          <Typography variant="body2" sx={{ alignSelf: "center", paddingLeft:'5px' }} gutterBottom>
            External -
          </Typography>
        </Box>
        <Tabs
          value={currentTab}
          onChange={handleType}
          aria-label="external tabs"
          sx={{ "& .MuiTabs-indicator": { display: "none" } }}
        >
          <Tab
            label="Contract"
            value={3}
            sx={{
              minWidth: "80px", // Adjusted tab width
              fontSize: "0.75rem", // Reduced font size
              padding: "2px 4px", // Reduced padding
              minHeight: "40px", // Reduced tab height
              border:
                currentTab === EXTERNAL_CONTRACT ? "1px solid" : "1px solid",
              borderColor:
                currentTab === EXTERNAL_CONTRACT ? "#1677FF" : "divider",
              borderRadius: "4px",
              margin: "4px",
              backgroundColor:
                currentTab === EXTERNAL_CONTRACT
                  ? "#F0F5FF"
                  : "transparent",
            }}
          />
          <Tab
            label="One Time"
            value={4}
            sx={{
              minWidth: "80px", // Adjusted tab width
              fontSize: "0.75rem", // Reduced font size
              padding: "2px 4px", // Reduced padding
              minHeight: "40px", // Reduced tab height
              border:
                currentTab === EXTERNAL_ONTIME ? "1px solid" : "1px solid",
              borderColor:
                currentTab === EXTERNAL_ONTIME ? "#1677FF" : "divider",
              borderRadius: "6px",
              margin: "4px",
              backgroundColor:
                currentTab === EXTERNAL_ONTIME
                  ? "#F0F5FF"
                  : "transparent",
            }}
          />
        </Tabs>
      </Box>
    </Box>
  );
};

export default RthTab;
