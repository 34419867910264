import React, { useState } from 'react';
import { Drawer, List, ListItem, IconButton, Hidden, Box } from '@mui/material';
import { Menu } from '@mui/icons-material';
import theronlogo from '../../../assets/images/theronlogo.svg';
import dashboardIcon from '../../../assets/images/dashboardIcon.svg'
import rthIcon from '../../../assets/images/rthIcon.svg'
import settingsIcon from '../../../assets/images/settingsIcon.svg'
import reportsIcon from '../../../assets/images/reports.svg'
import { useNavigate } from 'react-router-dom';

const drawerWidth = 64;

const Sidebar: React.FC = () => {
    const [isMobileOpen, setIsMobileOpen] = useState(false);

    const navigate=useNavigate();


    const handleDrawerToggle = () => {
        setIsMobileOpen(!isMobileOpen);
    };

    const handleRthIconClick=()=>{
        navigate("/Dashboard");
    }

    const drawerContent = (
        <List sx={{ padding: 0, }} >
            <Box display={"flex"} flexDirection={"column"}>
                <Box>
                    <ListItem sx={{ justifyContent: 'center', padding: '10px 0', marginBottom: "1%" }}>
                        <img src={theronlogo} alt='logo' style={{ maxWidth: '40px', maxHeight: '40px' }} />
                    </ListItem>
                </Box>
                <Box >
                    <ListItem sx={{ justifyContent: 'center', padding: '10px 0' }}>
                        <IconButton>
                            <img src={dashboardIcon} alt='logo' style={{ maxWidth: '40px', maxHeight: '40px' }} />
                        </IconButton>
                    </ListItem>
                    <ListItem sx={{ justifyContent: 'center', padding: '10px 0' }}>
                        <IconButton onClick={handleRthIconClick}>
                            <img src={rthIcon} alt='logo' style={{ maxWidth: '40px', maxHeight: '40px' }} />
                        </IconButton>
                    </ListItem>
                    <ListItem sx={{ justifyContent: 'center', padding: '10px 0' }}>
                        <IconButton>
                            <img src={reportsIcon} alt='logo' style={{ maxWidth: '40px', maxHeight: '40px' }} />
                        </IconButton>
                    </ListItem>
                    <ListItem sx={{ justifyContent: 'center', padding: '10px 0' }}>
                        <IconButton>
                            <img src={settingsIcon} alt='logo' style={{ maxWidth: '40px', maxHeight: '40px' }} />
                        </IconButton>
                    </ListItem>
                </Box>
            </Box>
        </List>
    );

    return (
        <Box sx={{ display: 'flex', width: 64 }}>
            <Hidden smUp>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ marginLeft: 2, marginTop: 2 }}
                >
                    <Menu />
                </IconButton>
                <Drawer
                    variant="temporary"
                    open={isMobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, background: 'linear-gradient(180deg, #36CFC9, #2F54EB)' },
                    }}
                >
                    {drawerContent}
                </Drawer>
            </Hidden>
            <Hidden xsDown>
                <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', background: 'linear-gradient(180deg, #36CFC9, #2F54EB)' },
                    }}
                >
                    {drawerContent}
                </Drawer>
            </Hidden>
        </Box>
    );
};

export default Sidebar;
