import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react'
import CandidateCard from '../../../common/CandidateCard';
import { getCandidateByRthId } from '../../../../redux/features/candidate';

export const CandidateDetails = ({ viewType }:any) => {
 const[candidate,setCandidate]=useState<any>([]);
  useEffect(()=>{
    getCandidateByRthId('67062dbc919e6d14b5be7371').then(response=>{
      setCandidate(response.data);
    });
  },[])
  
    return (
      <>
        {viewType === 'grid' ? (
          <Grid container >
            {candidate.map((candidate:any) => (
              <Grid item xs={12} sm={6} md={3} key={candidate.id}>
                <CandidateCard
                  name={candidate.firstName}
                  lastName={candidate.lastName}
                  status={candidate.status}
                  daysAgo={candidate.daysAgo}
                  phone={candidate.phoneNumber}
                  email={candidate.emailAddress}
                  pdfLink={candidate.resumeUrl}
                  schedule={candidate.priorityId}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography>List View</Typography>
        )}
      </>
    );
  };
  
  