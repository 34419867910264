import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Box, Button, FormControl, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import TableContainer from '@mui/material/TableContainer';
import { useLocation } from 'react-router-dom';
import legendLogo from '../../../assets/images/legendLogo.svg';
import { getRecruiter } from "../../../Crud/rth";
import { assignRecruiter, resetStatus, setSelectedRecruiter } from '../../../redux/features/recruiterSlice'; 
import {AppDispatch, RootState } from '../../../redux/store'; 
import { toast } from 'react-toastify';

export interface AssignRecruiterProps {
    numOfPosition?: string;
}

const AssignRecruiter: React.FC<AssignRecruiterProps> = ({ numOfPosition }) => {
    const dispatch = useDispatch<AppDispatch>();
    const recruiterId = useSelector((state: RootState) => state.recruiter.selectedRecruiter);
    const status = useSelector((state: RootState) => state.recruiter.status);
    const error = useSelector((state: RootState) => state.recruiter.error);

    const [isButtonClicked, setButtonClicked] = useState(false);
    const [recruiterData, setRecruiterData] = useState<any[]>([]);
    const [selectedDate, setSelectedDate] = useState<string | null>(null);

    const location = useLocation();
  const { id } = location.state || {};
  
  

    useEffect(() => {
        getRecruiter()
            .then((res) => {
                const data = res.data;
                console.log("dataaaaa", data);
                setRecruiterData(data || []);
            })
            .catch((err) => console.error(err));
    }, []);

    // const fetchRecruiterLead = () => {
    //     getRecruiterLead()
    //         .then((res) => {
    //             const data = res.data;
    //             console.log("Recruiter data:", data);
    //             setRecruiterData(data || []);
    //         })
    //         .catch((err) => console.error(err));
    // };

    const handleChange = (event: SelectChangeEvent) => {
        const selectedRecruiterId = event.target.value as string;
        dispatch(setSelectedRecruiter(selectedRecruiterId));

        const currentDate = new Date();
        // Format date as YYYY-MM-DD
        const formattedDate = currentDate.toISOString().split('T')[0];
        setSelectedDate(formattedDate);
    };

    const handleButtonClick = () => {
        setButtonClicked(true);
    };

    const handleButtonCancel = () => {
        setButtonClicked(false);
        dispatch(setSelectedRecruiter(''));
        dispatch(resetStatus());
        setSelectedDate(null);
    };

    const handleSubmit = () => {
        if ( recruiterId && selectedDate) {
            dispatch(assignRecruiter({rthId: id, recruiterLeadId: recruiterId, assignedDate: selectedDate }));
        } else if (!recruiterId && !selectedDate){
            toast.error('Failed to assign recruiter');
        }
    };

    useEffect(() => {
        if (status === 'succeeded') {
            setButtonClicked(false);
            dispatch(setSelectedRecruiter(''));
            setSelectedDate("")
            dispatch(resetStatus());
            toast.success('Recruiter assigned successfully');
        } else if (status === 'failed') {
            console.error(error);
        }
    }, [status, error, dispatch]);

    const formatDateForDisplay = (dateString: string) => {
        const date = new Date(dateString);
        const day = String(date.getUTCDate()).padStart(2, '0'); 
        const month = date.toLocaleString('en-GB', { month: 'long', timeZone: 'UTC' }); 
        const year = date.getUTCFullYear(); 
        
        return `${day} ${month} ${year}`; 
    };

    return (
        <Box>
            {!isButtonClicked && (
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} flexDirection={'row'}>
                    <Box>
                    <Typography variant="h6" sx={{ fontSize: "15px"}}> Recruiter Not Yet Assigned </Typography>
                    <Typography variant="h6" sx={{ fontSize: "10px", color: '#8C8C8C' }}>You've got {numOfPosition} positions waiting. Ready to assign a recruiter?</Typography>
                    </Box>
                    <Button variant="outlined" size="small" sx={{ border: '1.5px solid' }} onClick={handleButtonClick}>Assign Recruiter</Button>
                </Box>
            )}

            {isButtonClicked && (
                <Box>
                    <Typography variant="h6" sx={{ fontSize: "12px", color: '#8C8C8C', marginBottom: '1.5%' }}>Total No of Position opened:</Typography>
                    <TableContainer component={Paper} sx={{ marginBottom: '1.5%', borderRadius: '0px', boxShadow: 'none' }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
                            <TableHead>
                                <TableRow sx={{ backgroundColor: '#F9F9F9' }}>
                                    <TableCell align="left">No. of Position</TableCell>
                                    <TableCell align="left">Assign Recruiter </TableCell>
                                    <TableCell sx={{ alignContent: 'center', alignItems: 'center' }} align="center">
                                        <span>Assigned Date</span> <img src={legendLogo} alt="" />  <span style={{ color: 'grey', fontSize: '10px' }}>assigned date can't be edited</span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="left">{numOfPosition}</TableCell>
                                    <TableCell align="left" sx={{ padding: '0' }} width={'50%'}>
                                        <FormControl fullWidth>
                                            <Select
                                                id="demo-simple-select"
                                                value={recruiterId || ''}
                                                label="Choose Recruiter"
                                                displayEmpty
                                                onChange={handleChange}
                                                renderValue={(selected) => {
                                                    if (selected === '') {
                                                        return <span style={{ color: 'grey' }}>Choose Recruiter</span>
                                                    }
                                                    const selectedRecruiter = recruiterData.find(data => data.id === selected)

                                                    return (
                                                        <Box display={"flex"} alignItems="center" gap={1} borderRadius={'5px'} border={'1px solid grey'} width={'fit-content'} padding={'5px'}>
                                                            <Avatar sizes="small" alt={selectedRecruiter?.firstName} src={selectedRecruiter?.profilePicture} />
                                                            <Box display={'flex'} flexDirection={'column'}>
                                                                <Box display='flex' flexDirection={'row'} alignItems={'center'} gap={0.5}>
                                                                    <Typography variant="h6" sx={{ fontSize: '12px', color: 'black' }}>
                                                                        {selectedRecruiter?.firstName}
                                                                    </Typography>
                                                                </Box>
                                                                <Typography variant="body2" sx={{ fontSize: '12px', color: '#8C8C8C' }}>
                                                                    {selectedRecruiter?.role}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    );
                                                }}
                                                sx={{
                                                    color: 'grey',
                                                    '& .MuiSelect-select': {
                                                        border: 'none',
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                    '& .MuiSelect-iconOutlined': {
                                                        color: 'inherit',
                                                        border: 'none',
                                                    },
                                                }}
                                            >
                                                {recruiterData.map((data, index) => (
                                                    <MenuItem key={index} value={data.id} sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <Box display="flex" alignItems="center" width="100%">
                                                            <Box display="flex" gap={2} alignItems="center">
                                                                <Avatar sizes="small" alt={data.firstName} src={data?.profilePicture} />
                                                                <Box display="flex" flexDirection="column">
                                                                    <Typography variant="h6" sx={{ fontSize: '12px', color: 'black', marginBottom: '0.5em' }}>
                                                                        {data.firstName}
                                                                    </Typography>
                                                                    <Typography variant="body2" sx={{ fontSize: '10px', color: '#8C8C8C' }}>
                                                                        {data.role}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell align="center">
                {selectedDate ? formatDateForDisplay(selectedDate) : '-'}
            </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box display={"flex"} justifyContent={'end'} gap={3}>
                        <Button variant="text" sx={{ color: 'grey' }} onClick={handleButtonCancel}>Cancel</Button>
                        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={status === 'loading'}>
                            {status === 'loading' ? 'Submitting...' : 'Submit'}
                        </Button>
                    </Box>
                </Box>
            )}
        </Box>
    )
}

export default AssignRecruiter;