import { FormControl, InputLabel, Select, MenuItem, FormHelperText, Checkbox, ListItemText } from '@mui/material';

const CommonMultiSelect = ({ labelId, label, name, value, onChange, options, error, helperText }:any) => {
  return (
    <FormControl fullWidth size="small" error={error} sx={{ borderColor: "divider" }}>
      <InputLabel id={labelId} sx={{ fontSize: '0.9rem' }}>{label}</InputLabel>
      <Select
        labelId={labelId}
        label={label}
        multiple
        value={value}
        onChange={onChange}
        name={name}
        renderValue={(selected) => selected.join(', ')} // Display selected items as a comma-separated list
      >
        {options?.map((option:any) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={value.indexOf(option.value) > -1} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default CommonMultiSelect;
