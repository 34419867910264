import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Button, Paper, Tooltip, IconButton, Typography } from '@mui/material';
import { TemplateComponent } from './templateComponent';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Close';
import docIcon from '../../../../../assets/images/doc 1.svg';
import pdfIcon from '../../../../../assets/images/pdf 1.svg';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function JDcreation() {
  const [value, setValue] = React.useState(0);
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [fileIcon, setFileIcon] = React.useState<string | null>(null);
  const [HideSAD, setHideSAD] = React.useState(true);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const goToTemplateTab = () => {
    setValue((prevValue) => Math.max(0, prevValue - 1)); // Decrease the tab value by 1, ensuring it doesn't go below 0
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHideSAD(false);
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      
      // Check if the file size exceeds 2MB (2MB = 2 * 1024 * 1024 bytes)
      const maxFileSize = 2 * 1024 * 1024;
      if (file.size > maxFileSize) {
        alert('File size must be less than 2MB'); // Show error message
        event.target.value = ''; 
        return; // Exit function if file exceeds size limit
      }
  
      setSelectedFile(file);
  
      // Set the file icon based on the file type
      const fileType = file.type;
      if (fileType === 'application/pdf') {
        setFileIcon(pdfIcon); // Set PDF icon
      } else {
        setFileIcon(docIcon); // Set Word icon for .doc, .docx, etc.
      }
    }
  };  

  const truncateText = (text: string | null | undefined, maxLength: number) => {
    if (text == null) {
      return '';
    }
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  const clearFile = () => {
    setSelectedFile(null);
    setFileIcon(null); // Clear the icon when the file is cleared
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  return (
    <Paper sx={{ ml: '70px', maxWidth: '90vw', height: '85.5vh', display: 'flex', borderRadius: 0 }}>
      <Box sx={{ maxWidth: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '90vw' }}>
          {/*Tab component*/}
          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              
              TabIndicatorProps={{
                style: {
                  width: '24px', // Width of the indicator
                  border: '1px solid #1677FF',
                  borderRadius: '1px',
                  backgroundColor: '#1677FF',
                  marginLeft: '50px',
                },
              }}
            >
              <Tab
                label="Generate JD"
                {...a11yProps(0)}
                sx={{
                  color: value === 0 ? '#1677FF' : '#A9A9A9', // Active tab color
                  textTransform: 'none', // Disable uppercase transformation
                }}
              />
              <Tab
                label="Templates"
                {...a11yProps(1)}
                sx={{
                  color: value === 1 ? '#1677FF' : '#A9A9A9', // Active tab color
                  textTransform: 'none', // Disable uppercase transformation
                }}
              />
              <Tab
                label="Drafts"
                {...a11yProps(2)}
                sx={{
                  color: value === 2 ? '#1677FF' : '#A9A9A9', // Active tab color
                  textTransform: 'none', // Disable uppercase transformation
                }}
              />
            </Tabs>
          </Box>

          {/* Upload button and file display */}
          <Box sx={{ mt: 2, mr: 2 }}>
            {!selectedFile ? (
              <Button
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                sx={{
                  border: '1px solid #1677FF80',
                  color: '#1677FF',
                  textTransform: 'none',
                  fontWeight: 700,
                  '&:hover': {
                    border: '1px solid #1677FF80',
                    backgroundColor: 'none',
                  },
                  borderRadius: '8px'
                }}
                endIcon={
                  <Tooltip
                    title={
                      <React.Fragment>
                        <ul style={{ margin: 0, paddingLeft: '16px' }}>
                          <li style={{ marginBottom: '4px' }}>Allowed file type: .pdf, .docx</li>
                          <li>File must be less than: 2MB</li>
                        </ul>
                      </React.Fragment>
                    }
                  >
                    <ErrorOutlineIcon sx={{ fontSize: '10px' }} />
                  </Tooltip>
                }
              >
                Import
                <VisuallyHiddenInput
                  type="file"
                  accept=".doc,.pdf,.docx"
                  onChange={handleFileUpload}
                />
              </Button>
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center', border: '1px solid #1677FF80', pl: '14px', borderRadius: '8px', bgcolor:"#f0f5ff",  height:'34px' }}>
                {fileIcon && <img src={fileIcon} alt=".doc/.docx or .pdf " style={{ marginLeft: '2px', marginRight: '5px', width:'16px' }} />}
                <Typography variant="body2" sx={{ color: '#878787' }}>{truncateText(selectedFile.name,6)}{fileIcon===pdfIcon?".pdf":".docx"}</Typography>
                <IconButton aria-label="delete" onClick={clearFile} sx={{'&:hover':{bgcolor: 'transparent'} }}>
                  <DeleteIcon sx={{fontSize:'14px', color:'#9fa1a6'}} />
                </IconButton>
                <Button
                  component="label"
                  sx={{color: '#3087ff', textTransform: 'none', fontSize:'10px', fontWeight: 600, height:"10px", mr:'4px'}}
                >
                  Change File
                  <VisuallyHiddenInput
                    type="file"
                    accept=".doc,.pdf"
                    onChange={handleFileUpload}
                  />
                </Button>
              </Box>
            )}
          </Box>
        </Box>

        <CustomTabPanel value={value} index={0}>
        <TemplateComponent pageType="Generate" buttonDisable={HideSAD}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <TemplateComponent pageType="Template" goToTemplateTab={goToTemplateTab} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <TemplateComponent pageType="Draft" goToTemplateTab={goToTemplateTab}  />
        </CustomTabPanel>
      </Box>
    </Paper>
  );
}
