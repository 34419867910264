import React, { useEffect, useState } from 'react';
import {
    FormControl,
    FormControlLabel,
    Checkbox,
    Typography,
    Button,
    Box,
    Divider,
    Collapse,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    OutlinedInput,
    ListItemText
}
    from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import NavigateNext from '@mui/icons-material/NavigateNext';
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import { styled } from '@mui/system';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { getRthBusinessInputData } from '../../../redux/features/rthSlice';
import { getRthLocationData } from '../../../Crud/rth';


const SmallCheckbox = styled(Checkbox)(({ theme }) => ({
    '& .MuiSvgIcon-root': {
        fontSize: 12,
    },
}));




// type Props = {
//     drawerOpen: boolean;
//     onChangeFilter: (value: boolean) => void
//     tabIndex: number
//     tabName: string
//     label?: string
//     teamHeadFilterDto: any
//     setTeamHeadFilterDto: Function
//     myRquestCounts: any
// }


const CustomSmallFormControlLabel = (props: { label: string, number?: string, onChange?: (e: any) => void }) => (
    <FormControlLabel

        control={<SmallCheckbox sx={{ paddingLeft: '30px', borderColor: '#D9D9D9' }} onChange={props.onChange} />}
        label={
            <Box display="flex" justifyContent="space-between" width="100%">
                <Typography sx={{ fontSize: '12px', paddingRight: '100px' }}>{props.label}</Typography>
                <Typography sx={{ fontSize: '12px', marginLeft: 'auto', color: '#8C8C8C' }}>{props.number}</Typography>
            </Box>
        }
    />
);

const FilterSidebar = ({ drawerOpen, onChangeFilter, tabIndex, tabName, label, teamHeadFilterDto, setTeamHeadFilterDto, myRquestCounts }:any) => {

    const toggleDrawer = () =>
        onChangeFilter(!drawerOpen)

    const names = [
        'Open',
        'Resume',
        'Create Job',
        'Job Post',
        'Move to Job',
        'Screening',
        'Interview',
        'Feedback'
    ];

    const posTitleNames=['UX Designer','Software Developer','Product Management']

    const jobSocialNames=['Naukri','Linkedin','Indeed','Website']

    const [businessData,setBusinessData]=useState([]);
    const [locationData,setLocationData]=useState([]);

    console.log(businessData);

    useEffect(() => {

        getRthBusinessInputData().then(res => {
          const data = res.data?.map((i: any) => { return { value: i.businessId, label: i.businessName } })
          setBusinessData(data)
        }).catch((err) => console.error(err));

        getRthLocationData().then(res => {
            const data = res.data?.map((i: any) => { return { value: i.locationId, label: i.locationName } })
            setLocationData(data)
          }).catch((err) => console.error(err));

    },[])

    const [personName, setPersonName] = React.useState<string[]>([]);    

    const handleChangee = (event: SelectChangeEvent<typeof personName>) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        console.log(personName);        
        setTeamHeadFilterDto((prev:any)=>({
            ...prev,
            rthStages:personName
        }))
    };

    const handleDateChange = (value: [Date, Date]) => {
        const fromDate = value[0];
        const toDate = value[1]
        const extractedDate = {
            fromYear: fromDate.getFullYear(),
            fromMonth: fromDate.getMonth() + 1,  // Months are 0-indexed, so +1
            fromDay: fromDate.getDate(),
            toYear: toDate.getFullYear(),
            toMonth: toDate.getMonth() + 1,
            toDay: toDate.getDate(),
        };
        
        setTeamHeadFilterDto((prev: any) => ({
            ...prev,
            fromDay: extractedDate.fromDay,
            fromMonth: extractedDate.fromMonth,
            fromYear: extractedDate.fromYear,
            toDay: extractedDate.toDay,
            toMonth: extractedDate.toMonth,
            toYear: extractedDate.toYear
        }))
    }

    const handleDateClean = () => {
        setTeamHeadFilterDto((prev: any) => ({
            ...prev,
            fromYear: 0,
            fromMonth: 0,
            fromDay: 0,
            toYear: 0,
            toMonth: 0,
            toDay: 0
        }))
    }


    const jobKind: string[] = ['Internal-Onrole', 'Internal-Contract', 'External-One Time', 'External-Contract'];

    const [age, setAge] = React.useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value as string);
    };


    return (
        <Box
            bgcolor={'white'}
            sx={{
                margin: '20px', borderRadius: '10px', marginRight: "0px",
                width: drawerOpen ? "216px" : "50px", height: drawerOpen ? "250px" : "0px",
                position: drawerOpen ? "relative" : "absolute", zIndex: 1000,
                top: "-10px"
            }}
        >
            <Box sx={{ display: 'flex', bgcolor: "white", padding: "10px", justifyContent: 'space-between', alignItems: 'center', width: "90%", margin: '2px', borderRadius: '10px' }}>
                <TuneIcon sx={{ fontSize: 20 }} />

                {
                    drawerOpen &&
                    <Typography variant="h6" sx={{ fontSize: 14 }}
                    >
                        Filter
                    </Typography>
                }

                <Box sx={{ cursor: "pointer", padding: "0px", borderRadius: "3px", backgroundColor: '#F6F6F6', marginRight: "10px" }}>
                    {drawerOpen ? <NavigateBefore onClick={toggleDrawer} sx={{ fontSize: 14 }} /> : <NavigateNext onClick={toggleDrawer} sx={{ fontSize: 14 }} />}
                </Box>
            </Box>
            {drawerOpen &&
                <Divider />}

            <Collapse in={drawerOpen} timeout="auto" unmountOnExit orientation='horizontal'
            >
                <Box width={200} padding={1} bgcolor={"white"}>

                    {(tabName === "My Requests" || tabName === "overall RTH") && (
                        <Box marginTop={2}>
                            <Typography variant="subtitle1" sx={{ fontSize: '12px', fontWeight: 'bold', paddingLeft: '10px' }}>
                                L1 Status
                            </Typography>
                            <FormControl component="fieldset">
                                <CustomSmallFormControlLabel label="Pending" number={myRquestCounts["L1 Status Pending"]}
                                    onChange={() => setTeamHeadFilterDto((prev: any) => ({
                                        ...prev,
                                        l1StatusPending: !prev.l1StatusPending
                                    }))}
                                />
                                <CustomSmallFormControlLabel label="Declined" number={myRquestCounts["L1 Status Declined"]} onChange={() => setTeamHeadFilterDto((prev: any) => ({
                                    ...prev,
                                    l1StatusDeclined: !prev.l1StatusDeclined
                                }))} />
                            </FormControl>
                        </Box>
                    )}

                    {(tabName === "My Requests" || tabName === "overall RTH") && (
                        <Box marginTop={2}>
                            <Typography variant="subtitle1" sx={{ fontSize: '12px', fontWeight: 'bold', paddingLeft: '10px' }}>
                                L2 Status
                            </Typography>
                            <FormControl component="fieldset">
                                <CustomSmallFormControlLabel label="Pending" number={myRquestCounts["L2 Status Pending"]}
                                    onChange={() => {
                                        setTeamHeadFilterDto((prev: any) => ({
                                            ...prev,
                                            l2StatusPending: !prev.l2StatusPending
                                        }))
                                    }}
                                />
                                <CustomSmallFormControlLabel label="Approved" number={myRquestCounts["L2 Status Approved"]}
                                    onChange={() => {
                                        setTeamHeadFilterDto((prev: any) => ({
                                            ...prev,
                                            l2StatusApproved: !prev.l2StatusApproved
                                        }))
                                    }}
                                />
                                <CustomSmallFormControlLabel label="Declined" number={myRquestCounts["L2 Status Declined"]} />
                            </FormControl>
                        </Box>
                    )}
                    {
                        (tabName === "Job Post")
                        && (
                            <Box marginTop={2} >
                                <Typography variant="subtitle1" sx={{ fontSize: '12px', fontWeight: 'bold', paddingLeft: '10px', marginBottom: '10px' }}>
                                    Posted in
                                </Typography>
                                <FormControl sx={{width:"100%"}}>
                                <InputLabel id="demo-multiple-checkbox-label" sx={{alignItems:"center"}}>Choose</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    size='small'
                                    multiple
                                    value={personName}
                                    onChange={handleChangee}
                                    input={<OutlinedInput label="Choose" />}                                    
                                    renderValue={(selected) => selected.join(', ')}                                    
                                >
                                    {jobSocialNames.map((name) => (
                                        <MenuItem key={name} value={name} sx={{fontSize:"1%"}}>
                                            <Checkbox checked={personName.includes(name)} />
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            </Box>)}

                    {tabName === "In Progress" && (
                        <Box marginTop={2} >
                            <Typography variant="subtitle1" sx={{ fontSize: '12px', fontWeight: 'bold', paddingLeft: '10px', marginBottom: '10px' }}>
                                RTH Stages
                            </Typography>
                            <FormControl sx={{width:"100%"}}>
                                <InputLabel id="demo-multiple-checkbox-label" sx={{alignItems:"center"}}>Choose</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    size='small'
                                    multiple
                                    value={personName}
                                    onChange={handleChangee}
                                    input={<OutlinedInput label="Choose" />}                                    
                                    renderValue={(selected) => selected.join(', ')}                                    
                                >
                                    {names.map((name) => (
                                        <MenuItem key={name} value={name} >
                                            <Checkbox checked={personName.includes(name)} />
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Box>
                    )}

                    {
                        !true
                        && (


                            <Box marginTop={2} >
                                <Typography variant="subtitle1" sx={{ fontSize: '12px', fontWeight: 'bold', paddingLeft: '10px', marginBottom: '10px' }}>
                                    Created By
                                </Typography>
                                <FormControl component="fieldset" fullWidth size='small'>
                                    <InputLabel id="demo-simple-select-label" sx={{ color: 'grey' }}>Choose</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={age}
                                        label="Choose"
                                        sx={{ color: 'grey', }}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={10}><CustomSmallFormControlLabel label="Delish Kumar R" /></MenuItem>
                                        <MenuItem value={20}><CustomSmallFormControlLabel label="Jhon" /></MenuItem>
                                        <MenuItem value={30}><CustomSmallFormControlLabel label="Bejoy" /></MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        )
                    }


                    {(tabName === "My Requests" || tabName === "In Progress" || tabName === "Closed" || tabName === "overall RTH" || tabName === "Job Post" || tabName === "Waiting to Move to Job" || tabName === "Ready for JD Creation" || tabName === "Resume")
                        && (
                            <Box marginTop={2}>
                                <Typography variant="subtitle1" sx={{ fontSize: '12px', fontWeight: 'bold', paddingLeft: '10px' }}>Job Kind</Typography>
                                {
                                    jobKind.map((jobs) => (
                                        <Box display="flex" alignItems="center" mt={1} sx={{ paddingLeft: '15px', gap: '8px' }}>
                                            <Button
                                                variant="outlined"                                                                                                                                               
                                                sx={{
                                                    width: 'auto',
                                                    fontSize: '8px',
                                                    height: '20px',
                                                    color:
                                                    (jobs === 'Internal-Onrole' && teamHeadFilterDto.internalOnRole) ||
                                                    (jobs === 'Internal-Contract' && teamHeadFilterDto.internalContract) ||
                                                    (jobs === 'External-One Time' && teamHeadFilterDto.externalOneTime) ||
                                                    (jobs === 'External-Contract' && teamHeadFilterDto.externalContract)
                                                        ? 'blue'
                                                        : 'grey',
                                                border:
                                                    (jobs === 'Internal-Onrole' && teamHeadFilterDto.internalOnRole) ||
                                                    (jobs === 'Internal-Contract' && teamHeadFilterDto.internalContract) ||
                                                    (jobs === 'External-One Time' && teamHeadFilterDto.externalOneTime) ||
                                                    (jobs === 'External-Contract' && teamHeadFilterDto.externalContract)
                                                        ? '1px solid blue'
                                                        : '1px solid grey',                                                
                                                    padding: '4px 8px',
                                                }}
                                                data-value={jobs}
                                                onClick={(e) => {
                                                    const target = e.currentTarget as HTMLButtonElement;                                                    
                                                    if (target.dataset.value === "Internal-Onrole") {
                                                        setTeamHeadFilterDto((prev: any) => ({
                                                            ...prev,
                                                            internalOnRole: !prev.internalOnRole
                                                        }))
                                                        
                                                    }
                                                    if (target.dataset.value === "Internal-Contract") {
                                                        setTeamHeadFilterDto((prev: any) => ({
                                                            ...prev,
                                                            internalContract: !prev.internalContract
                                                        }))
                                                    }
                                                    if (target.dataset.value === "External-One Time") {
                                                        setTeamHeadFilterDto((prev: any) => ({
                                                            ...prev,
                                                            externalOneTime: !prev.externalOneTime
                                                        }))
                                                    }
                                                    if (target.dataset.value === "External-Contract") {
                                                        setTeamHeadFilterDto((prev: any) => ({
                                                            ...prev,
                                                            externalContract: !prev.externalContract
                                                        }))
                                                    }
                                                }}

                                            >
                                                {jobs}
                                            </Button>

                                        </Box>
                                    ))
                                }
                            </Box>
                        )
                    }

                    {
                        (tabName === "My Requests" || tabName === "In Progress" || tabName === "Closed" || tabName === "overall RTH" || tabName === "Job Post" || tabName === "Waiting to Move to Job" || tabName === "Ready for JD Creation" || tabName === "Resume")
                        && (

                            <Box marginTop={2}>
                                <Typography variant="subtitle1" sx={{ fontSize: '12px', fontWeight: 'bold', paddingLeft: '10px' }}>Priority</Typography>
                                <Box display="flex" flexDirection="column" justifyContent="space-between">
                                    <Typography sx={{ color: '#73D13D', fontSize: '10px' }}><CustomSmallFormControlLabel label="Low" number="4"
                                        onChange={() => {
                                            setTeamHeadFilterDto((prev: any) => ({
                                                ...prev,
                                                priorityLow: !prev.priorityLow
                                            }))
                                        }}
                                    /></Typography>
                                    <Typography sx={{ color: '#FFA940', fontSize: '10px' }}><CustomSmallFormControlLabel label="Medium" number="4"
                                        onChange={() => {
                                            setTeamHeadFilterDto((prev: any) => ({
                                                ...prev,
                                                priorityMedium: !prev.priorityMedium
                                            }))
                                        }}
                                    /></Typography>
                                    <Typography sx={{ color: '#FF4D4F', fontSize: '10px' }}><CustomSmallFormControlLabel label="High" number="4"
                                        onChange={() => {
                                            setTeamHeadFilterDto((prev: any) => ({
                                                ...prev,
                                                priorityHigh: !prev.priorityHigh
                                            }))
                                        }}
                                    /></Typography>
                                </Box>
                            </Box>

                        )
                    }


                    {
                        (tabName === "My Requests" || tabName === "In Progress" || tabName === "Closed" || tabName === "overall RTH" || tabName === "Job Post" || tabName === "Waiting to Move to Job" || tabName === "Ready for JD Creation" || tabName === "Resume")
                        && (

                            <Box marginTop={2} alignItems='center'>
                                <Typography variant="subtitle1" sx={{ fontSize: '12px', fontWeight: 'bold', marginRight: '8px', paddingLeft: '10px' }}>
                                    RTH Posted Date
                                </Typography>

                                <Box >
                                    <DateRangePicker
                                        showOneCalendar
                                        placement="rightEnd"
                                        onOk={handleDateChange}
                                        onClean={handleDateClean}
                                        style={{ width: '100%' }} />
                                </Box>
                            </Box>

                        )
                    }




                    {
                        (tabName === "Job Post" || tabName === "Waiting to Move to Job" || tabName === "Ready for JD Creation" || tabName === "Resume")
                        && (
                            <Box marginTop={2} >
                                <Typography variant="subtitle1" sx={{ fontSize: '12px', fontWeight: 'bold', paddingLeft: '10px', marginBottom: '10px' }}>
                                    Position Title
                                </Typography>
                                <FormControl sx={{width:"100%"}}>
                                <InputLabel id="demo-multiple-checkbox-label" sx={{alignItems:"center"}}>Choose</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    size='small'
                                    multiple
                                    value={personName}
                                    onChange={handleChangee}
                                    input={<OutlinedInput label="Choose" />}                                    
                                    renderValue={(selected) => selected.join(', ')}                                    
                                >
                                    {posTitleNames.map((name) => (
                                        <MenuItem key={name} value={name} sx={{fontSize:"1%"}}>
                                            <Checkbox checked={personName.includes(name)} />
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            </Box>
                        )}
                    {
                        (tabName === "Job Post" || tabName === "Waiting to Move to Job" || tabName === "Ready for JD Creation" || tabName === "Resume")
                        && (
                            <Box marginTop={2} >
                                <Typography variant="subtitle1" sx={{ fontSize: '12px', fontWeight: 'bold', paddingLeft: '10px', marginBottom: '10px' }}>
                                    Business
                                </Typography>
                                <FormControl sx={{width:"100%"}}>
                                <InputLabel id="demo-multiple-checkbox-label" sx={{alignItems:"center"}}>Choose</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    size='small'
                                    multiple
                                    value={personName}
                                    onChange={handleChangee}
                                    input={<OutlinedInput label="Choose" />}                                    
                                    renderValue={(selected) => selected.join(', ')}                                    
                                >
                                    {businessData.map((name:any) => (
                                        <MenuItem key={name.value} value={name.label} sx={{fontSize:"1%"}}>
                                            <Checkbox checked={personName.includes(name.label)} />
                                            <ListItemText primary={name.label} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            </Box>
                        )
                    }

                    {
                        (tabName === "Job Post" || tabName === "Waiting to Move to Job" || tabName === "Ready for JD Creation" || tabName === "Resume")
                        && (
                            <Box marginTop={2} >
                                <Typography variant="subtitle1" sx={{ fontSize: '12px', fontWeight: 'bold', paddingLeft: '10px', marginBottom: '10px' }}>
                                    Location
                                </Typography>
                                <FormControl sx={{width:"100%"}}>
                                <InputLabel id="demo-multiple-checkbox-label" sx={{alignItems:"center"}}>Choose</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    size='small'
                                    multiple
                                    value={personName}
                                    onChange={handleChangee}
                                    input={<OutlinedInput label="Choose" />}                                    
                                    renderValue={(selected) => selected.join(', ')}                               
                                >
                                    {locationData.map((name:any) => (
                                        <MenuItem key={name.value} value={name.label} sx={{fontSize:"1%"}}>
                                            <Checkbox checked={personName.includes(name.label)} />
                                            <ListItemText primary={name.label} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            </Box>
                        )}
                </Box>
            </Collapse>
        </Box>
    );
};

export default FilterSidebar;