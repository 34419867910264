import React, { useState } from 'react';
import {  Typography, Radio, Grid, Box, Paper, SelectChangeEvent, Button } from '@mui/material';
import CommonTextField from '../../../common/CommonTextField';
import CommonSelect from '../../../common/CommomSelect';
import { gender, knownOptions } from '../../../../constants/dummyConst';

const BulkUploadForm = ({formValues,setFormValues,handleNextPage}:any) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!formValues.howDidYouHeardAbtUs) {
      newErrors.howDidYouHeardAbtUs = 'This is required';
    }
    if (!formValues.firstName) {
      newErrors.firstName = 'First Name is required';
    }
    if (!formValues.lastName) {
      newErrors.lastName = 'Last Name is required';
    }
    if (!formValues.emailAddress) {
      newErrors.emailAddress = 'Email Address is required';
    } else if (!/\S+@\S+\.\S+/.test(formValues.emailAddress)) {
      newErrors.emailAddress = 'Email Address is invalid';
    }
    if (!formValues.phoneNumber) {
      newErrors.phoneNumber = 'Phone Number is required';
    } else if (!/^\d{10}$/.test(formValues.phoneNumber)) {
      newErrors.phoneNumber = 'Phone Number must be 10 digits';
    }
    if (!formValues.dateOfBirth) {
      newErrors.dateOfBirth = 'Date of Birth is required';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  
  const handleContinue=()=>{
  const isValid = validateForm();
  if (isValid) {
    handleNextPage();
  } else {
    console.log('Form validation failed');
  }
}

  return (
    
    <Box sx={{ pt: '4px'}}>
        <Paper elevation={0} sx={{overflowY:'auto',pt:'10px',position:'relative'}}>
      <Typography sx={{fontSize:'24px',fontWeight:'500', mb:'27px'}}> Personal Information</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <Box sx={{width:'55%'}}>
      <Grid container spacing={2}>

      <Grid item xs={12} md={4}>
        <Typography sx={{fontSize:'14px',color:'#E5E3E3' , mb:'17px'}}>How did you heard about us?<span style={{color:'red'}}> *</span></Typography>
      <CommonSelect
                  labelId=""
                  label=""
                  name="howDidYouHeardAbtUs"
                  value={formValues.howDidYouHeardAbtUs||''}
                  onChange={handleInputChange}
                  error={!!errors.howDidYouHeardAbtUs}
                  helperText={errors.howDidYouHeardAbtUs}
                  options={knownOptions}
                />
          </Grid>
          <Grid item xs={12} md={8}>
            <Box sx={{display:'flex',mt:'0px', flexDirection:'column'}}>
          <Typography sx={{fontSize:'14px',color:'#E5E3E3' , mb:'17px'}}>Have you previously been employed by HEPL?<span style={{color:'red'}}> *</span></Typography>
        <Box sx={{display:'flex', flexDirection:'row'}}>
        <Radio name="preEmployed" value="yes"    
         checked={formValues.preEmployed === 'yes'}
          onChange={handleInputChange}
/>
        <Typography sx={{mt:'10px'}}>Yes</Typography>
        <Radio name="employed" value="no"
            checked={formValues.preEmployed === 'no'}
            onChange={handleInputChange}
         />
        <Typography sx={{mt:'10px'}}>No</Typography>
        </Box>
        </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography sx={{fontSize:'14px',color:'#E5E3E3' , mb:'17px'}}>First Name<span style={{color:'red'}}> *</span></Typography>
          <CommonTextField
                  label="FirstName"
                  name="firstName"
                  value={formValues.firstName||''}
                  onChange={handleInputChange}
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                />
                </Grid>
                <Grid item xs={12} md={3}>
                <Typography sx={{fontSize:'14px',color:'#E5E3E3' , mb:'17px'}}>Last Name<span style={{color:'red'}}> *</span></Typography>
                <CommonTextField
                  label="LastName"
                  name="lastName"
                  value={formValues.lastName||''}
                  onChange={handleInputChange}
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                />
                </Grid>
                <Grid item xs={12} md={3}>
                <Typography sx={{fontSize:'14px',color:'#E5E3E3' , mb:'17px'}}>Email Address<span style={{color:'red'}}> *</span></Typography>
                <CommonTextField
                  label="EmailAddress"
                  name="emailAddress"
                  value={formValues.emailAddress||''}
                  onChange={handleInputChange}
                  error={!!errors.emailAddress}
                  helperText={errors.emailAddress}
                />
                </Grid>
                <Grid item xs={12} md={3}>
                <Typography sx={{fontSize:'14px',color:'#E5E3E3' , mb:'17px'}}>Phone Number<span style={{color:'red'}}> *</span></Typography>
                <CommonTextField
                  label="PhoneNumber"
                  name="phoneNumber"
                  value={formValues.phoneNumber||''}
                  onChange={handleInputChange}
                  error={!!errors.phoneNumber}
                  helperText={errors.phoneNumber}
                />
                </Grid>
                <Box sx={{mt:'20px',width:'50vw',display:'flex',gap:'14px',ml:'18px'}}>
                <Grid item xs={12} md={3}>
                <Typography sx={{fontSize:'14px',color:'#E5E3E3' , mb:'17px'}}>Date Of Birth<span style={{color:'red'}}> *</span></Typography>
                <CommonTextField
                  label="DateOfBirth"
                  type="date"
                  name="dateOfBirth"
                  value={formValues.dateOfBirth}
                  onChange={handleInputChange}
                  error={!!errors.dateOfBirth}
                  helperText={errors.dateOfBirth}
                />
             </Grid>
             <Grid item xs={12} md={3}>
             <Typography sx={{fontSize:'14px',color:'#E5E3E3' , mb:'17px'}}>Gender</Typography>
             <CommonSelect
                  labelId="gender"
                  label="Gender"
                  name="gender"
                  value={formValues.gender||''}
                  onChange={handleInputChange}
                  error={!!errors.gender}
                  helperText={errors.gender}
                  options={gender}
                />
             </Grid>
             </Box>
        </Grid>
        <Box sx={{ width: '35%', display: 'flex', justifyContent: 'flex-end' }}>

      </Box>
      </Box>
      </Box>
      <Box sx={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',gap:'20px',mt:'20px',mr:'10px', position:"absolute", bottom:'4px', right:'10px'}}>
      <Button variant='contained' sx={{textTransform:'none'}} onClick={handleContinue}>Continue</Button>
      </Box>
      </Paper>
    </Box>
  );
};

export default BulkUploadForm;
