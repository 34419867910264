import { Typography, Radio,Grid, Box, Paper, RadioGroup, FormControlLabel } from '@mui/material';

 
const Review = ({formValues}:any) => {
 
  return (
   
    <Box sx={{ pt: '4px'}}>
        <Paper elevation={0} sx={{height:'70vh',overflowY:'scroll',pt:'10px',position:'relative'}}>
      <Typography sx={{fontSize:'24px',fontWeight:'500', mb:'27px'}}>Review</Typography>
      <Box sx={{width:'66vw'}}>
      <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Typography sx={{fontSize:'14px', mb:'17px',backgroundColor:'#1677FF1A',color:'#1677FF',width:'159px',p:'3px',borderRadius:'3px', textAlign:'center'}}>Personal Information</Typography>
      <Typography sx={{color:'#bababa',mb:'18px', fontSize:'14px'}}>How did you heard about us<span style={{color:'red'}}> *</span></Typography>
      <Typography sx={{fontWeight:'500', bgcolor:'#E9EAEC', height:'58px', width:'274px', p:'0 10px'}}>{formValues.howDidYouHeardAbtUs}</Typography>
          </Grid>
          <Grid item xs={12} md={5}>
  <Typography sx={{ fontSize: '14px', color: '#bababa', mb: '24px',mt:'10px' }}>
    Have you previously been employed by HEPL?<span style={{ color: 'red' }}> *</span>
  </Typography>
 
  <RadioGroup
    row
    aria-label="employed"
    name="employed"
    value={formValues.preEmployed}
  >
    <FormControlLabel
      value="yes"
      control={<Radio />}
      label="Yes"
    />
    <FormControlLabel
      value="no"
      control={<Radio />}
      label="No"
    />
  </RadioGroup>
</Grid>            
        </Grid>
        <Grid container spacing={2} sx={{mt:'10px',borderBottom:'1px solid #E5E3E3'}}>
      <Grid item xs={12} md={2}>
      <Typography sx={{color:'#E5E3E3',mb:'26px'}}>FirstName<span style={{color:'red'}}>  *</span></Typography>
      <Typography sx={{fontWeight:'500',backgroundColor: '#E9EAEC', padding: '4px', borderRadius: '4px' }}>{formValues.firstName||''}</Typography>
          </Grid>
          <Grid item xs={12} md={2}>
      <Typography sx={{color:'#E5E3E3',mb:'28px'}}>Middle Name<span style={{color:'red'}}>  *</span></Typography>
      <Typography sx={{fontWeight:'500',backgroundColor: '#E9EAEC', padding: '4px', borderRadius: '4px' }}>{formValues.middleName||'-'}</Typography>
          </Grid>
          <Grid item xs={12} md={2}>
      <Typography sx={{color:'#E5E3E3',mb:'28px'}}>LastName<span style={{color:'red'}}>  *</span></Typography>
      <Typography sx={{fontWeight:'500',backgroundColor: '#E9EAEC', padding: '4px', borderRadius: '4px' }}>{formValues.lastName||''}</Typography>
          </Grid>
          <Grid item xs={12} md={2}>
      <Typography sx={{color:'#E5E3E3',mb:'28px'}}>Email Address<span style={{color:'red'}}>  *</span></Typography>
      <Typography sx={{fontWeight:'500',backgroundColor: '#E9EAEC', padding: '4px', borderRadius: '4px' }}>{formValues.emailAddress||''}</Typography>
          </Grid>
          <Grid item xs={12} md={2}>
      <Typography sx={{color:'#E5E3E3',mb:'28px'}}>Phone Number<span style={{color:'red'}}>  *</span></Typography>
      <Typography sx={{fontWeight:'500',backgroundColor: '#E9EAEC', padding: '4px', borderRadius: '4px' }}>{formValues.phoneNumber||''}</Typography>
          </Grid>
          <Grid container spacing={2} sx={{mt:'10px',ml:'4px'}}>
            <Grid item xs={12} md={2}>
            <Typography sx={{color:'#E5E3E3',mb:'28px'}}>Date of Birth</Typography>
            <Typography sx={{fontWeight:'500',backgroundColor: '#E9EAEC', padding: '4px', borderRadius: '4px' }}>{formValues.dateOfBirth||''}</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
            <Typography sx={{color:'#E5E3E3',mb:'28px'}}>Gender</Typography>
            <Typography sx={{fontWeight:'500',backgroundColor: '#E9EAEC', padding: '4px', borderRadius: '4px' }}>{formValues.gender||'-'}</Typography>
            </Grid>
          </Grid>
</Grid>
<Grid container spacing={2} sx={{borderBottom:'1px solid #E5E3E3'}}>
      <Grid item xs={12} md={6}>
        <Typography sx={{fontSize:'14px', mb:'17px',backgroundColor:'#E3E8F2',color:'#1677FF',width:'10vw',mt:'10px',p:'3px',borderRadius:'3px'}}>Additional Information</Typography>
      <Typography sx={{color:'#E5E3E3',mb:'18px'}}>Nationality</Typography>
      <Typography sx={{fontWeight:'500',backgroundColor: '#E9EAEC', padding: '4px', borderRadius: '4px' }}>{formValues.nationality}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
      <Typography sx={{color:'#E5E3E3',mb:'18px',mt:'40px'}}>Citizenship</Typography>
      <Typography sx={{fontWeight:'500',backgroundColor: '#E9EAEC', padding: '4px', borderRadius: '4px' }}>{formValues.citizenShip}</Typography>
          </Grid>        
        </Grid>
        <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Typography sx={{fontSize:'14px', mb:'17px',backgroundColor:'#E3E8F2',color:'#1677FF',width:'4vw',mt:'10px',p:'3px',borderRadius:'3px'}}>Address</Typography>
      <Typography sx={{color:'#E5E3E3',mb:'18px'}}>Country</Typography>
      <Typography sx={{fontWeight:'500',backgroundColor: '#E9EAEC', padding: '4px', borderRadius: '4px' }}>{formValues.country||'-'}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
      <Typography sx={{color:'#E5E3E3',mb:'18px',mt:'80px'}}>Address Line 1</Typography>
      <Typography sx={{fontWeight:'500',backgroundColor: '#E9EAEC', padding: '4px', borderRadius: '4px' }}>{formValues.addressLine1||'-'}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
      <Typography sx={{color:'#E5E3E3',mb:'18px',mt:'80px'}}>Address Line 2</Typography>
      <Typography sx={{fontWeight:'500',backgroundColor: '#E9EAEC', padding: '4px', borderRadius: '4px' }}>{formValues.addressLine2||'-'}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{borderBottom:'1px solid #E5E3E3'}}>
          <Grid item xs={12} md={4}>
          <Typography sx={{color:'#E5E3E3',mb:'18px'}}>Postal Code</Typography>
          <Typography sx={{fontWeight:'500',backgroundColor: '#E9EAEC', padding: '4px', borderRadius: '4px' }}>{formValues.postalCode||'-'}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
          <Typography sx={{color:'#E5E3E3',mb:'18px'}}>State</Typography>
          <Typography sx={{fontWeight:'500',backgroundColor: '#E9EAEC', padding: '4px', borderRadius: '4px' }}>{formValues.state||'-'}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
          <Typography sx={{color:'#E5E3E3',mb:'18px'}}>City</Typography>
          <Typography sx={{fontWeight:'500',backgroundColor: '#E9EAEC', padding: '4px', borderRadius: '4px' }}>{formValues.city||'-'}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Typography sx={{fontSize:'14px', mb:'17px',backgroundColor:'#E3E8F2',color:'#1677FF',width:'5vw',mt:'10px',p:'3px',borderRadius:'3px'}}>Experience</Typography>
      <Typography sx={{color:'#E5E3E3',mb:'18px'}}>Skills</Typography>
      <Typography sx={{fontWeight:'500',backgroundColor: '#E9EAEC', padding: '4px', borderRadius: '4px' }}>{formValues.skills}</Typography>
          </Grid>          
        </Grid>
        <Grid container spacing={2} sx={{mt:'10px'}}>
      <Grid item xs={12} md={4}>
      <Typography sx={{color:'#E5E3E3',mb:'28px'}}>Do You have  Work Experience?</Typography>
      <Typography sx={{fontWeight:'500',backgroundColor: '#E9EAEC', padding: '4px', borderRadius: '4px' }}>{formValues.experience}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
      <Typography sx={{color:'#E5E3E3',mb:'28px'}}>Total Years in Experience</Typography>
      <Typography sx={{fontWeight:'500',backgroundColor: '#E9EAEC', padding: '4px', borderRadius: '4px' }}>{formValues.yearsInExperience||'-'}</Typography>
          </Grid>
</Grid>
{/* <Grid container spacing={2} sx={{mt:'10px'}}>
{formValues?.experience?.map((edu: any, index: number) => (
      <Grid item xs={12} md={4} key={index}>
      <Typography sx={{mb:'28px',fontWeight:'bold'}}>{edu.label}</Typography>
          </Grid>
))}
          </Grid> */}
          {/* {formValues?.experience?.map((edu: any, index: number) => (
            <Box key={index}>
          <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
      <Typography sx={{mb:'28px',color:'#E3E3E3'}}>Job Title</Typography>
      <Typography sx={{fontWeight:'500',backgroundColor: '#E9EAEC', padding: '4px', borderRadius: '4px' }}>{edu.jobTitle}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
      <Typography sx={{mb:'28px',color:'#E3E3E3'}}>Company</Typography>
      <Typography sx={{fontWeight:'500',backgroundColor: '#E9EAEC', padding: '4px', borderRadius: '4px' }}>{edu.company}</Typography>
          </Grid>
          </Grid>
          <Grid container spacing={2} sx={{borderBottom:'1px solid #E5E3E3'}}>
          <Grid item xs={12} md={4}>
      <Typography sx={{mb:'28px',color:'#E3E3E3'}}>From</Typography>
      <Typography sx={{fontWeight:'500',backgroundColor: '#E9EAEC', padding: '4px', borderRadius: '4px' }}>{edu.expFrom}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
      <Typography sx={{mb:'28px',color:'#E3E3E3'}}>To</Typography>
      <Typography sx={{fontWeight:'500',backgroundColor: '#E9EAEC', padding: '4px', borderRadius: '4px' }}>{edu.expTo}</Typography>
          </Grid>
          </Grid>
          </Box>
          ))} */}
          <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Typography sx={{fontSize:'14px', mb:'17px',backgroundColor:'#E3E8F2',color:'#1677FF',width:'10vw',mt:'10px'}}>Education</Typography>
        {formValues?.education?.map((edu: any, index: number) => (
      <Box key={index}>
      <Typography sx={{mb:'18px',fontWeight:'bold'}}>{edu.label}</Typography>
      </Box>
        ))}
          </Grid>          
        </Grid>
       
                {formValues?.education?.map((edu: any, index: number) => (
        <Box key={index}>
        <Grid container spacing={2}>
         <Grid item xs={12} md={3}>
         <Typography sx={{mb:'28px',color:'#E3E3E3'}}>University</Typography>
         <Typography sx={{fontWeight:'500',backgroundColor: '#E9EAEC', padding: '4px', borderRadius: '4px' }}>{edu.university}</Typography>
         </Grid>
         <Grid item xs={12} md={3}>
         <Typography sx={{mb:'28px',color:'#E3E3E3'}}>Degree</Typography>
         <Typography sx={{fontWeight:'500',backgroundColor: '#E9EAEC', padding: '4px', borderRadius: '4px' }}>{edu.degree}</Typography>
         </Grid>
         <Grid item xs={12} md={3}>
         <Typography sx={{mb:'28px',color:'#E3E3E3'}}>Field of Study</Typography>
         <Typography sx={{fontWeight:'500',backgroundColor: '#E9EAEC', padding: '4px', borderRadius: '4px' }}>{edu.fieldOfStudy}</Typography>
         </Grid>
        </Grid>
        <Grid container spacing={2}>
         <Grid item xs={12} md={3}>
         <Typography sx={{mb:'28px',color:'#E3E3E3'}}>From</Typography>
         <Typography sx={{fontWeight:'500',backgroundColor: '#E9EAEC', padding: '4px', borderRadius: '4px' }}>{edu.from}</Typography>
         </Grid>
         <Grid item xs={12} md={3}>
         <Typography sx={{mb:'28px',color:'#E3E3E3'}}>To</Typography>
         <Typography sx={{fontWeight:'500',backgroundColor: '#E9EAEC', padding: '4px', borderRadius: '4px' }}>{edu.to}</Typography>
         </Grid>
        </Grid>
        </Box>
        ))}
      </Box>
      {/* <Box sx={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',gap:'20px',mt:'50px',mr:'10px', position:"absolute", bottom:'10px', right:'10px'}}>
      <Button variant='contained' sx={{textTransform:'none'}} onClick={handleClick}>Submit</Button>
      </Box> */}
      </Paper>
    </Box>
  );
};
 
export default Review;
 
 